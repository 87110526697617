/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import QuizOptions from "layouts/quiz/QuizOptions";
import { CancelTwoTone } from "@mui/icons-material";
import { client } from "constants/APIFunctions";
import { auth } from "firebase/firebase";
import { useCookies } from "react-cookie";
import { CircularProgress } from "@mui/material";
import StatementFormatter from "layouts/quiz/components/StatementFormatter";
import ReactGA from "react-ga4";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import QuizOptionsNeet from "./QuizOptionsNeet";
import { Helmet } from "react-helmet";
ReactGA.initialize("G-EDEXTHSM4C");

const NeetQuizQuestionPage = (props) => {
  const questionRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <>
      <div className="question-container" style={{ marginTop: 8, cursor: "pointer" }}>
        <div className="question-text-container">
          <h4>Q{props.index + 1}. </h4>
          <div className="question-text" ref={questionRef}>
            <StatementFormatter text={props.data.question} />
            {props.data.questionImage ? (
              <img
                src={props.data.questionImage}
                style={{ maxWidth: "600px", minWidth: "250px", minHeight: "100px", maxHeight: "250px" }}
              />
            ) : (
              <div />
            )}
            {props.data.question2 ? <StatementFormatter text={props.data.question2} /> : <div />}
          </div>
        </div>
        <div className="options-container">
          <QuizOptionsNeet data={props?.data?.options} questiondata={props.data} analysis={true} />
        </div>
        <div className="explaination">
          <h5
            style={{
              fontSize: "14px",
              marginTop: "2px",
              fontFamily: "BentonSans-Bold, Arial, Noto Sans, sans-serif",
            }}
          >
            Correct Answer:{props.data.correctanswer}
          </h5>
          {/* <div className="explaintext">{props.data.correctanswer}</div> */}
        </div>
        <div className="explaination">
          <h5
            style={{
              fontSize: "14px",
              marginTop: "2px",
              fontFamily: "BentonSans-Bold, Arial, Noto Sans, sans-serif",
            }}
          >
            Explanation:
          </h5>
          <div className="explaintext">{props.data.explanation}</div>
        </div>

        <div className="separator" />
      </div>
    </>
  );
};

export default NeetQuizQuestionPage;
