/* eslint-disable react/prop-types */
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import Invoices from "layouts/billing/components/Invoices";
import BillingInformation from "layouts/billing/components/BillingInformation";
import Transactions from "layouts/billing/components/Transactions";
import Card from "./CreateQuizCard";
import SearchInput from "./SearchInput";
import "./CreateQuizMainComponent.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { client } from "constants/APIFunctions";
import { CONSTANTS } from "constants/Constants";
import ReactGA from "react-ga4";
ReactGA.initialize("G-EDEXTHSM4C");

const styles = {
  Text: {
    color: "#030303",
    fontSize: "20px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: "500",
    lineHeight: "44px",
    textAlign: "center",
    opacity: 0.97,
    marginTop: 5,
  },

  ButtonSelected: {
    cursor: "pointer",
    padding: "0px 5px",
    border: "1px solid #030303",
    boxSizing: "border-box",
    borderRadius: "8px",
    backgroundColor: "#114c8a",
    color: "#ffffff",
    fontSize: "12px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: 400,
    lineHeight: "14px",
    outline: "none",
    width: "90%",
    marginLeft: "3px",
    marginTop: "12px",
    boxShadow: "0px 2px 8px rgba(0,0,0,0.16)",
    minHeight: "40px",
  },
  Button: {
    cursor: "pointer",
    padding: "0px 5px",
    border: "1px solid #030303",
    boxSizing: "border-box",
    borderRadius: "8px",
    backgroundColor: "#fff",
    color: "#030303",
    fontSize: "12px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: 400,
    lineHeight: "14px",
    outline: "none",
    width: "90%",
    marginLeft: "3px",
    marginTop: "12px",
    boxShadow: "0px 2px 8px rgba(0,0,0,0.16)",
    minHeight: "40px",
  },
};

const defaultProps = {
  text: "choose your subject",
};
const data = [
  { id: "1", title: "Item 1" },
  { id: "2", title: "Item 2" },
  { id: "3", title: "Item 3" },
  { id: "4", title: "Item 4" },
  { id: "5", title: "Item 5" },
  { id: "6", title: "Item 6" },
  // Add more items as needed
];
function CreateQuizMainComponent(props) {
  const [data, setData] = useState([]);
  const getSubjects = () => {
    axios
      .get(`${CONSTANTS.APIURL}/api/subject/get_subjects`, {
        params: {
          exam_id: props.exams.exam_id,
        },
      })
      .then((response) => {
        console.log(response);
        setData(response.data.subjects);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getTopics = () => {
    client
      .get(`${CONSTANTS.APIURL}/api/topic/get_topics`, {
        params: {
          subject_id: props.subject.subject_id,
          exam_id: props.exams.exam_id,
        },
      })
      .then((response) => {
        console.log(response);
        setData(response.data.topics);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSubTopics = () => {
    client
      .get(`${CONSTANTS.APIURL}/api/subtopic/get_sub_topics`, {
        params: {
          topic_id: props.topic.topic_id,
          exam_id: props.exams.exam_id,
        },
      })
      .then((response) => {
        console.log(response);
        setData(response.data.sub_topics);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getExams = () => {
    client
      .get(`${CONSTANTS.APIURL}/api/exam/get_exams`)
      .then((response) => {
        console.log(response);
        setData(response.data.exams);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (props.type === 4) {
      getExams();
    }
    if (props.type === 3) {
      setData([
        {
          id: "easy",
          name: "Foundation",
          value: "Foundation",
        },
        {
          id: "medium",
          name: "Intermediate",
          value: "Intermediate",
        },
        {
          id: "hard",
          name: "Advanced",
          value: "Advanced",
        },
      ]);
    }
    if (props.type === 5) {
      setData([
        {
          id: "5",
          name: "5",
        },
        {
          id: "10",
          name: "10",
        },
      ]);
    }
  }, []);
  useEffect(() => {
    console.log(props.type, props.subject);
    if (props.type === 1 && props.subject) {
      getTopics();
      console.log("1", props.subject);
    }
  }, [props.subject]);
  useEffect(() => {
    console.log(props.type, props.exams);
    if (props.type === 0 && props.exams) {
      getSubjects();
    }
  }, [props.exams]);
  useEffect(() => {
    if (props.type === 2 && props.topic) {
      getSubTopics();
    }
  }, [props.topic]);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <Card>
      <div style={{ height: 20 }} />
      {/* {props.type !== 3 ? <SearchInput placeholder={props.placeholder} /> : <div />} */}
      <div style={styles.Text}>{props.title}</div>
      <div className="list-container-new">
        {data.map((item) => {
          // Conditionally render item based on type
          if (props.type === 0) {
            return (
              <button
                style={
                  props.subject?.subject_id.includes(item?.subject_id)
                    ? styles.ButtonSelected
                    : styles.Button
                }
                key={item?.subject_id}
                onClick={() => {
                  // if (props.subject) {
                  //   props.selectedSubject({
                  //     ...props.subject,
                  //     subject_id: props?.subject?.subject_id + " , " + item?.subject_id,
                  //     subject_name: props?.subject?.subject_name + " , " + item?.subject_name,
                  //   });
                  // } else {
                  props.selectedSubject(item);
                  // }
                }}
              >
                {item.subject_name}
              </button>
            );
          } else if (props.type === 1) {
            return (
              <button
                style={
                  props.topic?.topic_id.includes(item?.topic_id)
                    ? styles.ButtonSelected
                    : styles.Button
                }
                key={item?.topic_id}
                onClick={() => {
                  // if (props.topic) {
                  //   props.selectedTopic({
                  //     ...props.topic,
                  //     topic_id: props?.topic?.topic_id + " , " + item?.topic_id,
                  //     topic_name: props?.topic?.topic_name + " , " + item?.topic_name,
                  //   });
                  // } else {
                  props.selectedTopic(item);
                  // }
                }}
              >
                {item.topic_name}
              </button>
            );
          } else if (props.type === 2) {
            return (
              <button
                style={
                  props.subTopic?.sub_topic_id.includes(item?.sub_topic_id)
                    ? styles.ButtonSelected
                    : styles.Button
                }
                key={item?.sub_topic_id}
                onClick={() => {
                  // if (props.sub_topic_id) {
                  //   props.selectedSubTopic({
                  //     ...props.subTopic,
                  //     sub_topic_id: props?.subTopic?.sub_topic_id + " , " + item?.sub_topic_id,
                  //     sub_topic_name:
                  //       props?.subTopic?.sub_topic_name + " , " + item?.sub_topic_name,
                  //   });
                  // } else {
                  props.selectedSubTopic(item);
                  // }
                }}
              >
                {item.sub_topic_name}
              </button>
            );
          } else if (props.type === 3) {
            return (
              <button
                style={props.difficulty?.id === item?.id ? styles.ButtonSelected : styles.Button}
                key={item?.id}
                onClick={() => {
                  props.selectedDifficulty(item);
                }}
              >
                {item.name}
              </button>
            );
          } else if (props.type === 5) {
            return (
              <button
                style={props.num?.id === item?.id ? styles.ButtonSelected : styles.Button}
                key={item?.id}
                onClick={() => {
                  props.selectnum(item);
                }}
              >
                {item.name}
              </button>
            );
          } else if (props.type === 4) {
            return (
              <button
                style={
                  props.exams?.exam_id === item?.exam_id ? styles.ButtonSelected : styles.Button
                }
                key={item?.exam_id}
                onClick={() => {
                  props.selectExams(item);
                }}
              >
                {item.exam_name}
              </button>
            );
          }
          // Add more conditions as needed
          return null; // Don't render if condition doesn't match
        })}
      </div>
    </Card>
  );
}

export default CreateQuizMainComponent;
