/* eslint-disable react/prop-types */
import { useEffect, useMemo, useState } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";
import ReactECharts from "echarts-for-react";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// ReportsLineChart configurations
import configs from "examples/Charts/LineCharts/ReportsLineChart/configs";
import { client } from "constants/APIFunctions";
import { auth } from "../../../../firebase/firebase";
import ClipLoader from "react-spinners/ClipLoader";
import { FadeLoader } from "react-spinners";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";
import { CONSTANTS } from "constants/Constants";
import { PysparkURL } from "constants/Constants";

import CreateNewQuizMainComponent from "layouts/quiz/CreateNewQuizMainComponent";
import { Button } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
  width: "10px",
  height: "10px",
};
function ReportsLineChart({ color, title, description, date, chart, subject }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});
  const [loading, setLoading] = useState(true);
  const [selectedSubject, setSelectedSubject] = useState();
  const [chipdata, setChipdata] = useState([]);
  const [selectedchipdata, setSelectedChipdata] = useState([]);
  const [selectedExams, setSelectedExams] = useState({
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    exam_name: "UPSC",
  });
  const [chartData, setChartData] = useState();
  // useEffect(() => {
  //   axios
  //     .get(`${CONSTANTS.APIURL}/api/subject/get_subjects`, {
  //       params: {
  //         exam_id: selectedExams.exam_id,
  //       },
  //     })
  //     .then((response) => {
  //       console.log(response);
  //       setChipdata(response.data.subjects);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [chipdata]);

  const [datas, setData] = useState({
    analysis: [
      {
        name: "Environment and Ecology",
        type: "line",
        stack: "Total",
        data: [40.0, 30.0, 100.0],
      },
      {
        name: "History of India",
        type: "line",
        stack: "Total",
        data: [20.0],
      },
      {
        name: "Polity and Governance",
        type: "line",
        stack: "Total",
        data: [20.0, 50.0, 50.0],
      },
      {
        name: "Science and Technology",
        type: "line",
        stack: "Total",
        data: [
          0.0, 40.0, 20.0, 30.0, 0.0, 20.0, 40.0, 40.0, 20.0, 0.0, 100.0, 0.0, 20.0, 30.0, 0.0,
          100.0, 0.0, 100.0, 100.0, 0.0, 100.0,
        ],
      },
      {
        name: "Indian and World Geography",
        type: "line",
        stack: "Total",
        data: [100.0, 50.0, 40.0, 0.0, 0.0, 100.0],
      },
      {
        name: "Current Events",
        type: "line",
        stack: "Total",
        data: [0.0, 100.0, 25.0, 0.0, 0.0],
      },
      {
        name: "Economic and Social Development",
        type: "line",
        stack: "Total",
        data: [100.0, 0.0, 0.0],
      },
    ],
  });
  // useEffect(() => {
  //   setData();
  //   setSelectedSubject(
  //     props?.subject && props?.subject?.length > 0
  //       ? props?.subject[0]
  //       : {
  //           subject_id: "a31168ae-d61d-40df-b52e-26804b527aba",
  //           subject_name: "Polity",
  //         }
  //   );
  //   getScoreSubjectWise(
  //     props?.subject && props?.subject?.length > 0
  //       ? props?.subject[0]?.subject_id
  //       : "a31168ae-d61d-40df-b52e-26804b527aba"
  //   );
  // }, [props?.subject]);
  // const getScoreSubjectWise = (subject_id) => {
  //   setLoading(true);
  //   setData();
  //   const items = JSON.parse(localStorage.getItem("userdata"));
  //   const barchart = JSON.parse(localStorage.getItem("lineChart"));
  //   if (barchart) {
  //     setData(barchart);
  //   }
  //   axios
  //     .get(`${CONSTANTS.PysparkURL}/api/analysis/analysis_subject_score`, {
  //       params: {
  //         user_id: auth.currentUser
  //           ? auth.currentUser.uid
  //           : items?.user_id
  //           ? items?.user_id
  //           : "813b6e33-91ff-49f0-8e08-2f479c95e4e1",
  //         subject_id: subject_id,
  //         exam_id: props?.exam_id,
  //       },
  //     })
  //     .then((res) => {
  //       console.log(res?.data?.analysis);
  //       setData(res?.data?.analysis);
  //       localStorage.setItem("lineChart", JSON.stringify(res.data?.analysis));
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setLoading(false);
  //     });
  // };
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("userdata"));
    const barchart = JSON.parse(localStorage.getItem("lineChart"));
    if (barchart) {
      setData(barchart);
    }
    axios
      .get(`${CONSTANTS.PysparkURL}/api/analysis/analysis_subject_score`, {
        params: {
          user_id: auth.currentUser
            ? auth.currentUser.uid
            : items?.user_id
            ? items?.user_id
            : "813b6e33-91ff-49f0-8e08-2f479c95e4e1",
        },
      })
      .then((res) => {
        // setsubjectdata(res.data.analysis);
        console.log("linechart", res.data?.analysis);
        setData(res.data?.analysis);
        localStorage.setItem("lineChart", JSON.stringify(res.data?.analysis));
        setLoading(false);
      })
      .catch((err) => {
        console.log("linechart", err);
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    // const getScoreSubjectWise = subject_id => {
    // setLoading(true);
    // setChartData();
    axios
      .get(`${CONSTANTS.PysparkURL}/api/analysis/analysis_subject_score`, {
        params: {
          user_id: auth()?.currentUser ? auth()?.currentUser?.uid : "E9mGW5hhmNZdLdU9ksh0IxdgURu2",
          subject_id: subject_id,
          exam_id: props?.exam_id,
        },
      })
      .then((res) => {
        console.log(res?.data?.analysis);
        setChartData(res?.data?.analysis);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    // };
  }, []);
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox padding="1rem">
        <div style={{ position: "absolute", top: 8, right: 8 }}>
          <RotatingLines
            visible={loading}
            height="20"
            width="20"
            color="grey"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
          />
        </div>
        {useMemo(
          () => (
            <ReactECharts
              style={{ width: "100%" }}
              option={{
                // title: {
                //   text: "Stacked Line",
                // },
                tooltip: {
                  trigger: "axis",
                },
                legend: {
                  data: ["T1", "t2", " t3", "t4", "t5"],
                },
                grid: {
                  left: "3%",
                  right: "4%",
                  bottom: "3%",
                  containLabel: true,
                },
                xAxis: {
                  type: "category",
                  boundaryGap: false,
                  data: datas?.test_names_list
                    ? datas?.test_names_list
                    : ["T1", "T2", "T3", "T4", "T5", "T6", "T7"],
                },
                yAxis: {
                  type: "value",
                },
                series: datas?.analysis,
              }}
              notMerge={true}
              lazyUpdate={true}
            />
          ),
          [chart, color, datas]
        )}
        <MDBox pt={3} pb={1} px={1}>
          {subject?.map((item, index) => {
            return (
              <Button
                key={index}
                variant="outlined"
                size="small"
                style={{ color: "black", margin: 5 }}
              >
                {item.subject_name}
              </Button>
            );
          })}
        </MDBox>
        <MDBox pt={3} pb={1} px={1}>
          <MDTypography variant="h6" textTransform="capitalize">
            {title}
          </MDTypography>
          <MDTypography component="div" variant="button" color="text" fontWeight="light">
            {description}
          </MDTypography>
          <Divider />
          <MDBox display="flex" alignItems="center">
            <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
              <Icon>schedule</Icon>
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="light">
              {date}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ReportsLineChart
ReportsLineChart.defaultProps = {
  color: "info",
  description: "",
};

// Typechecking props for the ReportsLineChart
ReportsLineChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string.isRequired,
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default ReportsLineChart;
