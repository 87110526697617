/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import "../layouts/quiz/QuizOptions.css"; // Import CSS file for styling
import Radio from "@mui/material/Radio";
import { styled } from "@mui/material/styles";

const QuizOptionsNeet = (props) => {
  const options = ["Option 1", "Option 2", "Option 3", "Option 4"];
  const optionRef = useRef(null);
  const [fontSize, setFontSize] = useState(22);
  const [selectedIndex, setSelectedIndex] = useState();

  useEffect(() => {
    if (!optionRef.current) return;

    const { lineHeight, height } = getComputedStyle(optionRef.current);
    const numLines = Math.ceil(parseInt(height, 10) / parseInt(lineHeight, 10));
    // Set font size based on number of lines within the given range
    setFontSize(Math.min(Math.max(16, 22 - (numLines - 1)), 22));
  }, []);
  function getIndexToLetter(index) {
    const letters = ["a", "b", "c", "d"];
    return letters[index];
  }
  function getLetterToIndex(letter) {
    const letters = ["a", "b", "c", "d"];
    return letters.indexOf(letter);
  }
  function updateItemAtIndex(arr, index, newValue) {
    if (index < 0 || index >= arr.length) {
      // Handle invalid index
      console.error("Invalid index:", index);
      return arr;
    }

    arr[index] = newValue;
    return arr;
  }

  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: 16,
    height: 16,
    // margin:10,
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px rgb(16 22 26 / 40%)"
        : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: theme.palette.mode === "dark" ? "rgba(57,75,89,.5)" : "rgba(206,217,224,.5)",
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#137cbd",
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&::before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  });
  function BpRadio(props) {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }
  return (
    <div className="container">
      <div className="optionsContainer">
        {props.data.map((option, index) => (
          <div
            key={index}
            className={"option"}
            style={{ backgroundColor: selectedIndex === index ? "#023f81" : "#dedede", margin: 10 }}
            ref={optionRef}
          >
            <BpRadio
              className={
                props.questiondata.correct_answer == getIndexToLetter(index)
                  ? "option-selected-right"
                  : "option"
              }
              checked={props.questiondata.correct_answer == getIndexToLetter(index)}
              value="a"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />

            <h4 style={{ marginRight: 3, fontSize: "14px", marginTop: "8px" }}>
              ({getIndexToLetter(index).toLowerCase()})
            </h4>
            <div>
              {option?.image ? (
                <img
                  src={option?.image}
                  style={{
                    maxWidth: "600px",
                    minWidth: "250px",
                    minHeight: "100px",
                    maxHeight: "250px",
                  }}
                />
              ) : (
                <div />
              )}
              {option?.text && option?.text != ""
                ? option?.text.replace(/\(a\)|\(b\)|\(c\)|\(d\)/g, "")
                : ""}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuizOptionsNeet;
