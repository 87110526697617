/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import "./TakeQuizTimer.css";
const keyframes = `
  @keyframes siren-animation {
    0% { background-color: #fff; }
    50% { background-color: #ff5c5c; }
    100% { background-color: #fff; }
  }

  @-webkit-keyframes siren-animation {
    0% { background-color: #fff; }
    50% { background-color: #ff5c5c; }
    100% { background-color: #fff; }
  }

  @-moz-keyframes siren-animation {
    0% { background-color: #ffff; }
    50% { background-color: #ff5c5c; }
    100% { background-color: #fff; }
  }
`;

const styles = {
  Input: {
    width: "200px",
    height: "60px",
    padding: "0px 8px",
    border: "1px solid #919191",
    boxSizing: "border-box",
    borderRadius: "8px",
    fontSize: "40px",
    fontFamily: "Orbitron, sans-serif", // Using Roboto Mono for digital effect
    lineHeight: "55px",
    outline: "none",
    textAlign: "center",
    animation: "siren-animation 0.5s infinite alternate",
    letterSpacing: 2,
    color: "#000",
  },
};

const QuizTimer = ({ initialTime = 600, showModal }) => {
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timer);
          showModal(true);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  return (
    <>
      <style>{keyframes}</style>
      <input
        style={{
          ...styles.Input,
          animationName: time <= 30 && time > 0 ? "siren-animation" : "none",
        }}
        value={formatTime(time)}
        readOnly
        disabled={true}
      />
    </>
  );
};

export default QuizTimer;
