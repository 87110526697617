/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import faker from "faker";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Modal from "react-modal";
import Lottie from "react-lottie";
import axios from "axios";
import { CONSTANTS } from "constants/Constants";
import { auth } from "../../../../firebase/firebase";
import { Button, IconButton, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { ArrowForwardIos, Close } from "@mui/icons-material";
import analysis from "./assets/analysis.json";
import performancereport from "./assets/performancereport.json";
import generating from "./assets/generating.json";
import { RotatingLines } from "react-loader-spinner";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: analysis,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const defaultOption1 = {
  loop: true,
  autoplay: true,
  animationData: performancereport,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const defaultOption2 = {
  loop: true,
  autoplay: true,
  animationData: generating,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
  content: {
    width: "70%",
    maxWidth: "600px",
    margin: "auto",
    border: "none",
    borderRadius: "8px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "auto",
  },
  "::-webkit-scrollbar": {
    width: "4px",
    height: "4px",
  },
  "::-webkit-scrollbar-thumb": {
    background: "gray",
    borderRadius: "2px",
  },
  closeButton: {
    cursor: "pointer",
  },
};

const customStylesPhone = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
  content: {
    width: "80%",
    maxWidth: "400px",
    margin: "auto",
    border: "none",
    borderRadius: "8px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "auto",
  },
  "::-webkit-scrollbar": {
    width: "2px",
    height: "2px",
  },
  "::-webkit-scrollbar-thumb": {
    background: "gray",
    borderRadius: "1px",
  },
};

if (window.innerWidth > 768) {
  customStyles.content.width = "80%";
  customStyles.content.maxWidth = "600px";
}

export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
    },
  },
};

const labels = ["T1", "T2", "T3", "T4", "T5", "T6", "T7"];

export const data = {
  labels,
  datasets: [
    {
      label: "Attended",
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Not-Attended",
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
    {
      label: "Skipped",
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: "rgb(0, 255, 0)",
      backgroundColor: "rgba(0, 255, 0, 0.5)",
    },
  ],
};

export function MultiLineChart({
  color,
  title,
  description,
  date,
  chart,
  subject,
  exam_name,
  exam_id,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [subjectdata, setSubjectData] = useState();
  const [chartData, setChartData] = useState();
  const [loading, setLoading] = useState(true);
  const [selectedSubject, setSelectedSubject] = useState();
  const [labels, setLabels] = useState();
  const [generatingDetailed, setgeneratingdetailed] = useState(false);
  const [generatedExplanation, setgeneratedExplanation] = useState();

  // useEffect(() => {
  //   setSelectedSubject(
  //     subject && subject.length > 0
  //       ? subject[0]
  //       : {
  //           subject_id: "a31168ae-d61d-40df-b52e-26804b527aba",
  //           subject_name: "Polity",
  //         }
  //   );
  //   getScoreSubjectWise(
  //     subject && subject.length > 0
  //       ? subject[0]?.subject_id
  //       : "a31168ae-d61d-40df-b52e-26804b527aba"
  //   );
  // }, [subject]);
  const generatePerformanceReport = () => {
    setgeneratingdetailed(true);
    setModalOpen(true);
    axios
      .post(`${CONSTANTS.APIURL}/api/graph/explanation`, {
        input: {
          data: JSON.stringify(chartData),
          exam: exam_name,
          prompt:
            "The data consists of subject_name and test_count where test_count denotes the number of tests attempted for a particular subject",
        },
      })
      .then((response) => {
        setgeneratedExplanation(response?.data?.graph_explanation);
        setgeneratingdetailed(false);
      })
      .catch((err) => {
        console.log(err);
        setgeneratingdetailed(false);
        setIsDetailed(false);
      });
  };
  useEffect(() => {
    setChartData();
    setSelectedSubject(
      subject && subject?.length > 0
        ? subject[0]
        : {
            subject_id: "a31168ae-d61d-40df-b52e-26804b527aba",
            subject_name: "Polity",
          }
    );
    getScoreSubjectWise(
      subject && subject?.length > 0
        ? subject[0]?.subject_id
        : "a31168ae-d61d-40df-b52e-26804b527aba"
    );
  }, [subject]);

  const getScoreSubjectWise = (subject_id) => {
    setLoading(true);
    axios
      .get(`${CONSTANTS.PysparkURL}/api/analysis/analysis_subject_score`, {
        params: {
          user_id: auth?.currentUser ? auth?.currentUser.uid : "E9mGW5hhmNZdLdU9ksh0IxdgURu2",
          subject_id: subject_id,
          exam_id: exam_id,
          type: "linechart",
        },
      })
      .then((res) => {
        //setChartData(res.data.analysis);
        var labelsarray = [];
        var correctanswer = [];
        var wronganswer = [];
        var skippedanswer = [];
        res.data.analysis.correct_answer.map((item, index) => {
          labelsarray.push(item.label);
          correctanswer.push(item.value);
        });
        res.data.analysis.wrong_answer.map((item, index) => {
          wronganswer.push(item.value);
        });
        res.data.analysis.skipped_answer.map((item, index) => {
          skippedanswer.push(item.value);
        });
        var chartObj = {
          correct_answer: [0, ...correctanswer],
          wrong_answer: [0, ...wronganswer],
          skipped_answer: [0, ...skippedanswer],
        };
        console.log("getdata", chartObj);
        setChartData(chartObj);
        setLabels(["0", ...labelsarray]);
        setLoading(false);
        // console.log(selectedSubject)
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Card sx={{ height: "100%", borderColor: "gold", borderWidth: 1 }} elevation={10}>
      <MDBox padding="1rem">
        {/* <MDBox pt={3} pb={1} px={1}> */}
        <Typography variant="h6" textTransform="capitalize" textAlign="center">
          {title}
        </Typography>
        <RotatingLines
          visible={loading}
          height="20"
          width="20"
          color="grey"
          strokeWidth="5"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
        />
        {/* </MDBox> */}

        {useMemo(
          () => (
            <Line
              options={options}
              data={{
                labels,
                datasets: [
                  {
                    label: "Correct Answer",
                    data: chartData?.correct_answer || [],
                    borderColor: "rgb(0, 255, 0)",
                    backgroundColor: "rgba(0, 255, 0, 0.2)",
                  },
                  {
                    label: "Wrong Answer",
                    data: chartData?.wrong_answer || [],

                    borderColor: "rgb(255, 0, 0)",
                    backgroundColor: "rgba(255, 0, 0, 0.2)",
                  },
                  {
                    label: "Skipped Answer",
                    data: chartData?.skipped_answer || [],
                    borderColor: "rgb(0, 0, 255)",
                    backgroundColor: "rgba(0, 0, 255, 0.2)",
                  },
                ],
              }}
              height={200}
              width={250}
            />
          ),
          [chartData, labels]
        )}

        <MDBox pt={1} pb={1} px={1}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexWrap: "wrap",
            }}
          >
            {subject?.map((item, index) => (
              <button
                key={index}
                style={{
                  color: "black",
                  margin: "5px",
                  backgroundColor:
                    item.subject_id === selectedSubject?.subject_id ? "#ffd700" : "#fff",
                  border: "1px solid gold",
                  // width: "100px",
                  height: "30px",
                  borderRadius: 8,
                  padding: 8,
                  fontSize: "12px",
                  fontWeight: item.subject_id === selectedSubject?.subject_id ? "500" : "200",
                }}
                onClick={() => {
                  console.log(item);
                  setSelectedSubject(item);
                  getScoreSubjectWise(item?.subject_id);
                }}
              >
                {item.subject_name}
              </button>
            ))}
          </div>
          <Button
            variant="contained"
            size="small"
            fullWidth
            endIcon={<ArrowForwardIosIcon />}
            style={{ color: "white" }}
            onClick={() => {
              setModalOpen(!modalOpen);
              generatePerformanceReport();
            }}
          >
            {generatingDetailed ? "Generating your insights" : "Get your performance insights "}
          </Button>
        </MDBox>

        <MDBox pt={3} pb={1} px={1}>
          <MDTypography variant="h6" textTransform="capitalize">
            {title}
          </MDTypography>
          <MDTypography component="div" variant="button" color="text" fontWeight="light">
            {description}
          </MDTypography>
          <Divider />
          <MDBox display="flex" alignItems="center">
            <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
              <Icon>schedule</Icon>
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="light">
              {date}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>

      <Modal
        isOpen={modalOpen}
        onClose={setModalOpen}
        style={window.innerWidth > 768 ? customStyles : customStylesPhone}
        ariaHideApp={false}
        position="fixed"
      >
        <div>
          {generatingDetailed ? (
            <div
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                flex: 1,
                justifyContent: "center",
              }}
            >
              <Lottie
                options={defaultOption2}
                height={100}
                width={100}
                style={{ alignSelf: "start" }}
              />
              <Typography variant="h6" textAlign="center">
                Generating Your Detailed Performance Insights
              </Typography>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4">Performance Insights</Typography>
                <IconButton onClick={() => setModalOpen(false)}>
                  <Close />
                </IconButton>
              </div>
              <Lottie
                options={defaultOption1}
                height={200}
                width={200}
                style={{ alignSelf: "start" }}
              />
              <Typography variant="h6" style={{ textAlign: "justify" }}>
                {generatedExplanation?.replace(/\*/g, "")}
              </Typography>
              <div style={{ height: 30 }} />
            </div>
          )}
        </div>
      </Modal>
    </Card>
  );
}

MultiLineChart.defaultProps = {
  color: "info",
  description: "",
};

MultiLineChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string.isRequired,
  chart: PropTypes.object.isRequired,
};
export default MultiLineChart;
