/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import "./AutomaticTextChanger.css"; // Import CSS file for styling

const AutomaticTextChanger = ({ texts, interval = 3000 }) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [showNextText, setShowNextText] = useState(true);
  const [currentColor, setCurrentColor] = useState("#FF5733"); // Initial color

  useEffect(() => {
    const intervalId = setInterval(() => {
      setShowNextText(false);
      setTimeout(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex === texts.length - 1 ? 0 : prevIndex + 1));
        setCurrentColor(getRandomColor()); // Generate random color
        setShowNextText(true);
      }, 500); // Delay for smooth transition
    }, interval);

    return () => clearInterval(intervalId);
  }, [texts, interval]);

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <div className="automatic-text-changer">
      {showNextText && (
        <div className="text-container">
          <h4 className="fade-in colorful-text" style={{ color: currentColor }}>
            {texts[currentTextIndex]}
          </h4>
        </div>
      )}
    </div>
  );
};

export default AutomaticTextChanger;
