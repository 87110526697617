/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Lottie from "react-lottie";
import animationData from "../loadingnew.json"; // Replace 'animation.json' with your Lottie animation file
import QuizQuestionComponent from "../QuizQuestionComponent";
import { client } from "constants/APIFunctions";
import { auth } from "../../../firebase/firebase";
import { useCookies } from "react-cookie";
import { Button, Typography } from "@mui/material";
import { CancelOutlined } from "@mui/icons-material";
import SimilarQuizQuestionComponent from "./SimilarQuizQuestionComponent";
import brandWhite from "assets/images/applogo.png";
import QuizQuestionComponentAnalysis from "../QuizQuestionComponentAnalysis";
import SimilarQuizQuestionComponentAnalysis from "./SimilarQuizQuestionComponentAnalysis";
import Sconti from "../../../Sconti.png";

// Modal styles
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
  content: {
    width: "70%",
    maxWidth: "600px",
    margin: "auto",
    border: "none",
    borderRadius: "8px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "auto", // Ensure scrollbar is available if content overflows
  },
  "::-webkit-scrollbar": {
    width: "4px", // Reduce the width of the scrollbar
    height: "4px", // For horizontal scrollbars, if any
  },
  "::-webkit-scrollbar-thumb": {
    background: "gray", // Color of the scrollbar thumb
    borderRadius: "2px", // Rounded corners for the scrollbar thumb
  },
  closeButton: {
    cursor: "pointer",
  },
};
const customStylesPhone = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
  content: {
    width: "80%",
    maxWidth: "400px",
    margin: "auto",
    border: "none",
    borderRadius: "8px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "auto", // Ensure scrollbar is available if content overflows
  },
  "::-webkit-scrollbar": {
    width: "2px", // Reduce the width more for smaller devices
    height: "2px", // For horizontal scrollbars, if any
  },
  "::-webkit-scrollbar-thumb": {
    background: "gray", // Color of the scrollbar thumb
    borderRadius: "1px", // Rounded corners for the scrollbar thumb on smaller devices
  },
};

if (window.innerWidth > 768) {
  customStyles.content.width = "80%"; // Adjust the width as needed
  customStyles.content.maxWidth = "600px"; // Adjust the max-width as needed
}
const SimilarQuestionsModal = ({ isOpen, onClose, data, question }) => {
  const [quizdata, setQuizdata] = useState([]);
  const [animation, setAnimation] = useState(animationData);
  const [animationText, setAnimationText] = useState("AI is preparing your quiz");
  const [cookies, setCookie] = useCookies(["user"]);
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showScore, setShowscore] = useState(false);
  const [totalScore, setTotalscore] = useState(0);
  const [retry, setRetry] = useState(false);
  const [userdata, setuserdata] = useState();

  const questionsPerPage = 1;
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("userdata"));
    setuserdata(items);
    var userdata = items;
    setShowscore(false);
    setQuizdata([]);
    setResponses([]);
    setAnimationText("AI is preparing your quiz");
    setLoading(true);
    if (question) {
      client
        .post("/api/test/generate-similar-test", {
          subject_name: data?.subject?.subject_name
            ? data?.subject?.subject_name
            : "All UPSC subjects",
          topic_name: data?.topic?.topic_name ? data?.topic?.topic_name : "",
          sub_topic_name: data?.subTopic?.sub_topic_name ? data?.subTopic?.sub_topic_name : "",
          number_of_questions: 5,
          difficulty_level: data?.difficulty?.name ? data?.difficulty?.name : "Advanced",
          user_id: auth.currentUser
            ? auth.currentUser.uid
            : userdata?.user_id
            ? userdata?.user_id
            : "813b6e33-91ff-49f0-8e08-2f479c95e4e1",
          user_name: cookies?.userdata?.fullname ? cookies?.userdata?.fullname : "Nyc",
          subject_id: data?.subject?.subject_id ? data?.subject?.subject_id : "allsubject",
          topic_id: data?.topic?.topic_id ? data?.topic?.topic_id : "alltopic",
          sub_topic_id: data?.subTopic?.sub_topic_id ? data?.subTopic?.sub_topic_id : "allsubtopic",
          exam_id: data?.exams?.exam_id ? data?.exams?.exam_id : "all",
          exam_name: data?.exams?.exam_name ? data?.exams?.exam_name : "all",
          user_type: "user",
          reference_question: question,
        })
        .then((response) => {
          console.log(response);
          setQuizdata(response.data.questions);
          setResponses(response.data.questions);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          // alert("An error occurred");
          setAnimationText("An error occurred");
          setAnimation(err);
        });
    }
  }, [question, retry]);
  const handleNextQuestion = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const handlePreviousQuestion = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };
  const handleSubmitQuiz = () => {
    console.log(responses);
    var score = 0;
    responses.map((item) => {
      if (item.correct_option == item.answer) {
        score = score + 1;
      }
    });
    setShowscore(true);
    setTotalscore(score);
  };
  const handleNextPage = () => {
    setCurrentIndex((prevIndex) => prevIndex + questionsPerPage);
  };

  const handlePreviousPage = () => {
    setCurrentIndex((prevIndex) => prevIndex - questionsPerPage);
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={window.innerWidth > 768 ? customStyles : customStylesPhone}
      ariaHideApp={false}
      position="fixed"
    >
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          height: "50px",
          backgroundColor: "#e2e275",
          padding: "5px",
          borderRadius: "8px",
          maxHeight: "50px",
        }}
      >
        <div style={{ flexDirection: "row", display: "flex" }}>
          <img
            src={Sconti}
            alt="Logo"
            style={{ width: "40px", height: "auto", marginRight: "5px" }}
          />
          {/* <span style={{ fontSize: "18px", color: "#000" }}>Sconti.ai</span> */}
        </div>
        <h5
          style={{ alignItems: "center", marginTop: "12px", fontSize: "16px", fontWeight: "bold" }}
        >
          SAMAI.AI-Crack {quizdata && quizdata.length > 0 ? `the ${quizdata.length}` : "it"}
        </h5>
        <div
          onClick={() => {
            onClose(false);
          }}
          style={customStyles.closeButton}
        >
          <CancelOutlined />
        </div>
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          <Lottie options={defaultOptions} height={150} width={150} />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography variant="h4" style={{ textAlign: "center" }}>
              {animationText}
            </Typography>
            {animationText.includes("error") ? (
              <Button
                variant="contained"
                onClick={() => {
                  setRetry(!retry);
                }}
                style={{
                  marginTop: "20px",
                  color: "#ffffff",
                  alignSelf: "center",
                  textAlign: "center",
                  justifySelf: "center",
                }}
              >
                Retry...
              </Button>
            ) : (
              <div />
            )}
          </div>
        </div>
      ) : showScore ? (
        <div
          style={{
            display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          <h4 style={{ fontWeight: "bolder", textAlign: "center", marginTop: "8px" }}>
            You cracked the {quizdata.length} questions, your results are out..
          </h4>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginTop: "20px", // Add spacing between heading and circular div
            }}
          >
            <div
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "130px",
                backgroundColor: "#8c2e22",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h2 style={{ color: "#fff", fontWeight: "bolder" }}>{totalScore}</h2>
              <div style={{ width: "140px", height: "2px", backgroundColor: "#fff" }} />
              <h2 style={{ color: "#fff", fontWeight: "bolder" }}>{quizdata?.length}</h2>
            </div>
          </div>
          {quizdata.map((item, index) => {
            return (
              <SimilarQuizQuestionComponentAnalysis
                key={item.question + index}
                data={item}
                index={index}
                // setReferenceQuestion={setReferenceQuestion}
                // open={setModalOpen}
              />
            );
          })}
        </div>
      ) : (
        <div style={{ marginBottom: "20px", display: "grid", justifyContent: "center" }}>
          {quizdata.map((item, index) => {
            return (
              <QuizQuestionComponent
                key={item.question}
                data={item}
                index={index}
                setResponses={setResponses}
                responses={responses}
              />
            );
          })}
          <Button
            variant="contained"
            onClick={handleSubmitQuiz}
            style={{
              marginTop: "20px",
              color: "#000000",
              alignSelf: "center",
              textAlign: "center",
              justifySelf: "center",
              backgroundColor: "gold",
              justifySelf: "center",
            }}
          >
            Submit Test
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default SimilarQuestionsModal;
