/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-undef */
import React, { useState } from "react";
import { Card, TextField, Button, Grid } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import { Email, LockRounded } from "@material-ui/icons";
import {
  ExpandLessRounded,
  ExpandMoreRounded,
  KeyOff,
  KeySharp,
  Password,
  Phone,
} from "@mui/icons-material";
import Google from "@mui/icons-material/Google";
import Checkbox from "@mui/material/Checkbox";
import ChipsArray from "./SelectExams";
import { client } from "constants/APIFunctions";
import { auth } from "../../../firebase/firebase";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const SignUpScreen = () => {
  const [checked, setChecked] = React.useState(false);
  const [showExams, setShowExams] = useState(false);
  const [userDetails, setUserDetails] = useState({
    full_name: "",
    email: "",
    password: "",
    phone_number: "",
    interested_exams: "",
  });
  const navigate = useNavigate();
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const [cookies, setCookie] = useCookies(["user"]);

  function checkEmpty(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === "") {
        return false; // Return false if any key has an empty string value
      }
    }
    return true; // Return true if all keys have non-empty string values
  }
  const signUp = () => {
    if (checkEmpty(userDetails)) {
      if (checked) {
        auth
          .createUserWithEmailAndPassword(userDetails.email, userDetails.password)
          .then((res) => {
            var user = { ...userDetails, user_id: res.user.uid };
            console.log(user);
            client
              .post("/api/user/register", user)
              .then((response) => {
                console.log(response);
                navigate("/upsc-kpsc-online-mocktest");
                toast.success("Registered Successfully...");
                var objtostore = {
                  user_id: res.user.uid,
                  fullname: userDetails.full_name,
                  email: userDetails.email,
                  phone_number: userDetails.phone_number,
                  interested_exams: userDetails.interested_exams,
                  user_type: "user",
                };
                setCookie("userdata", objtostore);
              })
              .catch((err) => {
                console.log(err);
                toast.error("An error occurred");
              });
          })
          .catch((err) => {
            console.log(err);
            toast.error(err?.message);
          });
      } else {
        toast.error("Please Accept terms and conditions...");
      }
    } else {
      toast.error("All Details are required...");
    }
  };
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ minHeight: "100vh", backgroundColor: "#080808" }}
    >
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        lg={4}
        style={{ backgroundColor: "#282828", borderRadius: "24px" }}
      >
        <Card
          style={{
            backgroundColor: "#282828",
            borderRadius: "24px",
            padding: "24px",
          }}
        >
          <ToastContainer />
          <div
            style={{
              color: "#ffffff",
              fontSize: "35px",
              fontFamily: "Source Sans Pro",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            Sign Up
          </div>
          <div
            style={{
              color: "#ffffff",
              fontSize: "14px",
              fontFamily: "Source Sans Pro",
              textAlign: "center",
              marginTop: "8px",
            }}
          >
            Join us to get started
          </div>
          <div style={{ flexDirection: "column", display: "flex", marginTop: "10px" }}>
            <div
              style={{
                color: "#ffffff",
                fontSize: "16px",
                fontFamily: "Source Sans Pro",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Full name
            </div>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              placeholder="Your full name"
              InputProps={{
                style: {
                  padding: "0px 8px",
                  borderRadius: "24px",
                  backgroundColor: "#333333",
                  color: "#bcbcbc",
                  fontSize: "14px",
                  fontFamily: "Source Sans Pro",
                  fontWeight: 500,
                  lineHeight: "18px",
                  outline: "none",
                },
              }}
              onChange={(e) => {
                setUserDetails({ ...userDetails, full_name: e.target.value });
              }}
            />
          </div>
          <div style={{ flexDirection: "column", display: "flex", marginTop: "8px" }}>
            <div
              style={{
                color: "#ffffff",
                fontSize: "16px",
                fontFamily: "Source Sans Pro",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Email
            </div>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              placeholder="Your email address"
              onChange={(e) => {
                setUserDetails({ ...userDetails, email: e.target.value });
              }}
              InputProps={{
                style: {
                  padding: "0px 8px",
                  borderRadius: "24px",
                  backgroundColor: "#333333",
                  color: "#bcbcbc",
                  fontSize: "14px",
                  fontFamily: "Source Sans Pro",
                  fontWeight: 500,
                  lineHeight: "18px",
                  outline: "none",
                },
                endAdornment: (
                  <InputAdornment position="start">
                    <Email style={{ color: "#bcbcbc" }} /> {/* Icon */}
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div style={{ flexDirection: "column", display: "flex", marginTop: "8px" }}>
            <div
              style={{
                color: "#ffffff",
                fontSize: "16px",
                fontFamily: "Source Sans Pro",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Phone number
            </div>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              placeholder="Enter your phone number"
              InputProps={{
                style: {
                  padding: "0px 8px",
                  borderRadius: "24px",
                  backgroundColor: "#333333",
                  color: "#bcbcbc",
                  fontSize: "14px",
                  fontFamily: "Source Sans Pro",
                  fontWeight: 500,
                  lineHeight: "18px",
                  outline: "none",
                },
                endAdornment: (
                  <InputAdornment position="start">
                    <Phone style={{ color: "#bcbcbc" }} /> {/* Icon */}
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setUserDetails({ ...userDetails, phone_number: e.target.value });
              }}
            />
          </div>
          <div style={{ flexDirection: "column", display: "flex", marginTop: "8px" }}>
            <div
              style={{
                color: "#ffffff",
                fontSize: "16px",
                fontFamily: "Source Sans Pro",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Exams you are preparing for
            </div>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              placeholder="Select Exams you are preparing for"
              disabled={true}
              value={userDetails?.interested_exams}
              InputProps={{
                style: {
                  padding: "0px 8px",
                  borderRadius: "24px",
                  backgroundColor: "#333333",
                  color: "#bcbcbc",
                  fontSize: "14px",
                  fontFamily: "Source Sans Pro",
                  fontWeight: 500,
                  lineHeight: "18px",
                  outline: "none",
                },
                endAdornment: (
                  <InputAdornment position="start">
                    {showExams ? (
                      <ExpandLessRounded style={{ color: "#bcbcbc" }} />
                    ) : (
                      <ExpandMoreRounded style={{ color: "#bcbcbc" }} />
                    )}
                    {/* Icon */}
                  </InputAdornment>
                ),
              }}
              onClick={() => {
                setShowExams(!showExams);
              }}
            />
            {showExams ? <ChipsArray setExams={setUserDetails} exams={userDetails} /> : <div />}
          </div>

          <div style={{ flexDirection: "column", display: "flex", marginTop: "8px" }}>
            <div
              style={{
                color: "#ffffff",
                fontSize: "16px",
                fontFamily: "Source Sans Pro",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Create your password
            </div>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              placeholder="Create a strong password"
              type="password"
              InputProps={{
                style: {
                  padding: "0px 8px",
                  borderRadius: "24px",
                  backgroundColor: "#333333",
                  color: "#bcbcbc",
                  fontSize: "14px",
                  fontFamily: "Source Sans Pro",
                  fontWeight: 500,
                  lineHeight: "18px",
                  outline: "none",
                },
                endAdornment: (
                  <InputAdornment position="start">
                    <KeySharp style={{ color: "#bcbcbc" }} /> {/* Icon */}
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setUserDetails({ ...userDetails, password: e.target.value });
              }}
            />
          </div>
          <div style={{ flexDirection: "column", display: "flex", marginTop: "8px" }}>
            <div
              style={{
                color: "#ffffff",
                fontSize: "16px",
                fontFamily: "Source Sans Pro",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Confirm password
            </div>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              placeholder="Confirm your password"
              type="password"
              InputProps={{
                style: {
                  padding: "0px 8px",
                  borderRadius: "24px",
                  backgroundColor: "#333333",
                  color: "#bcbcbc",
                  fontSize: "14px",
                  fontFamily: "Source Sans Pro",
                  fontWeight: 500,
                  lineHeight: "18px",
                  outline: "none",
                },
                endAdornment: (
                  <InputAdornment position="start">
                    <LockRounded style={{ color: "#bcbcbc" }} /> {/* Icon */}
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div
            style={{
              color: "#ffffff",
              fontSize: "12px",
              fontFamily: "Source Sans Pro",
              fontWeight: 500,
              lineHeight: "16px",
              marginTop: "8px",
            }}
          >
            <Checkbox
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
              style={{ color: "#ff9d00", marginRight: "5px" }}
            />
            By signing up, you agree to our Terms and Privacy policy
          </div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{
              width: "90%",
              height: "42px",
              padding: "0px 8px",
              border: "0",
              boxSizing: "border-box",
              borderRadius: "24px",
              backgroundColor: "#fff",
              color: "#000",
              fontSize: "20px",
              fontFamily: "Source Sans Pro",
              fontWeight: 700,
              lineHeight: "39px",
              outline: "none",
              alignSelf: "center",
              marginTop: "8px",
              marginBottom: "8px",
              marginLeft: "4%",
            }}
            onClick={signUp}
          >
            Sign Up
          </Button>
          <div
            style={{
              color: "#ffffff",
              fontSize: "16px",
              fontFamily: "Source Sans Pro",
              lineHeight: "19px",
              textAlign: "center",
              marginTop: "8px",
              marginBottom: "8px",
            }}
          >
            Already have an account? Login in
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SignUpScreen;
