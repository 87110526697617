import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FullScreenLottie from "layouts/quiz/FullScreenLottie";
import { auth } from "firebase/firebase";
import MDBox from "components/MDBox";
import { client } from "constants/APIFunctions";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import ReactGA from "react-ga4";
ReactGA.initialize("G-EDEXTHSM4C");
import Typography from "@mui/material/Typography";
import error from "../layouts/quiz/components/error.json";
import animationData from "../layouts/quiz/loadingnew.json";
import nodata from "../layouts/quiz/components/nodata.json";
import Grid from "@mui/material/Grid";
import QuizQuestionComponentAnalysis from "layouts/quiz/QuizQuestionComponentAnalysis";
import Lottie from "react-lottie";
import CustomizedTables from "layouts/quiz/components/QuizTable";
import Typist from "react-typist";
import NeetQuizQuestionPage from "./NeetQuizQuestionPage";
import { Helmet } from "react-helmet";
import axios from "axios";
import { CONSTANTS } from "constants/Constants";
import FullScreenLottieNeet from "./FullScreenLottieNeet";
import { Box, Button } from "@mui/material";
import { Translate } from "@material-ui/icons";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { db } from "../firebase/firebase";

// import animationDatabot from "../layouts/quiz/components/animationDatabot.json";
const styles = {
  Text: {
    color: "#030303",
    fontSize: "30px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: "500",
    lineHeight: "45px",
    marginLeft: "8px",
    width: "95%",
    textAlign: "center",
  },
  Summarytext: {
    color: "#030303",
    fontSize: "16px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: "500",
    lineHeight: "22px",
    width: "auto",
    textAlign: "justify",
    whiteSpace: "pre-wrap",
    maxHeight: 300,
    overflowY: "none",
  },
  TextSelected: {
    color: "#023f81",
    fontSize: "20px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: "500",
    lineHeight: "24px",
    marginLeft: "8px",
    width: "95%",
    textAlign: "center",
    marginTop: "10px",
  },
  TextSelectedBot: {
    //color: "#023f81",
    fontSize: "20px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: "500",
    lineHeight: "26px",
    marginLeft: "8px",
    width: "95%",
    textAlign: "center",
    marginTop: "10px",
    alignSelf: "center",
    fontStyle: "oblique",
  },
  Button: {
    cursor: "pointer",
    top: "32px",
    left: "977px",
    width: "163px",
    height: "50px",
    padding: "0px 8px",
    border: "1px solid #919191",
    boxSizing: "border-box",
    borderRadius: "40px",
    backgroundColor: "rgba(255,255,255,0.07)",
    color: "#000000",
    fontSize: "18px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: 500,
    lineHeight: "24px",
    outline: "none",
  },
  ButtonSelected: {
    cursor: "pointer",
    top: "30px",
    left: "1188px",
    width: "163px",
    height: "50px",
    padding: "0px 8px",
    border: "0",
    boxSizing: "border-box",
    borderRadius: "40px",
    backgroundColor: "#2f667f",
    color: "#ffffff",
    fontSize: "18px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: 500,
    lineHeight: "24px",
    outline: "none",
    marginLeft: "8px",
  },
  Card: {
    width: "90%",
    height: "60px",
    backgroundColor: "#2f667f",
    borderRadius: "100px",
    boxShadow: "0px 2px 8px rgba(0,0,0,0.16)",
    marginBottom: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  CardText: {
    color: "#ffffff",
    fontSize: "22px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: "700",
    lineHeight: "29px",
    textAlign: "center",
    width: "90%",
  },
  CardButton: {
    cursor: "pointer",
    width: "201px",
    height: "53px",
    padding: "0px 8px",
    border: 0,
    boxSizing: "border-box",
    borderRadius: "40px",
    backgroundColor: "#ffffff",
    color: "#000000",
    fontSize: "20px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: "500",
    lineHeight: "24px",
    outline: "none",
    margin: "10px 0" /* Add margin for spacing */,
  },
};
const defaultProps = {
  text: "UPSC Quiz Page",
  label: "Skip",
  selectedLabel: "Submit",
};
// const defaultOptions = {
//   loop: true,
//   autoplay: true,
//   animationData: animationDatabot,
//   rendererSettings: {
//     preserveAspectRatio: "xMidYMid slice",
//   },
// };

const NeetQuestionPage = (props) => {
  const location = useLocation();
  const data = location.state && location.state.year;
  const paperType = location.state && location.state.isPaperType;
  const isLive = true;
  const type = location.state && location.state.type;
  const [analysis, setAnalysis] = useState();
  const [analysisData, setAnalysisData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [score, setscore] = useState(0);
  const [analyseData, setAnalyseData] = useState([]);
  const [analysisLoading, setAnalysisLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [examType, setExamType] = useState("UPSC");

  const navigate = useNavigate();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const [questiondata, setquestiondata] = useState([]);
  const [animation, setAnimation] = useState(animationData);
  // const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [selectedLanguage, setSelectedLanguage] = React.useState("2024");
  const handleChange = (event) => {
    setSelectedLanguage(event.target.value);
    setLastDoc(null);
    setquestiondata([]);
  };

  const [animationText, setAnimationText] = useState(
    "AI is generating prelims key answers for UPSC and KPSC"
  );
  const [isNextPageAvailable, setIsNextPageAvailable] = useState(false);
  const [isPrevPageAvailable, setIsPrevPageAvailable] = useState(false);
  const [page, setPage] = useState(0);
  const [lastDoc, setLastDoc] = useState(null);

  const pageSize = 10; // Number of items per page

  useEffect(() => {
    setLoading(true);
    fetchQuestions();
  }, [selectedLanguage]);

  const fetchQuestions = async (direction = "next") => {
    let query = db.collection("NEETQNS").where("year", "==", selectedLanguage).limit(pageSize);

    if (direction === "next" && lastDoc) {
      query = query.startAfter(lastDoc);
    } else if (direction === "prev" && lastDoc) {
      query = query.endBefore(lastDoc);
    }

    const snapshot = await query.get();
    console.log(snapshot);
    const documents = snapshot.docs.map((doc) => doc.data());
    setquestiondata([...questiondata, ...documents]);
    setLastDoc(snapshot.docs[snapshot.docs.length - 1] || null);

    setIsNextPageAvailable(documents.length === pageSize);
    setIsPrevPageAvailable(page > 0);

    setLoading(false);
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
    fetchQuestions("next");
  };

  const handlePrevPage = () => {
    if (page > 0) {
      setPage((prevPage) => prevPage - 1);
      fetchQuestions("prev");
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SAMAI- </title>
        <meta
          name="description"
          content="Prelims Answer Key For UPSC, KPSC, and UPPSC Mock Tests with Best AI. Solve Previous Year Questions Subject Wise."
        />
        <meta
          name="keyword"
          content="Free UPSC mock test online, UPSC mock test series, UPSC Civil Services Exam Mock Tests, UPSC Preliminary Test Series, UPSC Mains Test Series, KPSC Preliminary Mock Tests, NEET Entrance Exam Mock Tests, NEET practice questions, NEET sample tests, D-cet Entrance Exam Mock Tests, UPSC GS Mock Tests, UPSC CSE Mock Tests, KPSC GS Mock Tests, NEET Biology Mock Tests, NEET Physics Mock Tests, UPSC Current Affairs Mock Tests, UPSC CSAT Mock Tests, UPSC Optional Subject Mock Tests, UPSC Online Practice Tests, KPSC Competitive Exams Mock Tests, NEET Chemistry Mock Tests, UPSC Test Series for Beginners, KPSC Test Series for Beginners, UPSC Free Online Test Series, Mock Tests for UPSC Exams, NEET mock test series, NEET biology mock tests, NEET chemistry mock tests, NEET physics mock tests, NEET previous year papers with solutions, Practice mock test, Previous year question papers with solutions, Chapter-wise mock tests, Subject-wise mock tests, Online test series for competitive exams, Best mock test series for competitive exams, Free online mock tests for government exams, Mock test preparation tips, Best mock test for UPSC online, UPSC online mock test, KPSC online coaching, Best AI-Based test for NEET preparation, Online NEET Test platform, KPSC KAS mock tests, KPSC PSI mock tests,UPSC Mock Test, UPSC Test Series, UPSC Practice, KPSC Mock Test, NEET Mock Test, D-cet Mock Test, UPSC Civil Services, UPSC Prelims, UPSC Mains, KPSC Exams, NEET Test Series, UPSC GS, UPSC CSAT, UPSC Coaching, KPSC Coaching, NEET Preparation, Mock Test Series, Online Mock Test, Free Mock Test, Government Exams, UPPSC Mock Tests, UPPSC, Mock Tests"
        />
        <meta
          property="og:description"
          content="SAMAI-Free UPSC & KPSC with Best AI Mock Tests - with Scontinent"
        />
        <meta property="og:url" content="https://samai.scontiapp.com/PrelimsLanding" />
        <meta property="og:site_name" content="SAMAI-AI Mock Test " />
        <link rel="canonical" href="https://samai.scontiapp.com/PrelimsLanding" />
      </Helmet>
      <DashboardLayout>
        <DashboardNavbar absolute isMini />
        {loading ? (
          <MDBox mt={8}>
            <FullScreenLottie content={animationText} animation={animation} />
          </MDBox>
        ) : (
          <MDBox mt={8} style={{ backgroundColor: "#fff" }}>
            {isLive ? (
              <div>
                <div style={{ marginTop: "30px" }} />
                <Typography variant="h1" textAlign="center">
                  NEET PYQ-{selectedLanguage}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    flexDirection: "row",
                  }}
                >
                  <FormControl style={{ height: 100 }}>
                    <InputLabel id="demo-simple-select-label">Select Langauge</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedLanguage}
                      label="Selectede Langauge"
                      onChange={handleChange}
                      style={{
                        width: 150,
                        height: 50,
                        padding: 10,
                        borderColor: "orange",
                        fontWeight: 500,
                      }}
                      size="large"
                    >
                      <MenuItem value={"2024"} style={{ fontWeight: 500 }}>
                        2024
                      </MenuItem>
                      <MenuItem value={"2023"} style={{ fontWeight: 500 }}>
                        2023
                      </MenuItem>
                      <MenuItem value={"2022"} style={{ fontWeight: 500 }}>
                        2022
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <></>
                {questiondata?.map((item, index) => {
                  return (
                    <NeetQuizQuestionPage
                      key={item.question + index}
                      data={item}
                      index={index}
                      type={type}
                    />
                  );
                })}
                <div>
                  <button onClick={handlePrevPage} disabled={!isPrevPageAvailable}>
                    Previous
                  </button>
                  <button onClick={handleNextPage} disabled={!isNextPageAvailable}>
                    Next
                  </button>
                </div>
              </div>
            ) : (
              <>
                <FullScreenLottieNeet data={data} type={type} />
              </>
            )}
          </MDBox>
        )}
      </DashboardLayout>
    </>
  );
};

export default NeetQuestionPage;
