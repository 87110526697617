/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-undef */
import React, { useState } from "react";
import { Card, TextField, Button, Grid } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import { Email } from "@material-ui/icons";
import { Password, RemoveRedEye, VisibilityOff } from "@mui/icons-material";
import Google from "@mui/icons-material/Google";
import { firebase, auth, provider, storage } from "../../../firebase/firebase";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IconButton } from "@mui/material";
import { client } from "constants/APIFunctions";
import { useCookies } from "react-cookie";

const LoginForm = () => {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [hidePassword, setHidePassword] = useState(false);
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["user"]);

  function onChange(userDetails) {
    setCookie("userdata", userDetails);
  }
  const getUserDetails = (userId) => {
    client
      .get("/api/user/user_profile", {
        params: { user_id: userId },
      })
      .then((res) => {
        console.log(res);
        if (res.data.detail) {
          toast.error("User not found");
        } else {
          setCookie("userdata", res.data);
        }
        // if(res.)
      })
      .catch((err) => {});
  };
  const getUserDetailsGoogle = (userdata) => {
    client
      .get("/api/user/user_profile", {
        params: { user_id: userdata.user_id },
      })
      .then((res) => {
        console.log(res);
        if (res.data.detail) {
          var user = {
            user_id: userdata.user_id,
            full_name: userdata.user_name,
            email: userdata.user_email,
            password: "google@123",
            phone_number: "N/A",
            interested_exams: "N/A",
          };
          console.log(user);
          client
            .post("/api/user/register", user)
            .then((response) => {
              console.log(response);
              navigate("/upsc-kpsc-online-mocktest");
              toast.success("Registered Successfully...");
              var objtostore = {
                user_id: user.user_id,
                fullname: user.full_name,
                email: user.email,
                phone_number: user.phone_number,
                interested_exams: user.interested_exams,
                user_type: "user",
              };
              setCookie("userdata", objtostore);
            })
            .catch((err) => {
              console.log(err);
              // toast.error("An error occurred");
            });
        } else {
          setCookie("userdata", res.data);
        }
        // if(res.)
      })
      .catch((err) => {});
  };
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ minHeight: "100vh", backgroundColor: "#080808" }}
    >
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        lg={4}
        style={{ backgroundColor: "#282828", borderRadius: "24px" }}
      >
        <Card
          style={{
            backgroundColor: "#282828",
            borderRadius: "24px",
            padding: "24px",
            justifyContent: "center",
          }}
        >
          <ToastContainer />

          <h2
            style={{
              color: "#ffffff",
              fontSize: "35px",
              fontFamily: "Source Sans Pro",
              fontWeight: "500",
              lineHeight: "45px",
              textAlign: "center",
            }}
          >
            SignIn
          </h2>
          <div
            style={{
              color: "#ffffff",
              fontSize: "14px",
              fontFamily: "Source Sans Pro",
              lineHeight: "18px",
              textAlign: "center",
              top: -45,
            }}
          >
            Join us to get started
          </div>
          <div style={{ flexDirection: "row", display: "flex" }}>
            <div
              style={{
                color: "#ffffff",
                fontSize: "20px",
                fontFamily: "Source Sans Pro",
                fontWeight: 500,
                lineHeight: "24px",
                textAlign: "center",
                alignSelf: "center",
              }}
            >
              Email/Phone
            </div>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              placeholder="Email Address or Phone Number"
              InputProps={{
                style: {
                  padding: "0px 8px",
                  borderRadius: "24px",
                  backgroundColor: "#333333",
                  color: "#bcbcbc",
                  fontSize: "14px",
                  fontFamily: "Source Sans Pro",
                  fontWeight: 500,
                  lineHeight: "18px",
                  outline: "none",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <Email style={{ color: "#bcbcbc" }} /> {/* Icon */}
                  </InputAdornment>
                ),
              }}
              style={{ marginLeft: "5px" }}
              onChange={(e) => {
                setemail(e.target.value);
              }}
            />
          </div>
          <div style={{ flexDirection: "row", display: "flex", marginTop: "8px" }}>
            <div
              style={{
                color: "#ffffff",
                fontSize: "20px",
                fontFamily: "Source Sans Pro",
                fontWeight: 500,
                lineHeight: "24px",
                textAlign: "center",
                alignSelf: "center",
              }}
            >
              Password
            </div>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              placeholder="Enter your password"
              type={hidePassword ? "password" : "text"}
              InputProps={{
                style: {
                  padding: "0px 8px",
                  borderRadius: "24px",
                  backgroundColor: "#333333",
                  color: "#bcbcbc",
                  fontSize: "14px",
                  fontFamily: "Source Sans Pro",
                  fontWeight: 500,
                  lineHeight: "18px",
                  outline: "none",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <Password style={{ color: "#bcbcbc" }} /> {/* Icon */}
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      setHidePassword(!hidePassword);
                    }}
                    style={{ color: "#bcbcbc" }}
                  >
                    {hidePassword ? <VisibilityOff /> : <RemoveRedEye />}
                  </IconButton>
                ),
              }}
              style={{ marginLeft: "30px" }}
              onChange={(e) => {
                setpassword(e.target.value);
              }}
            />
          </div>
          <div
            style={{
              color: "#ffffff",
              fontSize: "16px",
              fontFamily: "Source Sans Pro",
              lineHeight: "19px",
              textAlign: "right",
            }}
          >
            Forgot Password?
          </div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{
              width: "176px",
              height: "42px",
              padding: "0px 8px",
              border: "0",
              boxSizing: "border-box",
              borderRadius: "24px",
              backgroundColor: "#2f667f",
              color: "#ffffff",
              fontSize: "20px",
              fontFamily: "Source Sans Pro",
              fontWeight: 700,
              lineHeight: "39px",
              outline: "none",
              alignSelf: "center",
              marginLeft: "32%",
              marginTop: "8px",
              marginBottom: "8px",
            }}
            onClick={() => {
              if (email && email != "" && password && password != "") {
                auth
                  .signInWithEmailAndPassword(email, password)
                  .then((res) => {
                    console.log(res);
                    getUserDetails(res.user.uid);
                    toast("Login Successful !");
                    navigate("/upsc-kpsc-online-mocktest");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              } else {
                toast.error("Pls fill all the details");
              }
            }}
          >
            Sign In
          </Button>
          <div
            style={{
              color: "#ffffff",
              fontSize: "16px",
              fontFamily: "Source Sans Pro",
              lineHeight: "19px",
              textAlign: "center",
              marginTop: "8px",
              marginBottom: "8px",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/signup");
            }}
          >
            Don't have an account? Sign Up
          </div>
          <div
            style={{
              color: "#ffffff",
              fontSize: "20px",
              fontFamily: "Source Sans Pro",
              lineHeight: "19px",
              textAlign: "center",
              marginTop: "15px",
              marginBottom: "8px",
            }}
          >
            or continue with
          </div>
          <Google
            htmlColor="#fff"
            style={{
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center", // Align vertically
              justifyContent: "center",
              textAlign: "center",
              marginLeft: "45%",
              marginTop: "15px",
              cursor: "pointer",
            }}
            onClick={() => {
              auth
                .signInWithPopup(provider)
                .then((res) => {
                  console.log(res);
                  toast("Login successful");
                  var userdata = {
                    user_id: res.user.uid,
                    user_name: res.additionalUserInfo.profile?.name,
                    user_email: res.additionalUserInfo.profile?.email,
                  };
                  getUserDetailsGoogle(userdata);
                  navigate("/upsc-kpsc-online-mocktest");
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default LoginForm;
