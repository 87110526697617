import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "App";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import SignInSide from "layouts/authentication/screens/InitialScreen";
import LoginForm from "layouts/authentication/screens/LoginScreen";
import SignUpScreen from "layouts/authentication/screens/SignUpScreen";
import { CookiesProvider } from "react-cookie";
import { useCookies } from "react-cookie";

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <CookiesProvider defaultSetOptions={{ path: "/" }}>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </CookiesProvider>
);
