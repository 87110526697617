/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import EastIcon from "@mui/icons-material/East";
import { Link, useNavigate } from "react-router-dom";
import PrelimsQuestionPage from "./PrelimsQuestionPage";
import { Helmet } from "react-helmet";
import { CardActionArea } from "@mui/material";
import Dashboard from "layouts/dashboard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PrelimsSingleQuestion from "./PrelimsSingleQuestion";
import MDBox from "components/MDBox";
import FullScreenLottiePreleims from "./FullScreenLottiePreleims";
import ComingSoon from "./assets/ComingSoon.json";
import ReactGA from "react-ga4";
import raj from "../raj.pdf";
import { Android } from "@material-ui/icons";
import TranslateIcon from "@mui/icons-material/Translate";
import QuizIcon from "@mui/icons-material/Quiz";

ReactGA.initialize("G-EDEXTHSM4C");

const data = [
  {
    id: 1,
    examType: "UPSC_PRELIMS_KEY_ANSWERS_2024_GS1",
    // borderColor: "45deg, #00A3FF 0%, #FF7E38 100%",
    isLive: true,
    year: "2024",
    type: "upsc",
    isPaperType: "GS1",
  },
  {
    id: 2,
    examType: "UPSC_PRELIMS_KEY_ANSWERS_2024_GS2",
    // borderColor: "45deg, #00A3FF 0%, #FF7E38 100%",
    isLive: true,
    year: "2024",
    type: "upsc",
    isPaperType: "GS2",
  },
  // {
  //   id: 3,
  //   examType: "KPSC_PRELIMS_KEY_ANSWERS_2024_GS1",
  //   // borderColor: "45deg, #00A3FF 0%, #FF7E38 100%",
  //   isLive: false,
  //   year: "2024",
  //   type: "kpsc",
  // },
  // {
  //   id: 4,
  //   examType: "KPSC_PRELIMS_KEY_ANSWERS_2024_GS2",
  //   // borderColor: "45deg, #00A3FF 0%, #FF7E38 100%",
  //   isLive: false,
  //   year: "2024",
  //   type: "kpsc",
  // },
  {
    id: 5,
    examType: "UPSC_PRELIMS_KEY_ANSWERS_2023_GS1",
    // borderColor: "45deg, #00A3FF 0%, #FF7E38 100%",
    isLive: true,
    year: "2023",
    type: "upsc",
    isPaperType: "GS1",
  },
  {
    id: 6,
    examType: "UPSC_PRELIMS_KEY_ANSWERS_2023_GS2",
    // borderColor: "45deg, #00A3FF 0%, #FF7E38 100%",
    isLive: true,
    year: "2023",
    type: "upsc",
    isPaperType: "GS2",
  },
  // {
  //   id: 7,
  //   examType: "KPSC_PRELIMS_KEY_ANSWERS_2023_GS1",
  //   // borderColor: "45deg, #00A3FF 0%, #FF7E38 100%",
  //   isLive: false,
  //   year: "2023",
  //   type: "kpsc",
  // },
  // {
  //   id: 8,
  //   examType: "KPSC_PRELIMS_KEY_ANSWERS_2023_GS2",
  //   // borderColor: "45deg, #00A3FF 0%, #FF7E38 100%",
  //   isLive: false,
  //   year: "2023",
  //   type: "kpsc",
  // },
];

const TypeCard = ({ item, index, onClick }) => {
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
    console.log("mam bande");
  };
  const handleMouseLeave = () => {
    setIsHover(false);
    console.log("dasas bande");
  };
  const style = {
    card: {
      maxWidth: isHover ? 350 : 345,
      width: isHover ? 350 : 345,
      height: isHover ? 160 : 150,
      maxHeight: isHover ? 160 : 150,
      borderColor: "#00a3ff",
      cursor: "pointer",
      borderWidth: 1,
      // backgroundImage: `linear-gradient(${item.background})`,
      boxShadow: 1,
      opacity: 1,
      transition: "transform opacity 0.5s ease-in-out",
    },
  };

  return (
    <div style={{ width: 345, height: 160 }}>
      <Card
        style={style.card}
        key={index}
        elevation={10}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <CardContent>
          <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
            {item.examType}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" endIcon={<EastIcon style={{ width: 26, height: 26 }} />}>
            Click to see key answers
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

const PrelimsLanding = () => {
  const navigate = useNavigate();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  const handleClick = (year, isLive, type, isPaperType) => {
    navigate(
      type === "upsc" ? "/prelims/upsc-prelims-answer-key" : "/prelims/kpsc-prelims-answer-key",
      {
        state: { year: year, isLive: isLive, type: type, isPaperType: isPaperType },
      }
    );
    ReactGA.event({
      category: "Prelims Main Page ",
      action: "User is going to check with the Prelims Page",
    });
  };
  const click = () => {
    const pdfUrl =
      "https://drive.google.com/viewerng/viewer?url=https://scontimedia.s3.amazonaws.com/scontimedia/Bangalore_13-06-2024_TheHindu.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "upsc-answer-key-2024.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SAMAI-UPSC Prelims Answer Key With Questions-CSAT and GS1 Paper |GS2 Paper </title>
        <meta
          name="description"
          content=" UPSC Prelims Answer Key for CSAT and GS Paper 1 to get accurate solutions. Evaluate your performance and estimate your scores with UPSC answer key"
        />
        <meta
          name="robots"
          content={"SAMAI-UPSC Prelims Answer Key With Questions-CSAT and GS1 Paper |GS2 Paper"}
        />
        <meta
          name="keyword"
          content="UPSC Preliminary Test Series Answer Key,UPSC Prelims Key Answeres 2024,UPSC Prelims Key Answeres 2023,UPSC GS1 Prelims Key Answeres 2024,UPSC GS2 Prelims Key Answeres 2024,UPSC GS2 Prelims Key Answeres 2024,UPSC GS1 Prelims Key Answeres 2024, KPSC Preliminary Test Series Answer Key, KPSC Prelims Answer Key 2023,KPSC Prelims Answer Key 2024,UPSC Prelims Answer Key,KPSC Prelims Answer Key,UPSC Answer Key,UPSC Answe Key 2023,UPSC Answer 2024,csat answer key,upsc question paper with answer key,UPSC Prelims Answer Key 2024, UPSC Prelims 2024 Answer Sheet, UPSC Civil Services Prelims 2024 Solutions, 2024 UPSC Prelims Official Answer Key, UPSC Prelims 2024 Answer Key Download, UPSC CSE Prelims 2024 Answer Key, UPSC Prelims 2024 Question Paper and Answers, UPSC Prelims 2024 Answer Key PDF, UPSC 2024 Prelims Answer Key by Coaching Institutes, UPSC Prelims 2024 Answer Key All Sets, How to Download UPSC Prelims Answer Key 2024, UPSC Prelims 2024 Answer Key with Solutions, UPSC Prelims 2024 Answer Key Release Date, UPSC Prelims 2024 Answer Key for Set A, B, C, D, UPSC Prelims 2024 Exam Analysis and Answer Key, UPSC Prelims 2024 GS Paper 1 Answer Key, UPSC Prelims 2024 GS Paper 2 Answer Key,"
        />
        <meta
          property="og:description"
          content="SAMAI-Free UPSC & KPSC with Best AI Mock Tests - with Scontinent"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://samai.scontiapp.com/prelims" />
        <meta property="og:site_name" content="SAMAI-AI Mock Test " />
        <meta property="og:locale" content="en_In" />
        <link rel="canonical" href="https://samai.scontiapp.com/prelims" />
      </Helmet>
      <DashboardLayout>
        <DashboardNavbar absolute isMini />
        {/* <MDBox mt={8}>
          <FullScreenLottiePreleims content={animationText} animation={ComingSoon} />
        </MDBox> */}
        <Typography variant="h1" textAlign="center">
          Prelims Key Answers
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: 5,
            margin: 5,
            flexWrap: "wrap",
            gap: 25,
          }}
        >
          <Button
            onClick={() => {
              window.open(
                "https://play.google.com/store/apps/details?id=com.scontinent.sconti&pcampaignid=web_share"
              );
            }}
            variant="contained"
            size="small"
            style={{ color: "#fff", backgroundColor: "#00a3c9" }}
            startIcon={<Android />}
          >
            Download App
          </Button>
          <Button
            onClick={() => {
              navigate("/dashboard");
            }}
            variant="contained"
            size="small"
            style={{ color: "#fff", backgroundColor: "#FA8F0B" }}
            startIcon={<QuizIcon />}
          >
            Take Test
          </Button>
        </div>
        <marquee>
          <Typography variant="body1" textAlign="center" style={{ fontSize: 28 }}>
            📢 Here you can see the key answers for the prelims of UPSC and KPSC🚨
          </Typography>
        </marquee>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: 5,
            margin: 5,
            flexWrap: "wrap",

            gap: 25,
          }}
        >
          <Button
            onClick={() => {
              navigate("/prelims/upsc-prelims-answer-key-2024", {
                state: { year: "2024", isLive: true, type: "UPSC", isPaperType: "GS1" },
              });
            }}
            variant="contained"
            size="large"
            style={{ color: "#fff", backgroundColor: "#00a3c9" }}
          >
            Download Answer Key for UPSC-2024-GS1
          </Button>
          <Button
            onClick={() => {
              navigate("/prelims/upsc-prelims-answer-key-2024", {
                state: { year: "2024", isLive: true, type: "UPSC", isPaperType: "GS2" },
              });
            }}
            variant="contained"
            size="large"
            style={{ color: "#fff", backgroundColor: "#00a3c9" }}
          >
            Download Answer Key for UPSC-2024-GS2
          </Button>

          <Button
            onClick={() => {
              navigate("/prelims/upsc-prelims-answer-key", {
                state: { year: "2024", isLive: true, type: "UPSC", isPaperType: "GS1" },
              });
            }}
            variant="contained"
            color="primary"
            size="large"
            style={{ color: "#fff", backgroundColor: "#FA8F0B" }}
          >
            UPSC Prelims Answer Key with Explanation-2024-GS-1
          </Button>
          <Button
            onClick={() => {
              navigate("/prelims/upsc-prelims-answer-key", {
                state: { year: "2024", isLive: true, type: "UPSC", isPaperType: "GS2" },
              });
            }}
            variant="contained"
            color="primary"
            size="large"
            style={{ color: "#fff", backgroundColor: "#FA8F0B" }}
          >
            UPSC Prelims Answer Key with Explanation-2024-GS-2
          </Button>

          <Link
            to="https://firebasestorage.googleapis.com/v0/b/sconti-e7f3a.appspot.com/o/sconti.pdf?alt=media&token=3bfd6f24-3a2c-4d27-9324-d68b40137338"
            download
            target="_self"
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ color: "#fff", backgroundColor: "#00a3c9" }}
            >
              Download UPSC Prelims Question Paper-GS1
            </Button>
          </Link>
          <Link
            to="https://firebasestorage.googleapis.com/v0/b/sconti-e7f3a.appspot.com/o/upsc%20paper%202.pdf?alt=media&token=ef902f35-bd1b-4eb0-b3cd-b0e8f47d9d43"
            download
            target="_self"
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{ color: "#fff", backgroundColor: "#00a3c9" }}
            >
              Download UPSC Prelims Question Paper-GS2
            </Button>
          </Link>
          {/* <Button
            onClick={() => {
              navigate("/prelims/upsc-prelims-answer-key", {
                state: { year: "2024", isLive: true, type: "UPSC", isPaperType: "GS1" },
              });
            }}
            variant="contained"
            color="primary"
            size="large"
            style={{ color: "#fff", backgroundColor: "#FA8F0B" }}
            startIcon={<TranslateIcon />}
          >
            UPSC Prelims Answer Key with Explanation-2024-GS-1-HINDI
          </Button>
          <Button
            onClick={() => {
              navigate("/prelims/upsc-prelims-answer-key", {
                state: { year: "2024", isLive: true, type: "UPSC", isPaperType: "GS2" },
              });
            }}
            variant="contained"
            color="primary"
            size="large"
            style={{ color: "#fff", backgroundColor: "#FA8F0B" }}
            startIcon={<TranslateIcon />}
          >
            UPSC Prelims Answer Key with Explanation-2024-GS-2-HINDI
          </Button> */}
        </div>
        <Typography variant="body1" component="h4" textAlign="justify">
          Analysing the <b>UPSC Prelims Answer Key</b> will help the applicants to know their
          correct and incorrect responses and help them calculate the marks that they are going to
          score in the Prelims exam.{" "}
          <b>The Civil Services Prelims exam will be conducted on 16th June 2024</b>, and the
          results for the same will be out in the next 15 days. Aspirants can solve the question
          papers and go through the UPSC Prelims 2024 answer key to check their correct answers and
          learn from their mistakes.
        </Typography>
        <div style={{ marginTop: 20 }}>
          <Typography variant="body1" component="h4" textAlign="justify">
            The official UPSC Answer Key will be uploaded on the official website after the
            recruitment process is over. Till then, you can have a look at the unofficial
            <b> UPSC Prelims Answer Key 2024 PDF of GS Paper 1 and CSAT</b> prepared by our experts
            which will be updated here right after the exam is over.
          </Typography>
        </div>

        <div style={{ marginTop: 40 }}>
          <Typography variant="h4" component="h1">
            Download UPSC Prelims Question-Paper-GS1 - 2024
          </Typography>
        </div>
        <table style={{ width: "100%", border: "1px solid", margin: 10, padding: 10 }}>
          <tr style={{ width: "100%", border: "1px solid", margin: 10, padding: 10 }}>
            <th style={{ border: "1px solid" }}>GS Paper 1 SET</th>
            <th style={{ border: "1px solid" }}>UPSC Answer Key PDF</th>
          </tr>
          <tr style={{ width: "100%", border: "1px solid", margin: 10, padding: 10 }}>
            <td style={{ border: "1px solid" }}>Gs Paper 1, Set-A</td>
            <td style={{ border: "1px solid" }}>
              <Link
                to="https://firebasestorage.googleapis.com/v0/b/sconti-e7f3a.appspot.com/o/GS1-SETA.pdf?alt=media&token=64704aec-0ec0-43c5-a397-3e011515708f"
                download
                target="_self"
              >
                <Button>Click To see Download</Button>
              </Link>
            </td>
          </tr>

          <tr style={{ width: "100%", border: "1px solid", margin: 10, padding: 10 }}>
            <td style={{ border: "1px solid" }}>Gs Paper 1, Set-B</td>
            <td style={{ border: "1px solid" }}>
              <Link
                to="https://firebasestorage.googleapis.com/v0/b/sconti-e7f3a.appspot.com/o/GS1-SETB.pdf?alt=media&token=0a5a18d4-f74e-4886-8dbd-c5a16f0f27dc"
                download
                target="_self"
              >
                <Button>Click To see Download</Button>
              </Link>
            </td>
          </tr>
          <tr style={{ width: "100%", border: "1px solid", margin: 10, padding: 10 }}>
            <td style={{ border: "1px solid" }}>Gs Paper 1, Set-C</td>{" "}
            <td style={{ border: "1px solid" }}>
              <Link
                to="https://firebasestorage.googleapis.com/v0/b/sconti-e7f3a.appspot.com/o/GS1-SET-C.pdf?alt=media&token=f775528c-d409-4a97-8a3c-3bf16a76e7fd"
                download
                target="_self"
              >
                <Button>Click To see Download</Button>
              </Link>
            </td>
          </tr>
          <tr style={{ width: "100%", border: "1px solid", margin: 10, padding: 10 }}>
            <td style={{ border: "1px solid" }}>Gs Paper 1, Set-D</td>{" "}
            <td style={{ border: "1px solid" }}>
              <Link
                to="https://firebasestorage.googleapis.com/v0/b/sconti-e7f3a.appspot.com/o/GS1-SET-D.pdf?alt=media&token=a0d68a14-3cf6-44d1-8953-95a9a5c80aed"
                download
                target="_self"
              >
                <Button>Click To see Download</Button>
              </Link>
            </td>
          </tr>
        </table>
        <div style={{ marginTop: 40 }}>
          <Typography variant="h4" component="h1">
            Download UPSC Prelims Question-Paper-GS2 - 2024
          </Typography>
        </div>
        <table style={{ width: "100%", border: "1px solid", margin: 10, padding: 10 }}>
          <tr style={{ width: "100%", border: "1px solid", margin: 10, padding: 10 }}>
            <th style={{ border: "1px solid" }}>GS Paper 2 SET</th>
            <th style={{ border: "1px solid" }}>UPSC Answer Key PDF</th>
          </tr>
          <tr style={{ width: "100%", border: "1px solid", margin: 10, padding: 10 }}>
            <td style={{ border: "1px solid" }}>Gs Paper 2, Set-A</td>
            <td style={{ border: "1px solid" }}>
              <Link
                to="https://firebasestorage.googleapis.com/v0/b/sconti-e7f3a.appspot.com/o/gs2A.pdf?alt=media&token=92b87ed8-ded3-4871-8235-08559bbe6546"
                download
                target="_self"
              >
                <Button>Click To see Download</Button>
              </Link>
            </td>
          </tr>

          <tr style={{ width: "100%", border: "1px solid", margin: 10, padding: 10 }}>
            <td style={{ border: "1px solid" }}>Gs Paper 2, Set-B</td>
            <td style={{ border: "1px solid" }}>
              <Link
                to="https://firebasestorage.googleapis.com/v0/b/sconti-e7f3a.appspot.com/o/GS2-B.pdf?alt=media&token=a2d92e1e-f140-43e1-999b-b35cf1aa66e0"
                download
                target="_self"
              >
                <Button>Click To see Download</Button>
              </Link>
            </td>
          </tr>
          <tr style={{ width: "100%", border: "1px solid", margin: 10, padding: 10 }}>
            <td style={{ border: "1px solid" }}>Gs Paper 2, Set-C</td>{" "}
            <td style={{ border: "1px solid" }}>
              <Link
                to="https://firebasestorage.googleapis.com/v0/b/sconti-e7f3a.appspot.com/o/GS2-C.pdf?alt=media&token=da0dfca3-4647-48a7-8beb-b46dfe7193e6"
                download
                target="_self"
              >
                <Button>Click To see Download</Button>
              </Link>
            </td>
          </tr>
          <tr style={{ width: "100%", border: "1px solid", margin: 10, padding: 10 }}>
            <td style={{ border: "1px solid" }}>Gs Paper 2, Set-D</td>{" "}
            <td style={{ border: "1px solid" }}>
              <Link
                to="https://firebasestorage.googleapis.com/v0/b/sconti-e7f3a.appspot.com/o/GS2-D.pdf?alt=media&token=d4a1a76b-60ff-4f99-ab38-0fcc521cf7e2"
                download
                target="_self"
              >
                <Button>Click To see Download</Button>
              </Link>
            </td>
          </tr>
        </table>
        <div>
          <div style={{ marginTop: 40 }}>
            <Typography variant="h4" component="h1">
              UPSC Prelims Answer Key With Explanation Given By SAMAI - 2024🤖
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: 5,
              margin: 5,
              flexWrap: "wrap",
              gap: 25,
            }}
          >
            {data.map((item, index) => {
              return (
                <>
                  <TypeCard
                    item={item}
                    index={index}
                    onClick={() => {
                      handleClick(item.year, item.isLive, item.type, item.isPaperType);
                    }}
                  />
                </>
              );
            })}
          </div>
        </div>
        <div style={{ marginTop: 40 }}>
          <Typography variant="h4" component="h1">
            UPSC Prelims Answer Key - Marking Scheme
          </Typography>
        </div>

        <table style={{ width: "100%", border: "1px solid", margin: 10, padding: 10 }}>
          <tr style={{ width: "100%", border: "1px solid", margin: 10, padding: 10 }}>
            <th>Particulars</th>
            <th>Paper1</th>
            <th>Paper2</th>
          </tr>
          <tr style={{ width: "100%", border: "1px solid", margin: 10, padding: 10 }}>
            <td>Correct Answer</td>
            <td>+2</td>
            <td>+2</td>
          </tr>
          <tr style={{ width: "100%", border: "1px solid", margin: 10, padding: 10 }}>
            <td>InCorrect Answer</td>
            <td>-0.33</td>
            <td>-0.33</td>
          </tr>
          <tr style={{ width: "100%", border: "1px solid", margin: 10, padding: 10 }}>
            <td>Notanswered</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr style={{ width: "100%", border: "1px solid", margin: 10, padding: 10 }}>
            <td>No.Of.Question</td>
            <td>10</td>
            <td>80</td>
          </tr>
          <tr style={{ width: "100%", border: "1px solid", margin: 10, padding: 10 }}>
            <td>Total Question</td>
            <td>200</td>
            <td>200</td>
          </tr>
        </table>

        {/* <PrelimsQuestionPage /> */}
        {/* <PrelimsSingleQuestion /> */}
      </DashboardLayout>
    </>
  );
};

export default PrelimsLanding;
