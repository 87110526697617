import { useEffect, useMemo, useState } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";
import ReactECharts from "echarts-for-react";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// ReportsBarChart configurations
import configs from "examples/Charts/BarCharts/ReportsBarChart/configs";
import { client } from "constants/APIFunctions";
import { auth } from "../../../firebase/firebase";
import { RotatingLines } from "react-loader-spinner";
import { CONSTANTS } from "constants/Constants";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function HeatMapCharts({ color, title, description, date, chart }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});
  const [subjectdata, setsubjectdata] = useState();
  const [loading, setLoading] = useState(true);

  const [chartData, setChartData] = useState({
    analysis: [
      [0, 0, 0],
      [0, 1, 0],
      [0, 2, 0],
      [0, 3, 0],
      [0, 4, 0],
      [0, 5, 0],
      [0, 6, 0],
      [0, 7, 0],
      [0, 8, 0],
      [0, 9, 0],
      [0, 10, 0],
      [0, 11, 0],
      [0, 12, 3],
      [0, 13, 0],
      [0, 14, 0],
      [1, 0, 0],
      [1, 1, 3],
      [1, 2, 4],
      [1, 3, 3],
      [1, 4, 2],
      [1, 5, 3],
      [1, 6, 0],
      [1, 7, 14],
      [1, 8, 4],
      [1, 9, 0],
      [1, 10, 1],
      [1, 11, 8],
      [1, 12, 0],
      [1, 13, 1],
      [1, 14, 3],
      [2, 0, 0],
      [2, 1, 0],
      [2, 2, 0],
      [2, 3, 0],
      [2, 4, 0],
      [2, 5, 0],
      [2, 6, 0],
      [2, 7, 0],
      [2, 8, 0],
      [2, 9, 0],
      [2, 10, 0],
      [2, 11, 0],
      [2, 12, 0],
      [2, 13, 0],
      [2, 14, 0],
      [3, 0, 0],
      [3, 1, 0],
      [3, 2, 0],
      [3, 3, 0],
      [3, 4, 0],
      [3, 5, 0],
      [3, 6, 0],
      [3, 7, 0],
      [3, 8, 0],
      [3, 9, 0],
      [3, 10, 0],
      [3, 11, 0],
      [3, 12, 0],
      [3, 13, 0],
      [3, 14, 0],
    ],
    subject_names: [
      "Polity and Governance",
      "Science and Technology",
      "Indian and World Geography",
      "Economic and Social Development",
    ],
    test_names: [
      "{__class__=uuid.UUID, int=10050519251723047691887100379833697336}",
      "{__class__=uuid.UUID, int=109439165483689295573433387135945670203}",
      "{__class__=uuid.UUID, int=109751559114686587772472134882435320042}",
      "{__class__=uuid.UUID, int=117831295569987679151919417974613568014}",
      "{__class__=uuid.UUID, int=165716547190882926584927702508743229010}",
      "{__class__=uuid.UUID, int=207782546210278912905018884659956131575}",
      "{__class__=uuid.UUID, int=227956128138693875612349278281817115216}",
      "{__class__=uuid.UUID, int=232204799330923613829057469086857334610}",
      "{__class__=uuid.UUID, int=23314006238141861946931074400879741759}",
      "{__class__=uuid.UUID, int=246566859562405955146943776217179808474}",
      "{__class__=uuid.UUID, int=246976659165309753787698971131398947323}",
      "{__class__=uuid.UUID, int=254573127488109348446508832564653390729}",
      "{__class__=uuid.UUID, int=298604689359640896986818625029983163940}",
      "{__class__=uuid.UUID, int=302549492983573536764555023298534780777}",
      "{__class__=uuid.UUID, int=310238914831493404201359373733993338738}",
    ],
  });

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("userdata"));
    const heatmap = JSON.parse(localStorage.getItem("heatmap"));
    if (heatmap) {
      setChartData(heatmap);
    }
    client
      .get(`${CONSTANTS.PysparkURL}/api/analysis/analysis_subject_test_score`, {
        params: {
          user_id: auth.currentUser
            ? auth.currentUser.uid
            : items?.user_id
            ? items?.user_id
            : "813b6e33-91ff-49f0-8e08-2f479c95e4e1",
        },
      })
      .then((res) => {
        // setsubjectdata(res.data.analysis);
        console.log("analysistime", res.data.analysis);
        setChartData(res.data.analysis);
        setLoading(false);
        localStorage.setItem("heatmap", JSON.stringify(res.data.analysis));
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  // <MDBox
  //             variant="gradient"
  //             bgColor={color}
  //             borderRadius="lg"
  //             coloredShadow={color}
  //             py={0}
  //             pr={0.5}
  //             mt={0}
  //             height="12.5rem"
  //           >
  //             <Bar
  //               data={subjectdata ? subjectdata.data : data}
  //               options={subjectdata ? subjectdata.options : options}
  //               redraw
  //             />
  //           </MDBox>

  const getShortString = (data) => {
    console.log(data);
    var shortString = [];
    data.map((item) => {
      shortString.push(item?.toString().substring(0, 2));
    });
    return shortString;
  };
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox padding="1rem">
        <div style={{ position: "absolute", top: 8, right: 8 }}>
          <RotatingLines
            visible={loading}
            height="20"
            width="20"
            color="grey"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
          />
        </div>
        {useMemo(
          () => (
            <ReactECharts
              style={{ width: "100%" }}
              option={{
                tooltip: {
                  position: "top",
                },
                grid: {
                  height: "70%",
                  top: "10%",
                },
                xAxis: {
                  type: "category",
                  data: chartData?.subject_names ? getShortString(chartData?.subject_names) : [],
                  splitArea: {
                    show: true,
                  },
                },
                yAxis: {
                  type: "category",
                  data: chartData?.test_names,
                  splitArea: {
                    show: true,
                  },
                },
                visualMap: {
                  min: 0,
                  max: 10,
                  calculable: true,
                  orient: "horizontal",
                  left: "center",
                  bottom: "-3%",
                },
                series: [
                  {
                    name: "Score",
                    type: "heatmap",
                    data: chartData?.analysis,
                    label: {
                      show: true,
                    },
                    emphasis: {
                      itemStyle: {
                        shadowBlur: 10,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                      },
                    },
                  },
                ],
              }}
              notMerge={true}
              lazyUpdate={true}
            />
          ),
          [color, chart, chartData]
        )}
        <MDBox pt={3} pb={1} px={1}>
          <MDTypography variant="h6" textTransform="capitalize">
            {title}
          </MDTypography>
          <MDTypography component="div" variant="button" color="text" fontWeight="light">
            {description}
          </MDTypography>
          <Divider />
          <MDBox display="flex" alignItems="center">
            <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
              <Icon>schedule</Icon>
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="light">
              {date}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ReportsBarChart
HeatMapCharts.defaultProps = {
  color: "info",
  description: "",
};

// Typechecking props for the ReportsBarChart
HeatMapCharts.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string.isRequired,
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default HeatMapCharts;
