/* eslint-disable react/prop-types */
import React from "react";
import Lottie from "react-lottie";
import animationData from "./loadingnew.json"; // Replace with your animation file
import { Typography } from "@mui/material";
import AutomaticTextChanger from "./components/AutomaticTextChanger";
import error from "./components/error.json";
import { Button } from "@mui/material";
import { ChecklistRtl, SmartToy } from "@mui/icons-material";

const FullScreenLottie = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: props.animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#f0f0f0", // Optional background color
        flexDirection: "column",
      }}
    >
      <Lottie options={defaultOptions} height={300} width={300} />
      {props.showButton ? (
        <Button
          variant="contained"
          endIcon={<ChecklistRtl fontSize="24px" style={{ width: "24px", height: "24px" }} />}
          sx={{
            color: "#ffffff",
            alignSelf: "center",
            // height: "50px",
            backgroundColor: "#2f667f",
            fontSize: "14px",
            padding: "5px",
            marginTop: "8px",
          }}
          onClick={() => {
            navigate("/dashboard/CreateQuiz");
          }}
        >
          Take AI MockTest Now
        </Button>
      ) : (
        <div style={{ width: "100%", alignItems: "center" }}>
          <Typography variant="h4" style={{ textAlign: "center" }}>
            {props.content}
          </Typography>
          <AutomaticTextChanger
            texts={[
              "Understand the exam syllabus thoroughly.",
              "Create a realistic study schedule and stick to it.",
              "Read newspapers and magazines for current affairs.",
              "Use standard books and NCERTs for subjects.",
              "Practice previous years' papers and take mock tests.",
              "Focus on improving writing skills for mains.",
              "Dedicate time daily for current affairs updates.",
              "Revise regularly and make concise notes.",
              "Maintain a healthy lifestyle and stay positive.",
              "Seek guidance from coaching institutes or mentors.",
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default FullScreenLottie;
