/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import QuizOptions from "layouts/quiz/QuizOptions";
import { CancelTwoTone } from "@mui/icons-material";
import { client } from "constants/APIFunctions";
import { auth } from "firebase/firebase";
import { useCookies } from "react-cookie";
import { CircularProgress } from "@mui/material";
import StatementFormatter from "layouts/quiz/components/StatementFormatter";
import ReactGA from "react-ga4";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import QuizOptionsPrelims from "./QuizOptionsPrelims";
import { Helmet } from "react-helmet";
ReactGA.initialize("G-EDEXTHSM4C");

const PrelimsQuizQuestionPage = (props) => {
  const questionRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SAMAI-UPSC Prelims Answer Key With Questions-CSAT and GS1 Paper |GS2 Paper </title>
        <meta
          name="description"
          content=" UPSC Prelims Answer Key for CSAT and GS Paper 1 to get accurate solutions. Evaluate your performance and estimate your scores with UPSC answer key"
        />
        <meta
          name="keyword"
          content="UPSC Preliminary Test Series Answer Key,UPSC Prelims Key Answeres 2024,UPSC Prelims Key Answeres 2023,UPSC GS1 Prelims Key Answeres 2024,UPSC GS2 Prelims Key Answeres 2024,UPSC GS2 Prelims Key Answeres 2024,UPSC GS1 Prelims Key Answeres 2024, KPSC Preliminary Test Series Answer Key, KPSC Prelims Answer Key 2023,KPSC Prelims Answer Key 2024,UPSC Prelims Answer Key,KPSC Prelims Answer Key,UPSC Answer Key,UPSC Answe Key 2023,UPSC Answer 2024,csat answer key,upsc question paper with answer key,"
        />
        <meta
          property="og:description"
          content="SAMAI-Free UPSC & KPSC with Best AI Mock Tests - with Scontinent"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://samai.scontiapp.com/prelims" />
        <meta property="og:site_name" content="SAMAI-AI Mock Test " />
        <meta property="og:locale" content="en_In" />
        <link rel="canonical" href="https://samai.scontiapp.com/prelims" />
      </Helmet>

      <div
        className="question-container"
        style={{ marginTop: 8, cursor: "pointer" }}
        onClick={() => {
          navigate("/prelims/upsc-prelims-answer-key/upsc-prelims-question", {
            state: { qdata: props.data },
          });

          ReactGA.event({
            category: "Prelims Questions and Key Answer Page ",
            action: "User is going to check with the Prelims Questions and Key Answer Page",
          });
        }}
      >
        <div className="question-text-container">
          <h4>Q{props.index + 1}. </h4>
          <div className="question-text" ref={questionRef}>
            <StatementFormatter text={props.data.question_text} />
          </div>
        </div>
        <div className="options-container">
          <QuizOptionsPrelims
            data={[
              props.data.option_a,
              props.data.option_b,
              props.data.option_c,
              props.data.option_d,
            ]}
            questiondata={props.data}
            analysis={true}
          />
        </div>

        <div className="explaination">
          <h5
            style={{
              fontSize: "14px",
              marginTop: "2px",
              fontFamily: "BentonSans-Bold, Arial, Noto Sans, sans-serif",
            }}
          >
            Explanation:
          </h5>
          <div className="explaintext">{props.data.explanation}</div>
        </div>

        <div className="separator" />
      </div>
    </>
  );
};

export default PrelimsQuizQuestionPage;
