/* eslint-disable react/prop-types */
import { useEffect, useMemo, useState } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";
import ReactECharts from "echarts-for-react";
import Lottie from "react-lottie";
import analysis from "../../LineCharts/MultilineChart/assets/analysis.json";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Modal from "react-modal";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// ReportsBarChart configurations
import configs from "examples/Charts/BarCharts/ReportsBarChart/configs";
import { client } from "constants/APIFunctions";
import { auth } from "../../../../firebase/firebase";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";
import { CONSTANTS } from "constants/Constants";
import { Button, Typography, IconButton } from "@mui/material";
import { ArrowForward, ArrowForwardIos, Close } from "@mui/icons-material";
import performancereport from "../../LineCharts/MultilineChart/assets/performancereport.json";
import generating from "../../LineCharts/MultilineChart/assets/generating.json";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: analysis,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const defaultOption1 = {
  loop: true,
  autoplay: true,
  animationData: performancereport,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const defaultOption2 = {
  loop: true,
  autoplay: true,
  animationData: generating,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
  content: {
    width: "70%",
    maxWidth: "600px",
    margin: "auto",
    border: "none",
    borderRadius: "8px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "auto", // Ensure scrollbar is available if content overflows
  },
  "::-webkit-scrollbar": {
    width: "4px", // Reduce the width of the scrollbar
    height: "4px", // For horizontal scrollbars, if any
  },
  "::-webkit-scrollbar-thumb": {
    background: "gray", // Color of the scrollbar thumb
    borderRadius: "2px", // Rounded corners for the scrollbar thumb
  },
  closeButton: {
    cursor: "pointer",
  },
};
const customStylesPhone = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
  content: {
    width: "80%",
    maxWidth: "400px",
    margin: "auto",
    border: "none",
    borderRadius: "8px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "auto", // Ensure scrollbar is available if content overflows
  },
  "::-webkit-scrollbar": {
    width: "2px", // Reduce the width more for smaller devices
    height: "2px", // For horizontal scrollbars, if any
  },
  "::-webkit-scrollbar-thumb": {
    background: "gray", // Color of the scrollbar thumb
    borderRadius: "1px", // Rounded corners for the scrollbar thumb on smaller devices
  },
};

if (window.innerWidth > 768) {
  customStyles.content.width = "80%"; // Adjust the width as needed
  customStyles.content.maxWidth = "600px"; // Adjust the max-width as needed
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const barColors = [
  (opacity = 1) => `#C60DE5`,
  (opacity = 1) => `#5C3B66`,
  (opacity = 1) => `#1F870A`,
  (opacity = 1) => `#666633`,
  (opacity = 1) => `#EC643E`,
  (opacity = 1) => `#9E210B`,
  (opacity = 1) => `#384579`,
  (opacity = 1) => `#EFD076`,
  (opacity = 1) => `#235183`,
  (opacity = 1) => `#336633`,
  (opacity = 1) => `#D40F19`,
  (opacity = 1) => `#333366`,
  (opacity = 1) => `#e6f619`,
  (opacity = 1) => `#333333`,
  (opacity = 1) => `#666600`,
  (opacity = 1) => `#663300`,
  (opacity = 1) => `#333333`,
  (opacity = 1) => `#660033`,
  (opacity = 1) => `#333366`,
];
const barColorslabel = [
  "#C60DE5",
  "#5C3B66",
  "#1F870A",
  "#666633",
  "#EC643E",
  "#9E210B",
  "#384579",
  "#EFD076",
  "#235183",
  "#336633",
  "#D40F19",
  "#333366",
  "#e6f619",
  "#333333",
  "#666600",
  "#336666",
  "#663300",
  "#333333",
  "#660033",
  "#333366",
];
function ReportsBarChart({ color, title, description, date, chart, subject, exam_name }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});
  const [subjectdata, setsubjectdata] = useState();
  const [chartData, setChartData] = useState();
  const [updatedChartData, setUpdatedChartData] = useState();
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [generatingDetailed, setgeneratingdetailed] = useState(false);
  // const [isDetailed, setIsDetailed] = useState(false);
  const [generatedExplanation, setgeneratedExplanation] = useState();
  const [fulllabel, setfulllabel] = useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("userdata"));
    const barchart = JSON.parse(localStorage.getItem("barChart"));
    if (barchart) {
      setChartData(barchart);
    }
    axios
      .get(`${CONSTANTS.PysparkURL}/api/analysis/analysis_subject`, {
        params: {
          user_id: auth.currentUser
            ? auth.currentUser.uid
            : items?.user_id
            ? items?.user_id
            : "813b6e33-91ff-49f0-8e08-2f479c95e4e1",
          exam_id:
            items && items?.interested_exams != "N/A"
              ? JSON.parse(items?.interested_exams)?.exam_id
                ? JSON.parse(items?.interested_exams)?.exam_id
                : "813b6e33-91ff-49f0-8e08-2f479c65e4e1"
              : "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
        },
      })
      .then((res) => {
        // setsubjectdata(res.data.analysis);
        console.log("analysistime", res.data.analysis);
        setLoading(false);
        var labelFull = [];
        var labels = [];
        var score = [];
        var showlabel = [];
        var updatedData = [];
        var color = [];
        res.data.analysis?.map((item, index) => {
          updatedData.push({
            value: item.test_count,
            itemStyle: {
              color: barColorslabel[index],
            },
          });
          labelFull.push(item.subject_name);
          labels.push(
            item.subject_name == "Environment"
              ? item.subject_name?.substring(0, 2)
              : item.subject_name?.substring(0, 1)
          );
          score.push(item.test_count);
          color.push(barColors[index] ? barColors[index] : (opacity = 1) => `#87CEEB`);
          showlabel.push({
            key:
              item.subject_name == "Environment"
                ? item.subject_name?.substring(0, 2)
                : item.subject_name?.includes("General")
                ? item.subject_name?.substring(0, 3)
                : item.subject_name?.substring(0, 1),
            value: item.subject_name,
            barColor: barColorslabel[index] ? barColorslabel[index] : "#87CEEB",
          });
        });
        console.log("analysistimedata", {
          labels: labels,
          datasets: { label: "Analysis", data: score },
        });
        const missingItems = subject?.filter(
          (item1) => !labelFull.some((item2) => item2 === item1.subject_name)
        );
        const updatedFullLabelList = [...labelFull, ...missingItems?.map((item) => item)];
        const updatedLabelList = [
          ...labels,
          ...missingItems?.map((item) =>
            item.subject_name == "Environment"
              ? item.subject_name?.substring(0, 2)
              : item.subject_name?.substring(0, 1)
          ),
        ];
        const updatedScoreList = [
          ...updatedData,
          ...missingItems?.map((item, index) => ({
            value: 0,
            itemStyle: {
              color: barColorslabel[(updatedData?.length ? updatedData?.length : 0) + index],
            },
          })),
        ];
        const updatedshowlabel = [
          ...showlabel,
          ...missingItems.map((item, index) => ({
            key:
              item.subject_name == "Environment"
                ? item.subject_name?.substring(0, 2)
                : item.subject_name?.includes("General")
                ? item.subject_name?.substring(0, 3)
                : item.subject_name?.substring(0, 1),
            value: item.subject_name,
            barColor: barColorslabel[index + showlabel.length]
              ? barColorslabel[index + showlabel.length]
              : "#87CEEB",
          })),
        ];
        console.log(updatedshowlabel);
        setfulllabel(updatedshowlabel);
        setChartData({
          labels: updatedLabelList,
          data: updatedScoreList,
          labelFull: updatedFullLabelList,
          // datasets: { label: "Analysis", data: score },
        });
        var datasets = { label: "Analysis", data: score };
        var { data, options } = configs(labels || [], datasets || {});
        setsubjectdata({
          data: data,
          options: options,
        });
        localStorage.setItem(
          "barChart",
          JSON.stringify({
            labels: updatedLabelList,
            data: updatedScoreList,
            labelFull: updatedFullLabelList,
            // datasets: { label: "Analysis", data: score },
          })
        );
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  const generatePerformanceReport = () => {
    setgeneratingdetailed(true);
    setModalOpen(true);
    axios
      .post(`${CONSTANTS.APIURL}/api/graph/explanation`, {
        input: {
          data: JSON.stringify(chartData?.data),
          exam: exam_name,
          type: "barchart",
          prompt:
            "The data consists of subject_name and test_count where test_count denotes the number of tests attempted for a particular subject",
        },
      })
      .then((response) => {
        setgeneratedExplanation(response?.data?.graph_explanation);
        setgeneratingdetailed(false);
      })
      .catch((err) => {
        console.log(err);
        // showerrormessages("Something went wrong...");
        setgeneratingdetailed(false);
        setModalOpen(false);
      });
  };

  return (
    <Card sx={{ height: "100%", borderColor: "gold", borderWidth: 1 }} elevation={10}>
      <MDBox padding="1rem">
        {/* <div style={{ position: "absolute", top: 8, right: 8 }}> */}
        <Typography variant="h6" textTransform="capitalize" textAlign="center">
          {title}
        </Typography>
        <RotatingLines
          visible={loading}
          height="20"
          width="20"
          color="grey"
          strokeWidth="5"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
        />
        {/* </div> */}
        {useMemo(
          () => (
            <ReactECharts
              style={{ width: "100%", borderRadius: 10 }}
              option={{
                tooltip: {
                  trigger: "axis",
                  axisPointer: {
                    type: "shadow",
                  },
                },
                grid: {
                  left: "3%",
                  right: "4%",
                  bottom: "3%",
                  containLabel: true,
                },
                xAxis: [
                  {
                    type: "category",
                    data: chartData?.labels
                      ? chartData?.labels
                      : ["Env", "Hi", "Po", "Sc", "In", "Cu", "Ec"],
                    axisTick: {
                      alignWithLabel: true,
                    },
                  },
                ],
                yAxis: [
                  {
                    type: "value",
                  },
                ],
                series: [
                  {
                    data: chartData?.data ? chartData?.data : [],
                    type: "bar",
                  },
                ],
              }}
              notMerge={true}
              lazyUpdate={true}
            />
          ),
          [color, chart, chartData]
        )}
        <MDBox pt={3} pb={1} px={1}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              height: 100,
              maxHeight: 100,
              overflowY: "scroll",
            }}
          >
            {fulllabel?.map((item, index) => {
              return (
                <Typography
                  key={index}
                  variant="body2"
                  style={{
                    color: item?.barColor,
                    margin: 5,
                    borderWidth: 1,
                    borderColor: "black",
                    borderRadius: 8,
                    fontWeight: "500",
                  }}
                >
                  {item.key}-{item.value}
                </Typography>
              );
            })}
          </div>
        </MDBox>
        <Button
          variant="contained"
          size="small"
          fullWidth
          onClick={() => {
            setModalOpen(!modalOpen);
            generatePerformanceReport();
          }}
          style={{ color: "white" }}
          endIcon={<ArrowForwardIos />}
        >
          Get your performance Insights
        </Button>
        <MDBox pt={3} pb={1} px={1}>
          <MDTypography variant="h6" textTransform="capitalize">
            {title}
          </MDTypography>
          <MDTypography component="div" variant="button" color="text" fontWeight="light">
            {description}
          </MDTypography>
          <Divider />
          <MDBox display="flex" alignItems="center">
            <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
              <Icon>schedule</Icon>
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="light">
              {date}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Modal
        isOpen={modalOpen}
        onClose={setModalOpen}
        style={window.innerWidth > 768 ? customStyles : customStylesPhone}
        ariaHideApp={false}
        position="fixed"
      >
        <div>
          {generatingDetailed ? (
            <div
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                flex: 1,
                justifyContent: "center",
              }}
            >
              <Lottie
                options={defaultOption2}
                height={100}
                width={100}
                style={{ alignSelf: "start" }}
              />
              <Typography variant="h6" textAlign="center">
                Generating Your Detailed Performance Insights
              </Typography>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h4">Performance Insights</Typography>
                <IconButton
                  onClick={() => {
                    setModalOpen(false);
                  }}
                >
                  <Close />
                </IconButton>
              </div>
              <Lottie
                options={defaultOption1}
                height={100}
                width={100}
                style={{ alignSelf: "start" }}
              />
              <Typography variant="h6" style={{ textAlign: "justify" }}>
                {generatedExplanation?.replace(/\*/g, "")}
              </Typography>
              <div style={{ height: 30 }} />
            </div>
          )}
        </div>
      </Modal>
    </Card>
  );
}

// Setting default values for the props of ReportsBarChart
ReportsBarChart.defaultProps = {
  color: "info",
  description: "",
};

// Typechecking props for the ReportsBarChart
ReportsBarChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string.isRequired,
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default ReportsBarChart;
