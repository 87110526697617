/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import "./Answer.css";
import { Button, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { CONSTANTS } from "constants/Constants";
import axios from "axios";
import animationData from "../layouts/quiz/loadingnew.json";
import { Helmet } from "react-helmet";
import MDBox from "components/MDBox";
import FullScreenLottie from "layouts/quiz/FullScreenLottie";

const UPSCAnswerKey2024 = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const data = location.state && location.state.year;
  const paperType = location.state && location.state.isPaperType;
  const isLive = location.state && location.state.isLive;
  const type = location.state && location.state.type;
  const [keyAnswerData, setKeyAnswerData] = useState([]);
  const [animation, setAnimation] = useState(animationData);
  const [animationText, setAnimationText] = useState(
    "AI is generating prelims key answers for UPSC and KPSC"
  );
  const [setA, setSetA] = useState();
  const [setB, setSetB] = useState();
  const [setC, setSetC] = useState();
  const [setD, setSetD] = useState();

  useEffect(() => {
    console.log("yearwise", data);
    console.log(location.state);
    axios
      .get(`${CONSTANTS.APIURL}/api/question/answer-key-2024`, {
        params: { year: data ? data : "2024", paper_type: paperType ? paperType : "GS1" },
      })
      .then((response) => {
        setSetA(response.data.SET_A);
        setSetB(response.data.SET_B);
        setSetC(response.data.SET_C);
        setSetD(response.data.SET_D);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setAnimationText("An error occurred");
        setAnimation(err);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SAMAI-UPSC Prelims Question Paper 2024 CSAT Paper</title>
        <meta
          name="description"
          content=" UPSC Prelims 2024 Question Paper for CSAT Paper is available for download now. Candidates can download the free CSAT and GS-1 2024 paper here.
"
        />
        <meta
          name="robots"
          content="UPSC Prelims 2024 Question Paper, IAS Prelims Question Paper 2024, UPSC CSE Prelims 2024 Question Paper, Download UPSC Prelims 2024 Paper, UPSC Prelims 2024 CSAT Paper, UPSC Prelims 2024 Answer Key, UPSC Prelims 2024 PDF Download, UPSC CSAT 2024 Question Paper, Free UPSC Prelims Question Paper 2024, UPSC Prelims 2024 Paper 1 Download, UPSC Prelims 2024 Paper 2 PDF, UPSC Prelims 2024 Exam Question Paper, UPSC 2024 Prelims Question Paper Insights IAS, UPSC Prelims 2024 Question Paper Solutions, UPSC 2024 Answer Key PDF, UPSC Prelims 2024 CSAT Answer Key, UPSC Prelims 2024 Exam Analysis, UPSC Prelims 2024 Question Paper Download Link, UPSC Prelims 2024 Question Paper InsightsIAS, UPSC Prelims CSAT Paper PDF, UPSC Prelims 2024 Question Paper PDF Free Download, UPSC Prelims 2024 Exam Question Paper Solutions, UPSC IAS Prelims 2024 Question Paper, UPSC Prelims 2024 CSAT, Download UPSC Prelims 2024 CSAT Paper, UPSC Prelims 2024 Exam Question Papers PDF, UPSC Prelims 2024 Exam Solutions, UPSC Prelims 2024 Paper Download InsightsIAS, UPSC Prelims 2024 Question Papers with Answers, CSAT and GS-1 2024"
        />
        <meta
          name="keyword"
          content="UPSC Prelims 2024 Question Paper, IAS Prelims Question Paper 2024, UPSC CSE Prelims 2024 Question Paper, Download UPSC Prelims 2024 Paper, UPSC Prelims 2024 CSAT Paper, UPSC Prelims 2024 Answer Key, UPSC Prelims 2024 PDF Download, UPSC CSAT 2024 Question Paper, Free UPSC Prelims Question Paper 2024, UPSC Prelims 2024 Paper 1 Download, UPSC Prelims 2024 Paper 2 PDF, UPSC Prelims 2024 Exam Question Paper, UPSC 2024 Prelims Question Paper Insights IAS, UPSC Prelims 2024 Question Paper Solutions, UPSC 2024 Answer Key PDF, UPSC Prelims 2024 CSAT Answer Key, UPSC Prelims 2024 Exam Analysis, UPSC Prelims 2024 Question Paper Download Link, UPSC Prelims 2024 Question Paper InsightsIAS, UPSC Prelims CSAT Paper PDF, UPSC Prelims 2024 Question Paper PDF Free Download, UPSC Prelims 2024 Exam Question Paper Solutions, UPSC IAS Prelims 2024 Question Paper, UPSC Prelims 2024 CSAT, Download UPSC Prelims 2024 CSAT Paper, UPSC Prelims 2024 Exam Question Papers PDF, UPSC Prelims 2024 Exam Solutions, UPSC Prelims 2024 Paper Download InsightsIAS, UPSC Prelims 2024 Question Papers with Answers, CSAT and GS-1 2024"
        />
        <meta
          property="og:description"
          content="SAMAI-Free UPSC & KPSC with Best AI Mock Tests - with Scontinent"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://samai.scontiapp.com/prelims" />
        <meta property="og:site_name" content="SAMAI-AI Mock Test " />
        <meta property="og:locale" content="en_In" />
        <link rel="canonical" href="https://samai.scontiapp.com/prelims" />
      </Helmet>
      <DashboardLayout>
        <Typography variant="h4" textAlign="center">
          SAMAI-UPSC Prelims Answer Key-2024-25
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: 5,
            margin: 5,
            flexWrap: "wrap",
            gap: 25,
          }}
        >
          <Link
            to="https://firebasestorage.googleapis.com/v0/b/sconti-e7f3a.appspot.com/o/FINAL.pdf?alt=media&token=c11633f0-740f-40f5-bba3-2b16a070797b"
            download
            target="_self"
          >
            <Button
              variant="contained"
              size="large"
              style={{ color: "#fff", backgroundColor: "#00a3c9" }}
            >
              Download Answer Key for UPSC-2024-GS1
            </Button>
          </Link>
          <Link
            to="https://firebasestorage.googleapis.com/v0/b/sconti-e7f3a.appspot.com/o/gs2AnswerKey.pdf?alt=media&token=67dfb720-ad75-46b5-a39d-a72e22e31141"
            download
            target="_self"
          >
            <Button
              variant="contained"
              size="large"
              style={{ color: "#fff", backgroundColor: "#00a3c9" }}
            >
              Download Answer Key for UPSC-2024-GS2
            </Button>
                     
          </Link>
        </div>
        {loading ? (
          <MDBox mt={8}>
            <FullScreenLottie content={animationText} animation={animation} />
          </MDBox>
        ) : paperType == "GS1" ? (
          <>
            <table>
              <thead>
                <tr>
                  <th colSpan="4">SET - A</th>
                </tr>
                <td>
                  <th>1-25</th>

                  {setA?.slice(0, 25)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>26-50</th>
                  {setA?.slice(25, 50)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>51-75</th>
                  {setA?.slice(50, 75)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>76-100</th>
                  {setA?.slice(75, 100)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
              </thead>
            </table>
            <table>
              <thead>
                <tr>
                  <th colSpan="4">SET - B</th>
                </tr>
                <td>
                  <th>1-25</th>
                  {setB?.slice(0, 25)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>26-50</th>
                  {setB?.slice(25, 50)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>51-75</th>
                  {setB?.slice(50, 75)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>76-100</th>
                  {setB?.slice(75, 100)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
              </thead>
            </table>
            <table>
              <thead>
                <tr>
                  <th colSpan="4">SET - C</th>
                </tr>
                <td>
                  <th>1-25</th>
                  {setC?.slice(0, 25)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>26-50</th>
                  {setC?.slice(25, 50)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>51-75</th>
                  {setC?.slice(50, 75)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>76-100</th>
                  {setC?.slice(75, 100)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
              </thead>
            </table>
            <table>
              <thead>
                <tr>
                  <th colSpan="4">SET - D</th>
                </tr>
                <td>
                  <th>1-25</th>
                  {setD?.slice(0, 25)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>26-50</th>
                  {setD?.slice(25, 50)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>51-75</th>
                  {setD?.slice(50, 75)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>76-100</th>
                  {setD?.slice(75, 100)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
              </thead>
            </table>
          </>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th colSpan="4">SET - A</th>
                </tr>
                <td>
                  <th>1-20</th>

                  {setA?.slice(0, 20)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>21-40</th>
                  {setA?.slice(20, 40)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>41-60</th>
                  {setA?.slice(40, 60)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>61-80</th>
                  {setA?.slice(60, 80)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
              </thead>
            </table>
            <table>
              <thead>
                <tr>
                  <th colSpan="4">SET - B</th>
                </tr>
                <td>
                  <th>1-20</th>
                  {setB?.slice(0, 20)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>21-40</th>
                  {setB?.slice(20, 40)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>41-60</th>
                  {setB?.slice(40, 60)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>61-80</th>
                  {setB?.slice(60, 80)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
              </thead>
            </table>
            <table>
              <thead>
                <tr>
                  <th colSpan="4">SET - C</th>
                </tr>
                <td>
                  <th>1-20</th>
                  {setC?.slice(0, 20)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>21-40</th>
                  {setC?.slice(20, 40)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>41-60</th>
                  {setC?.slice(40, 60)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>61-80</th>
                  {setC?.slice(60, 80)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
              </thead>
            </table>
            <table>
              <thead>
                <tr>
                  <th colSpan="4">SET - D</th>
                </tr>
                <td>
                  <th>1-20</th>
                  {setD?.slice(0, 20)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>21-40</th>
                  {setD?.slice(20, 40)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>41-65</th>
                  {setD?.slice(40, 60)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
                <td>
                  <th>61-80</th>
                  {setD?.slice(60, 80)?.map((item, index) => {
                    return (
                      <tr>
                        {item.question_number}. ({item.correct_answer})
                      </tr>
                    );
                  })}
                </td>
              </thead>
            </table>
          </>
        )}
      </DashboardLayout>
    </>
  );
};

export default UPSCAnswerKey2024;
