/* eslint-disable react/prop-types */
import React from "react";
import Modal from "react-modal";
import Lottie from "react-lottie";
import animationData from "./timeout.json"; // Replace 'animation.json' with your Lottie animation file

// Modal styles
const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
  content: {
    width: "70%",
    maxWidth: "400px",
    margin: "auto",
    border: "none",
    borderRadius: "8px",
    padding: "30px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    display: "flex", // Added display flex
    flexDirection: "column", // Added flex direction column
    alignItems: "center", // Align items center
  },
};

const QuizTimeoutModal = ({ isOpen, onClose }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles} ariaHideApp={false}>
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <Lottie options={defaultOptions} height={150} width={150} />
      </div>
      <h2 style={{ marginBottom: "10px" }}>Quiz Timeout</h2>
      <p style={{ fontSize: "16px", marginBottom: "20px" }}>
        Oops! Your quiz session has timed out. Please try again later.
      </p>
      <button
        onClick={onClose}
        style={{
          padding: "10px 20px",
          backgroundColor: "#2f667f",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        View Results
      </button>
    </Modal>
  );
};

export default QuizTimeoutModal;
