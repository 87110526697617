// Filename - App.js

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CreateQuiz from "./CreateQuiz";
import TakeQuiz from "./TakeQuizPage";
import ShowQuizResults from "./ShowQuizResults";
import Dashboard from "layouts/dashboard";
import CreateQuizNew from "./CreateQuizNew";

function QuizContainer() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="/CreateQuiz" element={<CreateQuizNew />} />
        <Route exact path="/TakeQuiz" element={<TakeQuiz />} />
        <Route exact path="/ShowResults" element={<ShowQuizResults />} />
        {/* <Route exact path="/CreateGame" element={<CreateGame />} /> */}
      </Routes>
    </>
  );
}

export default QuizContainer;
