/* eslint-disable react/prop-types */
import * as React from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import { client } from "constants/APIFunctions";
import { exams } from "layouts/quiz/data/Precacheddata";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function ChipsArray(props) {
  const [chipData, setChipData] = React.useState([]);
  const [selectedChip, setSelectedChip] = React.useState([]);
  const checkIfExists = (value) => {
    // return selectedChip.some((item) => item.label === value);
    if (props?.selectedExamsData) {
      return props?.selectedExamsData?.exam_name === value;
    } else {
      return selectedChip.some((item) => item.label === value);
    }
  };
  const getExams = () => {
    client
      .get("/api/exam/get_exams")
      .then((response) => {
        console.log(response);
        var data = [];
        response.data.exams.map((item, index) => {
          var objExams = {
            key: item.exam_id,
            label: item.exam_name,
          };
          data.push(objExams);
        });
        setChipData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    var data = [];
    exams.map((item, index) => {
      var objExams = {
        key: item.exam_id,
        label: item.exam_name,
      };
      data.push(objExams);
    });
    setChipData(data);
    getExams();
  }, []);
  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "left",
        flexWrap: "wrap",
        listStyle: "none",
        p: 0.5,
        m: 0,
        maxHeight: 100,
        backgroundColor: "#fff",
        borderWidth: 1,
        borderColor: "#333",
      }}
      component="ul"
    >
      {chipData.map((data) => {
        let icon;

        return (
          <ListItem key={data.key}>
            <Chip
              label={data.label}
              onClick={() => {
                if (checkIfExists(data.label)) {
                  setSelectedChip([]);
                  props.setExams({
                    ...props.exams,
                    interested_exams: JSON.stringify({ exam_id: "", exam_name: "" }),
                  });
                } else {
                  setSelectedChip([data]);
                  props.setExams({
                    ...props.exams,
                    interested_exams: JSON.stringify({ exam_id: data.key, exam_name: data.label }),
                  });
                  console.log(selectedChip);
                }
              }}
              style={{
                backgroundColor: checkIfExists(data.label) ? "#2f667f" : "#f1eff0",
                color: checkIfExists(data.label) ? "#fff" : "#000",
                borderRadius: "8px",
              }}
            />
          </ListItem>
        );
      })}
    </Paper>
  );
}
