export const subject_list = [
  {
    subject_id: "598fad9e-4da1-41a0-877e-f5b54a54c3b4",
    created_date: "2024-04-19 18:07:56.276000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    subject_name: "History",
  },
  {
    subject_id: "1da3ceda-8d97-4e9f-89db-62c8ab9438bd",
    created_date: "2024-04-19 18:08:49.089000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    subject_name: "Current Affairs",
  },
  {
    subject_id: "af428d35-7ad1-4c54-b0c6-27f0873682ae",
    created_date: "2024-04-19 18:08:12.465000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    subject_name: "Economy",
  },
  {
    subject_id: "a31168ae-d61d-40df-b52e-26804b527aba",
    created_date: "2024-04-19 18:07:22.125000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    subject_name: "Polity",
  },
  {
    subject_id: "9f0f3090-d233-4624-8928-ae8a1d9aec28",
    created_date: "2024-04-19 18:08:31.560000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    subject_name: "Environment",
  },
  {
    subject_id: "ab4a8cf7-2a6f-4a7f-8285-bfdf4c95c3b2",
    created_date: "2024-04-19 18:07:44.053000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    subject_name: "Geography",
  },
];

export const topic_list = [
  {
    topic_id: "46a13b61-7dcd-4f4d-840e-c53d4d3d234c",
    created_date: "2024-04-19 18:14:47.191000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    subject_id: "598fad9e-4da1-41a0-877e-f5b54a54c3b4",
    topic_name: "Medieval",
  },
  {
    topic_id: "c249f3e1-2b67-49c3-86e3-5365475e2546",
    created_date: "2024-04-19 18:17:13.082000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    subject_id: "9f0f3090-d233-4624-8928-ae8a1d9aec28",
    topic_name: "Climate change",
  },
  {
    topic_id: "ce4ad0c2-80a1-45d3-a7af-38949f0ca193",
    created_date: "2024-04-19 18:11:40.595000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    subject_id: "a31168ae-d61d-40df-b52e-26804b527aba",
    topic_name: "Constitution",
  },
  {
    topic_id: "c3e30b97-36f1-4313-a5df-e82ffa7c3271",
    created_date: "2024-04-19 18:15:37.796000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    subject_id: "af428d35-7ad1-4c54-b0c6-27f0873682ae",
    topic_name: "Indian Economy",
  },
  {
    topic_id: "8637fe35-dd9f-45d3-9a52-5c305ed08a99",
    created_date: "2024-04-19 18:18:11.146000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    subject_id: "1da3ceda-8d97-4e9f-89db-62c8ab9438bd",
    topic_name: "International",
  },
  {
    topic_id: "52b749c7-c17f-420c-971b-9cfed5c465ab",
    created_date: "2024-04-19 18:13:36.678000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    subject_id: "ab4a8cf7-2a6f-4a7f-8285-bfdf4c95c3b2",
    topic_name: "Indian Geography",
  },
  {
    topic_id: "c4ec61bb-b53d-4bc6-99d0-7005608d220f",
    created_date: "2024-04-19 18:13:22.681000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    subject_id: "ab4a8cf7-2a6f-4a7f-8285-bfdf4c95c3b2",
    topic_name: "Physical Geography",
  },
  {
    topic_id: "06a1b21e-2239-44fd-89cb-70085457983d",
    created_date: "2024-04-19 18:15:52.617000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    subject_id: "af428d35-7ad1-4c54-b0c6-27f0873682ae",
    topic_name: "Money and Banking",
  },
  {
    topic_id: "6b67b737-4294-4738-88a9-00254d0baa04",
    created_date: "2024-04-19 18:14:17.561000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    subject_id: "598fad9e-4da1-41a0-877e-f5b54a54c3b4",
    topic_name: "Ancient",
  },
  {
    topic_id: "715fc0fe-4246-417e-a81d-b1fb236844c4",
    created_date: "2024-04-19 18:12:12.301000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    subject_id: "a31168ae-d61d-40df-b52e-26804b527aba",
    topic_name: "Executive",
  },
  {
    topic_id: "5bcd8d99-e644-4de5-9495-f8d3ffd1be5c",
    created_date: "2024-04-19 18:17:32.717000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    subject_id: "9f0f3090-d233-4624-8928-ae8a1d9aec28",
    topic_name: "Environment Pollution",
  },
  {
    topic_id: "0b9999ad-a579-4381-bce9-219ae11102c4",
    created_date: "2024-04-19 18:16:55.999000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    subject_id: "9f0f3090-d233-4624-8928-ae8a1d9aec28",
    topic_name: "Ecology",
  },
  {
    topic_id: "3d06e7c9-51ab-4c60-9ee2-3670d11b09b8",
    created_date: "2024-04-19 18:11:57.122000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    subject_id: "a31168ae-d61d-40df-b52e-26804b527aba",
    topic_name: "Legislature",
  },
  {
    topic_id: "ca68abfb-7ed2-4bb1-abdb-021d34b9c826",
    created_date: "2024-04-19 18:17:59.550000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    subject_id: "1da3ceda-8d97-4e9f-89db-62c8ab9438bd",
    topic_name: "National",
  },
  {
    topic_id: "195003e0-f451-4a70-9b55-93f54fbf2330",
    created_date: "2024-04-19 18:14:53.260000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    subject_id: "598fad9e-4da1-41a0-877e-f5b54a54c3b4",
    topic_name: "Modern",
  },
];

export const sub_topic_list = [
  {
    sub_topic_id: "44aba869-d59e-4529-a4e9-acadee63ebc1",
    created_date: "2024-04-19 18:39:09.073000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Regional Provinces",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "598fad9e-4da1-41a0-877e-f5b54a54c3b4",
    topic_id: "195003e0-f451-4a70-9b55-93f54fbf2330",
  },
  {
    sub_topic_id: "13aa6ace-4e08-460b-a777-7c4bb8d94ee7",
    created_date: "2024-04-19 18:46:05.673000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Basics of Economy",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "af428d35-7ad1-4c54-b0c6-27f0873682ae",
    topic_id: "c3e30b97-36f1-4313-a5df-e82ffa7c3271",
  },
  {
    sub_topic_id: "a58501fb-27e5-4e72-a487-7dba81e4a9fd",
    created_date: "2024-04-19 18:32:55.122000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Agriculture",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "ab4a8cf7-2a6f-4a7f-8285-bfdf4c95c3b2",
    topic_id: "52b749c7-c17f-420c-971b-9cfed5c465ab",
  },
  {
    sub_topic_id: "f379543b-1e7b-4c75-b5a1-6d1f6b6ab93e",
    created_date: "2024-04-20 18:47:16.303000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Indian Land Climate",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "ab4a8cf7-2a6f-4a7f-8285-bfdf4c95c3b2",
    topic_id: "52b749c7-c17f-420c-971b-9cfed5c465ab",
  },
  {
    sub_topic_id: "384ca51b-76be-41bd-9659-b37e959d7b3b",
    created_date: "2024-04-19 18:46:05.745000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Sectors of Economy",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "af428d35-7ad1-4c54-b0c6-27f0873682ae",
    topic_id: "c3e30b97-36f1-4313-a5df-e82ffa7c3271",
  },
  {
    sub_topic_id: "ae4d49bf-d98d-4078-9b17-120f2343a238",
    created_date: "2024-04-19 18:39:09.013000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "British Colonial Rule",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "598fad9e-4da1-41a0-877e-f5b54a54c3b4",
    topic_id: "195003e0-f451-4a70-9b55-93f54fbf2330",
  },
  {
    sub_topic_id: "76944d05-e26c-4a6f-8adb-0bd36a7753d6",
    created_date: "2024-04-19 18:55:03.302000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Govt. Of India Measures and Laws",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "9f0f3090-d233-4624-8928-ae8a1d9aec28",
    topic_id: "5bcd8d99-e644-4de5-9495-f8d3ffd1be5c",
  },
  {
    sub_topic_id: "d915c44b-971e-49c5-93ce-8ca436210432",
    created_date: "2024-04-19 18:31:35.120000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Climate and Vegitation",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "ab4a8cf7-2a6f-4a7f-8285-bfdf4c95c3b2",
    topic_id: "c4ec61bb-b53d-4bc6-99d0-7005608d220f",
  },
  {
    sub_topic_id: "fbe71113-b3b1-4c94-934b-dfb4a58df978",
    created_date: "2024-04-19 18:35:41.074000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Indus Civilization and Vedic period",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "598fad9e-4da1-41a0-877e-f5b54a54c3b4",
    topic_id: "6b67b737-4294-4738-88a9-00254d0baa04",
  },
  {
    sub_topic_id: "332bcb43-5c11-4010-b395-5a153b30522d",
    created_date: "2024-04-19 18:39:09.043000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Freedom Struggle",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "598fad9e-4da1-41a0-877e-f5b54a54c3b4",
    topic_id: "195003e0-f451-4a70-9b55-93f54fbf2330",
  },
  {
    sub_topic_id: "838838aa-d3d1-4bd9-86b4-65454659acb7",
    created_date: "2024-04-19 18:25:43.849000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Judiciary",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "a31168ae-d61d-40df-b52e-26804b527aba",
    topic_id: "ce4ad0c2-80a1-45d3-a7af-38949f0ca193",
  },
  {
    sub_topic_id: "875a83a0-152c-4a5a-9e62-6cf24bd3906f",
    created_date: "2024-04-19 18:56:47.100000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Government Schemes",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "1da3ceda-8d97-4e9f-89db-62c8ab9438bd",
    topic_id: "ca68abfb-7ed2-4bb1-abdb-021d34b9c826",
  },
  {
    sub_topic_id: "1a75986e-87e0-4aae-8bc9-2a113f9f3345",
    created_date: "2024-04-19 18:53:43.381000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "International Laws",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "9f0f3090-d233-4624-8928-ae8a1d9aec28",
    topic_id: "c249f3e1-2b67-49c3-86e3-5365475e2546",
  },
  {
    sub_topic_id: "2355ede3-52a7-48cb-aeaf-8cc78a7718b7",
    created_date: "2024-04-19 18:56:47.075000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Science and Technology",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "1da3ceda-8d97-4e9f-89db-62c8ab9438bd",
    topic_id: "ca68abfb-7ed2-4bb1-abdb-021d34b9c826",
  },
  {
    sub_topic_id: "df0b66d5-8292-4046-bf25-a13dbed365a2",
    created_date: "2024-04-19 18:57:59.239000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Intl. Conferences and Events",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "1da3ceda-8d97-4e9f-89db-62c8ab9438bd",
    topic_id: "8637fe35-dd9f-45d3-9a52-5c305ed08a99",
  },
  {
    sub_topic_id: "f0b58e98-e8d6-401d-ae84-e85564452910",
    created_date: "2024-04-19 18:48:18.454000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Money Market",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "af428d35-7ad1-4c54-b0c6-27f0873682ae",
    topic_id: "06a1b21e-2239-44fd-89cb-70085457983d",
  },
  {
    sub_topic_id: "4320972c-360e-4c5e-a0cc-8b8571eba2ee",
    created_date: "2024-04-19 18:26:59.855000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "State Legislature",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "a31168ae-d61d-40df-b52e-26804b527aba",
    topic_id: "3d06e7c9-51ab-4c60-9ee2-3670d11b09b8",
  },
  {
    sub_topic_id: "0199bb41-e1ea-47a5-9d5b-0b2ebd2ce9b8",
    created_date: "2024-04-19 18:31:35.094000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Basics of Earth",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "ab4a8cf7-2a6f-4a7f-8285-bfdf4c95c3b2",
    topic_id: "c4ec61bb-b53d-4bc6-99d0-7005608d220f",
  },
  {
    sub_topic_id: "f26b2201-e57d-4c0d-a8f3-d7b17624bf17",
    created_date: "2024-04-19 18:55:03.269000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Pollution Causes and Effects",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "9f0f3090-d233-4624-8928-ae8a1d9aec28",
    topic_id: "5bcd8d99-e644-4de5-9495-f8d3ffd1be5c",
  },
  {
    sub_topic_id: "00cd4473-0de0-468d-88d2-7d611bd9adc0",
    created_date: "2024-04-19 18:37:14.169000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Religious Reformers",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "598fad9e-4da1-41a0-877e-f5b54a54c3b4",
    topic_id: "46a13b61-7dcd-4f4d-840e-c53d4d3d234c",
  },
  {
    sub_topic_id: "9917360f-9654-4f2e-9092-30b76f9eef50",
    created_date: "2024-04-19 18:46:05.720000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Fiscal Policy",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "af428d35-7ad1-4c54-b0c6-27f0873682ae",
    topic_id: "c3e30b97-36f1-4313-a5df-e82ffa7c3271",
  },
  {
    sub_topic_id: "84a1a4d7-ba5e-4fe6-b476-378cd76cb85d",
    created_date: "2024-04-19 18:35:41.137000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Buddhism and Jainism",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "598fad9e-4da1-41a0-877e-f5b54a54c3b4",
    topic_id: "6b67b737-4294-4738-88a9-00254d0baa04",
  },
  {
    sub_topic_id: "b2821a10-1c92-46a7-8287-414aa7dcb344",
    created_date: "2024-04-19 18:25:43.874000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Rights, Duties and State Policy",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "a31168ae-d61d-40df-b52e-26804b527aba",
    topic_id: "ce4ad0c2-80a1-45d3-a7af-38949f0ca193",
  },
  {
    sub_topic_id: "e19e0c70-2944-4745-afb1-33c587b48f40",
    created_date: "2024-04-19 18:32:55.098000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Resource distribution",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "ab4a8cf7-2a6f-4a7f-8285-bfdf4c95c3b2",
    topic_id: "52b749c7-c17f-420c-971b-9cfed5c465ab",
  },
  {
    sub_topic_id: "06285803-c087-47cc-ac74-190f17ae7638",
    created_date: "2024-04-19 18:25:43.772000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Features",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "a31168ae-d61d-40df-b52e-26804b527aba",
    topic_id: "ce4ad0c2-80a1-45d3-a7af-38949f0ca193",
  },
  {
    sub_topic_id: "d8165be3-649c-4ab5-a8d4-3537f925799f",
    created_date: "2024-04-19 18:26:59.819000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Parliament",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "a31168ae-d61d-40df-b52e-26804b527aba",
    topic_id: "3d06e7c9-51ab-4c60-9ee2-3670d11b09b8",
  },
  {
    sub_topic_id: "be06b847-9454-4b4d-89e8-fff7f256a4da",
    created_date: "2024-04-19 18:29:29.878000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "State Executive",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "a31168ae-d61d-40df-b52e-26804b527aba",
    topic_id: "715fc0fe-4246-417e-a81d-b1fb236844c4",
  },
  {
    sub_topic_id: "320a5d26-56a3-4ceb-8370-21f43d234479",
    created_date: "2024-04-19 18:37:14.143000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Mughal",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "598fad9e-4da1-41a0-877e-f5b54a54c3b4",
    topic_id: "46a13b61-7dcd-4f4d-840e-c53d4d3d234c",
  },
  {
    sub_topic_id: "ab520658-bc5c-4c19-8aea-622330a9b698",
    created_date: "2024-04-19 18:29:29.842000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Union Executive",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "a31168ae-d61d-40df-b52e-26804b527aba",
    topic_id: "715fc0fe-4246-417e-a81d-b1fb236844c4",
  },
  {
    sub_topic_id: "dbb731ef-950b-4269-8cb5-68256b26b9ac",
    created_date: "2024-04-19 18:26:59.888000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Local Governance",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "a31168ae-d61d-40df-b52e-26804b527aba",
    topic_id: "3d06e7c9-51ab-4c60-9ee2-3670d11b09b8",
  },
  {
    sub_topic_id: "b06bce42-0a01-4e10-9198-9c499d8cc79b",
    created_date: "2024-04-19 18:37:14.104000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Regional Kingdoms",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "598fad9e-4da1-41a0-877e-f5b54a54c3b4",
    topic_id: "46a13b61-7dcd-4f4d-840e-c53d4d3d234c",
  },
  {
    sub_topic_id: "08acf575-6702-4fa0-9db4-043ebb75b319",
    created_date: "2024-04-19 18:51:38.779000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Basics of Ecology",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "9f0f3090-d233-4624-8928-ae8a1d9aec28",
    topic_id: "0b9999ad-a579-4381-bce9-219ae11102c4",
  },
  {
    sub_topic_id: "90340d3e-debe-43ef-a6c0-5fb1bf5915b2",
    created_date: "2024-04-19 18:29:29.903000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Constitutional and Statutory Bodies",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "a31168ae-d61d-40df-b52e-26804b527aba",
    topic_id: "715fc0fe-4246-417e-a81d-b1fb236844c4",
  },
  {
    sub_topic_id: "fa8a5acd-8f4f-4cba-98b5-21054bd900da",
    created_date: "2024-04-19 18:48:18.506000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Trade",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "af428d35-7ad1-4c54-b0c6-27f0873682ae",
    topic_id: "06a1b21e-2239-44fd-89cb-70085457983d",
  },
  {
    sub_topic_id: "a464f12b-a782-4e43-ab42-73e4c5fcb8c6",
    created_date: "2024-04-19 18:29:29.931000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Non Constitutional Bodies",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "a31168ae-d61d-40df-b52e-26804b527aba",
    topic_id: "715fc0fe-4246-417e-a81d-b1fb236844c4",
  },
  {
    sub_topic_id: "6899e033-3165-4ff6-80f2-d7250718cd6d",
    created_date: "2024-04-19 18:51:38.814000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Biodiversity",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "9f0f3090-d233-4624-8928-ae8a1d9aec28",
    topic_id: "0b9999ad-a579-4381-bce9-219ae11102c4",
  },
  {
    sub_topic_id: "6979ea83-0ffe-4cc3-87ab-cbdec623c551",
    created_date: "2024-04-19 18:53:43.406000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Basics of Climate change",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "9f0f3090-d233-4624-8928-ae8a1d9aec28",
    topic_id: "c249f3e1-2b67-49c3-86e3-5365475e2546",
  },
  {
    sub_topic_id: "3367d31f-32b7-4ca2-9744-dd390aa26414",
    created_date: "2024-04-19 18:35:41.107000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Art and Culture",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "598fad9e-4da1-41a0-877e-f5b54a54c3b4",
    topic_id: "6b67b737-4294-4738-88a9-00254d0baa04",
  },
  {
    sub_topic_id: "a936b2c1-0970-4bd7-b5f6-5303afc8ea17",
    created_date: "2024-04-19 18:48:18.478000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Banking",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "af428d35-7ad1-4c54-b0c6-27f0873682ae",
    topic_id: "06a1b21e-2239-44fd-89cb-70085457983d",
  },
  {
    sub_topic_id: "286ca244-251e-4e6a-ba3a-837002188983",
    created_date: "2024-04-19 18:57:59.292000",
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    is_active: true,
    prompt_keywords: null,
    sub_topic_name: "Geopolitical Events",
    sub_topic_prompt: null,
    sub_topic_prompt_keywords: null,
    subject_id: "1da3ceda-8d97-4e9f-89db-62c8ab9438bd",
    topic_id: "8637fe35-dd9f-45d3-9a52-5c305ed08a99",
  },
];

export const languages = [
  {
    key: "en",
    label: "English",
    code: "en",
  },
  {
    key: "ur",
    label: "Urdu",
    code: "ur",
  },
  {
    key: "sd",
    label: "Sindhi",
    code: "sd",
  },
  {
    key: "bn",
    label: "Bengali",
    code: "bn",
  },
  {
    key: "hi",
    label: "Hindi",
    code: "hi",
  },
  {
    key: "ta",
    label: "Tamil",
    code: "ta",
  },
  {
    key: "or",
    label: "Oriya",
    code: "or",
  },
  {
    key: "ne",
    label: "Nepali",
    code: "ne",
  },
  {
    key: "gu",
    label: "Gujarati",
    code: "gu",
  },
  {
    key: "ml",
    label: "Malayalam",
    code: "ml",
  },
  {
    key: "te",
    label: "Telugu",
    code: "te",
  },
  {
    key: "kn",
    label: "Kannada",
    code: "kn",
  },
  {
    key: "mr",
    label: "Marathi",
    code: "mr",
  },
];
export const exams = [
  {
    exam_id: "813b6e33-91ff-49f0-8e08-2f479c65e4e1",
    exam_name: "UPSC",
  },
];
