/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
// import QuizQuestionsRate from "./QuizQuestionsRate";
// import QuizOptions from "./SimilarQuizOptions";
import "../QuizQuestionComponentAnalysis.css";
import {
  AppRegistration,
  AppRegistrationTwoTone,
  BookmarkAddTwoTone,
  ReportTwoTone,
} from "@mui/icons-material";
import SimilarQuizOptions from "./SimilarQuizOptions";

const defaultProps = {
  text: "1. Who was the first President of India?",
};

const iconsList = [
  {
    name: "Take new Quiz",
    icon: <AppRegistrationTwoTone className="icon-div" />,
  },
  {
    name: "Save Question",
    icon: <BookmarkAddTwoTone className="icon-div" />,
  },
  {
    name: "Report Question",
    icon: <ReportTwoTone className="icon-div" />,
  },
];
const SimilarQuizQuestionComponentAnalysis = (props) => {
  const [fontSize, setFontSize] = useState(36);
  const questionRef = useRef(null);
  const [correctAnswer, setCorrectAnswer] = useState(6);

  useEffect(() => {
    if (!questionRef.current) return;

    const { lineHeight, height } = getComputedStyle(questionRef.current);
    const numLines = Math.ceil(parseInt(height, 10) / parseInt(lineHeight, 10));
    // Set font size based on number of lines within the given range
    setFontSize(Math.min(Math.max(20, 26 - (numLines - 1)), 26));
  }, []);
  return (
    <div className="question-container" style={{ marginTop: 8 }}>
      <div className="question-text-container">
        <h4>Q{props.index + 1}. </h4>
        <div className="question-text" ref={questionRef}>
          {props.data.question_text}
        </div>
      </div>
      <div className="options-container">
        <SimilarQuizOptions
          data={[
            props.data.option_a,
            props.data.option_b,
            props.data.option_c,
            props.data.option_d,
          ]}
          questiondata={props.data}
          analysis={true}
        />
      </div>
      <div className="explaination">
        <h5 style={{ fontSize: "14px", marginTop: "2px", fontFamily: "Roboto" }}>Explanation: </h5>
        <div className="explaintext">{props.data.explanation}</div>
      </div>
      {/* <div className="div-options">
        {iconsList.map((item) => {
          return (
            <div
              style={{
                backgroundColor: "#ffffff",
                borderRadius: "80px",
                boxShadow: "0px 2px 8px rgba(0,0,0,0.16)",
                marginRight: "20px",
                display: "flex",
                flexDirection: "row",
                padding: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                props.setReferenceQuestion(props.data.question_text);
                props.open(true);
              }}
            >
              <div className="text-div">{item.name}</div>
              {item.icon}
            </div>
          );
        })}
      </div> */}
      <div className="separator" />
    </div>
  );
};

export default SimilarQuizQuestionComponentAnalysis;
