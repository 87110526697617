/* eslint-disable react/prop-types */
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components

import { ImportContacts } from "@mui/icons-material";
import "./ShowQuizResults.css";
import QuizQuestionComponentAnalysis from "../quiz/QuizQuestionComponentAnalysis";
import { useEffect, useState } from "react";
import { client } from "constants/APIFunctions";
import { useLocation, useNavigate } from "react-router-dom";
import FullScreenLottie from "../quiz/FullScreenLottie";
// and just use it
import animationData from "../quiz/analysis.json"; // Replace with your animation file
import Lottie from "react-lottie";
// import animationData1 from "../quiz/loadingnew.json"; // Replace with your animation file
// import ReactECharts from "echarts-for-react";
import CustomizedTables from "layouts/quiz/components/QuizTable";
import Typist from "react-typist";
import animationDatabot from "../quiz/components/bot.json";
import ReactGA from "react-ga4";
ReactGA.initialize("G-EDEXTHSM4C");

const styles = {
  Text: {
    color: "#030303",
    fontSize: "30px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: "500",
    lineHeight: "45px",
    marginLeft: "8px",
    width: "95%",
    textAlign: "center",
  },
  Summarytext: {
    color: "#030303",
    fontSize: "16px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: "500",
    lineHeight: "22px",
    width: "auto",
    textAlign: "justify",
    whiteSpace: "pre-wrap",
    maxHeight: 300,
    overflowY: "none",
  },
  TextSelected: {
    color: "#023f81",
    fontSize: "20px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: "500",
    lineHeight: "24px",
    marginLeft: "8px",
    width: "95%",
    textAlign: "center",
    marginTop: "10px",
  },
  TextSelectedBot: {
    //color: "#023f81",
    fontSize: "20px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: "500",
    lineHeight: "26px",
    marginLeft: "8px",
    width: "95%",
    textAlign: "center",
    marginTop: "10px",
    alignSelf: "center",
    fontStyle: "oblique",
  },
  Button: {
    cursor: "pointer",
    top: "32px",
    left: "977px",
    width: "163px",
    height: "50px",
    padding: "0px 8px",
    border: "1px solid #919191",
    boxSizing: "border-box",
    borderRadius: "40px",
    backgroundColor: "rgba(255,255,255,0.07)",
    color: "#000000",
    fontSize: "18px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: 500,
    lineHeight: "24px",
    outline: "none",
  },
  ButtonSelected: {
    cursor: "pointer",
    top: "30px",
    left: "1188px",
    width: "163px",
    height: "50px",
    padding: "0px 8px",
    border: "0",
    boxSizing: "border-box",
    borderRadius: "40px",
    backgroundColor: "#2f667f",
    color: "#ffffff",
    fontSize: "18px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: 500,
    lineHeight: "24px",
    outline: "none",
    marginLeft: "8px",
  },
  Card: {
    width: "90%",
    height: "60px",
    backgroundColor: "#2f667f",
    borderRadius: "100px",
    boxShadow: "0px 2px 8px rgba(0,0,0,0.16)",
    marginBottom: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  CardText: {
    color: "#ffffff",
    fontSize: "22px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: "700",
    lineHeight: "29px",
    textAlign: "center",
    width: "90%",
  },
  CardButton: {
    cursor: "pointer",
    width: "201px",
    height: "53px",
    padding: "0px 8px",
    border: 0,
    boxSizing: "border-box",
    borderRadius: "40px",
    backgroundColor: "#ffffff",
    color: "#000000",
    fontSize: "20px",
    fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
    fontWeight: "500",
    lineHeight: "24px",
    outline: "none",
    margin: "10px 0" /* Add margin for spacing */,
  },
};
const defaultProps = {
  text: "UPSC Quiz Page",
  label: "Skip",
  selectedLabel: "Submit",
};
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationDatabot,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
function ShowPreviousQuizResults(props) {
  const location = useLocation();
  const data = location.state && location.state.data;
  const [analysis, setAnalysis] = useState();
  const [analysisData, setAnalysisData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [score, setscore] = useState(0);
  const [analyseData, setAnalyseData] = useState([]);
  const [analysisLoading, setAnalysisLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  useEffect(() => {
    console.log(data.test_id);
    client
      .get("/api/test/get_responses_of_test", {
        params: {
          test_id: data.test_id,
        },
      })
      .then((response) => {
        setLoading(false);
        console.log("analyze", response);
        setAnalysisData(response.data.analysis);
        setAnalysis(response.data);
        var pysparkdata = [];
        response.data.analysis.map((item) => {
          pysparkdata.push({
            is_response_correct: item.is_response_correct,
            response_categories: item.response_categories,
            test_id: item.question_id,
            user_response: item.user_response,
            question_id: item.question_id,
          });
        });
        // callAnalysis(pysparkdata);
        var correctCount = 0;
        var wrongCount = 0;
        var skippedCount = 0;
        response.data.analysis.map((item) => {
          if (item.user_response == "skipped") {
            skippedCount = skippedCount + 1;
          } else {
            if (item.correct_anaswer == item.user_response) {
              correctCount = correctCount + 1;
            } else {
              wrongCount = wrongCount + 1;
            }
          }
        });
        var arr = [
          {
            name: "Correct",
            score: correctCount,
            totalScore: correctCount * 2,
          },
          {
            name: "Wrong",
            score: wrongCount,
            totalScore: Number.parseFloat(wrongCount * 0.66).toFixed(2),
          },
          {
            name: "Skipped",
            score: skippedCount,
            totalScore: 0,
          },
          {
            name: "Total Score",
            score: `${Number.parseFloat(correctCount * 2 - wrongCount * 0.66).toFixed(2) * 5}%`,
            totalScore: Number.parseFloat(correctCount * 2 - wrongCount * 0.66).toFixed(2),
          },
        ];
        setTableData(arr);
        // setQuizdata(response.data.questions);
      })
      .catch((err) => {
        console.log(err);
        alert("An error occurred");
      });
  }, []);
  const callAnalysis = (data) => {
    console.log("analysedata", data);
    client
      .post("/api/analysis/analysis", { data: data })
      .then((response) => {
        setAnalyseData(response.data.analysis);
        setAnalysisLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      {loading ? (
        <MDBox mt={8}>
          <FullScreenLottie
            content={"Your Results are getting generated..."}
            animation={animationData}
          />
        </MDBox>
      ) : (
        <MDBox mt={8}>
          <div style={styles.TextSelected}>
            Thank you for attending the Quiz your results are here
          </div>
          <div style={{ marginTop: "30px" }} />
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <CustomizedTables data={tableData} />
                {/* <ReactSpeedometer
                  segments={1}
                  minValue={-100}
                  value={analysis?.test_score ? Number.parseInt(analysis?.test_score) * 10 : score}
                  maxValue={100}
                  segmentColors={["#023f81"]}
                  needleColor="#023f81"
                  needleHeightRatio={0.6}
                  ringWidth={8}
                  customSegmentStops={[-100, -80, -60, -40, -20, 0, 20, 40, 60, 80, 100]}
                  customSegmentLabels={[
                    { fontSize: 10, color: "#5d5d5b", position: "OUTSIDE", text: "-80" },
                    { fontSize: 10, color: "#5d5d5b", position: "OUTSIDE", text: "-60" },
                    { fontSize: 10, color: "#5d5d5b", position: "OUTSIDE", text: "-40" },
                    { fontSize: 10, color: "#5d5d5b", position: "OUTSIDE", text: "-20" },
                    { fontSize: 10, color: "#5d5d5b", position: "OUTSIDE", text: "0" },
                    { fontSize: 10, color: "#5d5d5b", position: "OUTSIDE", text: "20" },
                    { fontSize: 10, color: "#5d5d5b", position: "OUTSIDE", text: "40" },
                    { fontSize: 10, color: "#5d5d5b", position: "OUTSIDE", text: "60" },
                    { fontSize: 10, color: "#5d5d5b", position: "OUTSIDE", text: "80" },
                    { fontSize: 10, color: "#5d5d5b", position: "OUTSIDE", text: "100" },
                  ]}
                /> */}
                {/* <ReactECharts
                  style={{ width: "100%" }}
                  option={{
                    series: [
                      {
                        type: "gauge",
                        startAngle: 180,
                        endAngle: 0,
                        center: ["50%", "75%"],
                        radius: "90%",
                        min: 0,
                        max: 1,
                        splitNumber: 8,
                        axisLine: {
                          lineStyle: {
                            width: 6,
                            color: [
                              [0.25, "#FF6E76"],
                              [0.5, "#FDDD60"],
                              [0.75, "#58D9F9"],
                              [1, "#7CFFB2"],
                            ],
                          },
                        },
                        pointer: {
                          icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
                          length: "12%",
                          width: 20,
                          offsetCenter: [0, "-60%"],
                          itemStyle: {
                            color: "auto",
                          },
                        },
                        axisTick: {
                          length: 12,
                          lineStyle: {
                            color: "auto",
                            width: 2,
                          },
                        },
                        splitLine: {
                          length: 20,
                          lineStyle: {
                            color: "auto",
                            width: 5,
                          },
                        },
                        axisLabel: {
                          color: "#464646",
                          fontSize: 12,
                          distance: -50,
                          rotate: "tangential",
                          formatter: function (value) {
                            if (value === 0.875) {
                              return "Grade A";
                            } else if (value === 0.625) {
                              return "Grade B";
                            } else if (value === 0.375) {
                              return "Grade C";
                            } else if (value === 0.125) {
                              return "Grade D";
                            }
                            return "";
                          },
                        },
                        title: {
                          offsetCenter: [0, "-10%"],
                          fontSize: 20,
                        },
                        detail: {
                          fontSize: 30,
                          offsetCenter: [0, "-35%"],
                          valueAnimation: true,
                          formatter: function (value) {
                            return Math.round(value * 100) + "";
                          },
                          color: "inherit",
                        },
                        data: [
                          {
                            value: analysis?.test_score
                              ? Number.parseInt(analysis?.test_score) / 10
                              : score,
                            name: "Grade Rating",
                          },
                        ],
                      },
                    ],
                  }}
                  notMerge={true}
                  lazyUpdate={true}
                /> */}
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="summary-div-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                      alignContent: "start",
                      textAlign: "left",
                      justifyContent: "left", // Align animation to start from left side
                      width: "100%",
                      borderRadius: "8px", // Rounded corners
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Lottie
                        options={defaultOptions}
                        height={80}
                        width={80}
                        style={{ alignSelf: "start" }}
                      />
                      <div style={styles.TextSelectedBot} className="summary-heading-text">
                        Feedback by Sconti.ai
                      </div>
                    </div>
                  </div>
                  <Typist
                    cursor={{ show: true, blink: true, element: "|", hideWhenDone: true }}
                    avgTypingDelay={20}
                    startDelay={300}
                  >
                    <div style={styles.Summarytext}>{analysis?.summary}</div>
                  </Typist>
                </div>
              </Grid>
            </Grid>
          </MDBox>
          <h4>Question Answer Analysis</h4>
          {analysisData.map((item, index) => {
            return (
              <QuizQuestionComponentAnalysis
                key={item.question + index}
                data={item}
                index={index}
              />
            );
          })}
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default ShowPreviousQuizResults;
