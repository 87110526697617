import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SelectLanguage from "layouts/authentication/screens/SelectLanguage";
import ChipsArray from "layouts/authentication/screens/SelectExams";
import { Button } from "@mui/material";
import axios from "axios";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ToastContainer, toast } from "react-toastify";
import { client } from "constants/APIFunctions";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
ReactGA.initialize("G-EDEXTHSM4C");

export default function SettingsPage() {
  const [userDetails, setUserDetails] = React.useState();
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    const items = JSON.parse(localStorage.getItem("userdata"));
    console.log(items);
    setUserDetails({ ...items, selected_language: items.preferred_language });
  }, []);
  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  const navigation = useNavigate();

  const handleUpdate = () => {
    setLoading(true);
    var user = { ...userDetails, full_name: userDetails.fullname };
    console.log(user, userDetails);
    client
      .put(`/api/user/edit_user`, user)
      .then((response) => {
        console.log("User details updated successfully:", response.data);
        toast("Preferences Updated Successfully");
        localStorage.setItem("userdata", JSON.stringify(userDetails));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error updating user details:", error);
        toast.error("Error updating preferences");
        setLoading(false);
      });
    ReactGA.event({
      category: "Updating Langauge",
      action: "User is going to update his preference  of langauge",
    });
    // navigation("/upsc-kpsc-online-mocktest");
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SAMAI-Choose the langauge and select the mock test type </title>
        <meta
          name="description"
          content="Here you can update the langauge you want to take the test and the type "
        />
        {/* <meta
          name="keyword"
          content="Free UPSC mock test online, UPSC mock test series, UPSC Civil Services Exam Mock Tests, UPSC Preliminary Test Series, UPSC Mains Test Series, KPSC Preliminary Mock Tests, NEET Entrance Exam Mock Tests, NEET practice questions, NEET sample tests, D-cet Entrance Exam Mock Tests, UPSC GS Mock Tests, UPSC CSE Mock Tests, KPSC GS Mock Tests, NEET Biology Mock Tests, NEET Physics Mock Tests, UPSC Current Affairs Mock Tests, UPSC CSAT Mock Tests, UPSC Optional Subject Mock Tests, UPSC Online Practice Tests, KPSC Competitive Exams Mock Tests, NEET Chemistry Mock Tests, UPSC Test Series for Beginners, KPSC Test Series for Beginners, UPSC Free Online Test Series, Mock Tests for UPSC Exams, NEET mock test series, NEET biology mock tests, NEET chemistry mock tests, NEET physics mock tests, NEET previous year papers with solutions, Practice mock test, Previous year question papers with solutions, Chapter-wise mock tests, Subject-wise mock tests, Online test series for competitive exams, Best mock test series for competitive exams, Free online mock tests for government exams, Mock test preparation tips, Best mock test for UPSC online, UPSC online mock test, KPSC online coaching, Best AI-Based test for NEET preparation, Online NEET Test platform, KPSC KAS mock tests, KPSC PSI mock tests,UPSC Mock Test, UPSC Test Series, UPSC Practice, KPSC Mock Test, NEET Mock Test, D-cet Mock Test, UPSC Civil Services, UPSC Prelims, UPSC Mains, KPSC Exams, NEET Test Series, UPSC GS, UPSC CSAT, UPSC Coaching, KPSC Coaching, NEET Preparation, Mock Test Series, Online Mock Test, Free Mock Test, Government Exams, UPPSC Mock Tests, UPPSC, Mock Tests"
        /> */}
        <meta
          property="og:description"
          content="Here you can update the langauge you want to take the test and the type "
        />
        <meta property="og:url" content="https://samai.scontiapp.com/settings" />
        <meta property="og:site_name" content="SAMAI-AI Mock Test " />
        <link rel="canonical" href="https://samai.scontiapp.com/settings" />
      </Helmet>
      <DashboardLayout>
        <DashboardNavbar absolute isMini />

        <Box p={1} display="flex" flexDirection="column">
          <ToastContainer />
          <Typography
            variant="h4"
            gutterBottom
            style={{
              fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
              fontWeight: 500,
              marginBottom: "10px",
            }}
          >
            Personalise your Preferences
          </Typography>
          <Accordion
            elevation={3}
            sx={{
              mb: 3,
              border: "2px solid #edc967",
              backgroundColor: "#fff",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="exams-content"
              id="exams-header"
              style={{ borderRadius: 8 }}
            >
              <Typography
                variant="h5"
                style={{
                  fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
                  fontWeight: 500,
                }}
              >
                Update your Preferred Exams
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* <Paper sx={{ p: 1, borderRadius: 3, width: "100%" }}> */}
              <ChipsArray
                setExams={setUserDetails}
                exams={userDetails}
                selectedExamsData={
                  userDetails?.interested_exams && !userDetails?.interested_exams?.includes("N/A")
                    ? JSON.parse(userDetails?.interested_exams)
                    : {}
                }
              />
              {/* </Paper> */}
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={3}
            sx={{
              mb: 3,
              border: "2px solid #edc967",
              backgroundColor: "#fff",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.25)",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="language-content"
              id="language-header"
            >
              <Typography
                variant="h5"
                style={{
                  fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
                  fontWeight: 500,
                }}
              >
                Update your Preferred Language
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* <Paper sx={{ p: 1, borderRadius: 3, width: "100%" }}> */}
              <SelectLanguage
                setExams={setUserDetails}
                exams={userDetails}
                selectedLanguageData={userDetails?.selected_language}
              />
              {/* </Paper> */}
            </AccordionDetails>
          </Accordion>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              borderRadius: 24,
              marginTop: 3,
              alignSelf: "flex-end",
              color: "#fff",
              width: "160px",
            }}
            onClick={handleUpdate}
          >
            {loading ? "Updating..." : "Update"}
          </Button>
        </Box>
      </DashboardLayout>
    </>
  );
}
