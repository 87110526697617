/* eslint-disable react/prop-types */
import * as React from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import { client } from "constants/APIFunctions";
import { languages } from "layouts/quiz/data/Precacheddata";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function SelectLanguage(props) {
  const [chipData, setChipData] = React.useState([]);
  const [selectedChip, setSelectedChip] = React.useState([]);
  const checkIfExists = (value) => {
    if (props.selectedLanguageData) {
      return props.selectedLanguageData == value;
    } else {
      return selectedChip.some((item) => item.label === value);
    }
  };
  const getLanguages = () => {
    client
      .get("/api/language/languages")
      .then((response) => {
        console.log(response);
        var data = [];
        response.data.exams.map((item, index) => {
          var objExams = {
            key: item.language_code,
            label: item.language_name,
            code: item.language_code,
          };
          data.push(objExams);
        });
        if (data && data.length > 0) {
          setChipData(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    setChipData(languages);
    getLanguages();
  }, []);
  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "left",
        flexWrap: "wrap",
        listStyle: "none",
        p: 0.5,
        m: 0,
        maxHeight: 135,
        backgroundColor: "#fff",
        borderWidth: 1,
        borderColor: "#333",
        overflowY: "auto",
      }}
      component="ul"
    >
      {chipData.map((data) => {
        let icon;

        return (
          <ListItem key={data.key}>
            <Chip
              label={data.label}
              onClick={() => {
                if (checkIfExists(data.label)) {
                  setSelectedChip([]);
                  props.setExams({
                    ...props.exams,
                    selected_language: "",
                    selected_language_code: "",
                    preferred_language: "",
                    preferred_language_code: "",
                  });
                } else {
                  setSelectedChip([data]);
                  props.setExams({
                    ...props.exams,
                    selected_language: data.label,
                    selected_language_code: data.code,
                    preferred_language: data.label,
                    preferred_language_code: data.code,
                  });
                  console.log(selectedChip);
                }
              }}
              style={{
                backgroundColor: checkIfExists(data.label) ? "#2f667f" : "#f1eff0",
                color: checkIfExists(data.label) ? "#fff" : "#000",
                borderRadius: "8px",
              }}
            />
          </ListItem>
        );
      })}
    </Paper>
  );
}
