/* eslint-disable react/prop-types */
import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomizedTables(props) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        {/* <TableHead>
          <StyledTableRow style={{ width: "100%" }}>
            <StyledTableCell component="th" scope="row">
              Response
            </StyledTableCell>
            <StyledTableCell align="right">Questions</StyledTableCell>
            <StyledTableCell align="right">Score</StyledTableCell>
          </StyledTableRow>
        </TableHead> */}
        <TableBody>
          <StyledTableRow key={"aq"} style={{ backgroundColor: "gold" }}>
            <StyledTableCell
              style={{
                letterSpacing: 4,
                fontFamily: "Orbitron, sans-serif",
                fontWeight: "bolder", // Add fontWeight if desired
                fontSize: window.innerWidth > 768 ? "14px" : "10px", // Add fontSize if desired
                color: "#333",
              }}
            >
              Response
            </StyledTableCell>
            <StyledTableCell
              align="right"
              style={{
                letterSpacing: 4,
                fontFamily: "Orbitron, sans-serif",
                fontWeight: "bolder", // Add fontWeight if desired
                fontSize: window.innerWidth > 768 ? "14px" : "10px", // Add fontSize if desired
                color: "#333",
              }}
            >
              Questions
            </StyledTableCell>
            <StyledTableCell
              align="right"
              style={{
                letterSpacing: 4,
                fontFamily: "Orbitron, sans-serif",
                fontWeight: "bolder", // Add fontWeight if desired
                fontSize: window.innerWidth > 768 ? "14px" : "10px", // Add fontSize if desired
                color: "#333",
              }}
            >
              Score
            </StyledTableCell>
          </StyledTableRow>
          {props?.data?.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell
                style={{
                  letterSpacing: 4,
                  fontFamily: "Orbitron, sans-serif",
                  fontWeight: "normal", // Add fontWeight if desired
                  fontSize: window.innerWidth > 768 ? "14px" : "12px", // Add fontSize if desired
                  color: "#333",
                }}
              >
                {row.name}
              </StyledTableCell>
              <StyledTableCell
                align="right"
                style={{
                  letterSpacing: 4,
                  fontFamily: "Orbitron, sans-serif",
                  fontWeight: "bolder", // Add fontWeight if desired
                  fontSize: window.innerWidth > 768 ? "14px" : "12px", // Add fontSize if desired
                  color: "#333",
                }}
              >
                {row.score} {row.percentage ? "%" : ""}
              </StyledTableCell>
              <StyledTableCell
                align="right"
                style={{
                  letterSpacing: 4,
                  fontFamily: "Orbitron, sans-serif",
                  fontWeight: "bolder", // Add fontWeight if desired
                  fontSize: window.innerWidth > 768 ? "14px" : "12px", // Add fontSize if desired
                  color: "#333",
                }}
              >
                {row.totalScore}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
