/* eslint-disable react/jsx-key */
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import ReactECharts from "echarts-for-react";

// Billing page components
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import Invoices from "layouts/billing/components/Invoices";
import BillingInformation from "layouts/billing/components/BillingInformation";
import Transactions from "layouts/billing/components/Transactions";
import Card from "./CreateQuizCard";
import SearchInput from "./SearchInput";
import CreateQuizMainComponent from "./CreateQuizMainComponent";
import StartQuizButton from "./StartQuizButton";
import PreviousQuiz from "./PreviousQuiz";
import {
  ArrowDropDownCircle,
  ArrowDropUpTwoTone,
  ArrowUpward,
  ArrowUpwardOutlined,
  ArrowUpwardRounded,
  ArrowUpwardTwoTone,
  BookTwoTone,
  InsertChartOutlinedSharp,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));
const styles = {
  Card: {
    width: "100%",
    height: "192px",
    backgroundColor: "#1e1f26",
    borderRadius: "24px",
  },
};
function QuizProgress() {
  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={7}>
                  <div style={styles.Card}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: "8px",
                        alignItems: "center",
                        marginLeft: "15px",
                        marginRight: "15px",
                      }}
                    >
                      <h4 color="#fff" style={{ color: "#fff" }}>
                        Profile
                      </h4>
                      <div style={{ flexGrow: 1 }} />
                      <InsertChartOutlinedSharp fontSize="medium" color="#2f667f" />
                      <h4 color="#fff" style={{ color: "#fff", marginLeft: "8px" }}>
                        5,000
                      </h4>
                    </div>
                    <h2 color="#fff" style={{ color: "#fff", marginLeft: "15px" }}>
                      Total Score
                    </h2>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center", // Center vertically
                        marginLeft: "15px",
                        marginRight: "15px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "8px",
                          width: "48%",
                          marginLeft: "5px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <ArrowDropUpTwoTone fontSize="small" color="#2f667f" />
                          <div style={{ color: "#fff", fontSize: 12, marginLeft: "5px" }}>
                            Weekly
                          </div>
                        </div>
                        <h5 style={{ color: "#fff", marginTop: "5px" }}>Rank 1st</h5>
                      </div>
                      <div
                        style={{
                          width: "2px",
                          height: "60px",
                          backgroundColor: "#fff",
                          marginLeft: "5px",
                          marginRight: "5px",
                        }}
                      />
                      <div
                        style={{
                          marginTop: "8px",
                          width: "48%",
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "5px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                          }}
                        >
                          <ArrowDropDownCircle fontSize="small" color="#2f667f" />
                          <div style={{ color: "#fff", fontSize: 12, marginRight: "5px" }}>
                            Recent
                          </div>
                        </div>
                        <h5 style={{ color: "#fff", marginTop: "5px", textAlign: "right" }}>
                          Previous Score
                        </h5>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={5}>
                  <div style={{ ...styles.Card, display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: "8px",
                        alignItems: "center",
                        marginLeft: "15px",
                        marginRight: "15px",
                      }}
                    >
                      <h4 color="#fff" style={{ color: "#fff" }}>
                        Top performers
                      </h4>
                      <div style={{ flexGrow: 1 }} />
                      <ArrowUpwardRounded fontSize="medium" color="#2f667f" />
                      <h4 color="#fff" style={{ color: "#fff", marginLeft: "8px" }}>
                        5,000
                      </h4>
                    </div>
                    <h3 color="#fff" style={{ color: "#fff", marginLeft: "15px" }}>
                      Recent Quizzes
                    </h3>
                    <div style={{ flexGrow: 1 }} />
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        transform: "scaleY(-1)",
                        marginBottom: "30px",
                        marginLeft: "15px",
                      }}
                    >
                      {[10, 9, 8, 7, 6, 5, 4, 3, 2, 1].reverse().map((item) => {
                        return (
                          <div
                            key={item}
                            style={{
                              width: "16px",
                              height: (50 * item) / 10, // Adjusted the denominator to ensure correct scaling
                              backgroundColor: "#2f667f",
                              marginLeft: "10px",
                              borderRadius: "40px",
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: 8,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <h4 style={{ marginTop: 15, marginBottom: 8, marginLeft: 20 }}>Quiz Statistics</h4>

                <ReactECharts
                  option={{
                    legend: {},
                    tooltip: {},
                    dataset: {
                      source: [
                        ["product", "2015", "2016", "2017"],
                        ["Matcha Latte", 43.3, 85.8, 93.7],
                        ["Milk Tea", 83.1, 73.4, 55.1],
                        ["Cheese Cocoa", 86.4, 65.2, 82.5],
                        ["Walnut Brownie", 72.4, 53.9, 39.1],
                      ],
                    },
                    xAxis: { type: "category" },
                    yAxis: {},
                    // Declare several bar series, each will be mapped
                    // to a column of dataset.source by default.
                    series: [{ type: "bar" }, { type: "bar" }, { type: "bar" }],
                  }}
                  notMerge={true}
                  lazyUpdate={true}
                />
              </Grid>
              <Grid container spacing={3} sx={{ marginTop: "10px" }}>
                <Grid item xs={12} md={6}>
                  <div style={{ ...styles.Card, backgroundColor: "#2f667f" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "8px",
                        marginLeft: "15px",
                        marginRight: "15px",
                      }}
                    >
                      <h5 color="#fff" style={{ color: "#fff" }}>
                        Goals
                      </h5>

                      <h6 color="#fff" style={{ color: "#fff" }}>
                        Achievements
                      </h6>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: "15px",
                        marginRight: "15px",
                      }}
                    >
                      <div
                        style={{
                          width: "60px",
                          height: "60px",
                          borderRadius: "8px",
                          boxSizing: "border-box",
                          borderRadius: "10px",
                          color: "#ffffff",
                          backgroundColor: "#333333",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <BookTwoTone
                          color="#fff"
                          sx={{
                            color: "#fff",
                            width: 40,
                            height: 40,
                            alignSelf: "center",
                            marginLeft: "8px",
                            marginTop: "8px",
                          }}
                        />
                      </div>
                      <div style={{ flexGrow: 1 }} />
                      <div style={{ width: "70%" }}>
                        <h4 style={{ color: "#fff", marginBottom: "8px" }}>62% completed</h4>
                        <BorderLinearProgress variant="determinate" value={60} />
                        <div style={{ color: "#fff", fontSize: 14 }}>
                          Completed: $1,485 out of $2,3
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div style={{ ...styles.Card, backgroundColor: "#2f667f" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "8px",
                        marginLeft: "15px",
                        marginRight: "15px",
                      }}
                    >
                      <h5 color="#fff" style={{ color: "#fff" }}>
                        Quiz Progress
                      </h5>
                    </div>
                    <div style={{ marginLeft: "20px", marginRight: "20px", marginTop: "10px" }}>
                      {[1, 2, 3, 4].map((item) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <BorderLinearProgress
                              variant="determinate"
                              value={60}
                              sx={{ width: "40%" }}
                            />
                            <h6 style={{ color: "#fff" }}>68</h6>
                            <h6 style={{ color: "#fff" }}>Math Quiz</h6>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <PreviousQuiz />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default QuizProgress;
