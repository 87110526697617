/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import CreateQuiz from "layouts/quiz/CreateQuiz";
import TakeQuiz from "layouts/quiz/TakeQuizPage";
import ShowQuizResults from "layouts/quiz/ShowQuizResults";
import QuizDashBoard from "layouts/quiz/QuizDashboard";
import QuizProgress from "layouts/quiz/QuizProgress";
import QuizContainer from "layouts/quiz/QuizContainer";
import SignInSide from "layouts/authentication/screens/InitialScreen";
import LoginForm from "layouts/authentication/screens/LoginScreen";
import SignUpScreen from "layouts/authentication/screens/SignUpScreen";
import QuizHistory from "layouts/quiz-history/QuizHistory";
import ShowPreviousQuizResults from "layouts/quiz-history/ShowPreviousQuizResults";
import CreateQuizNew from "layouts/quiz/CreateQuizNew";
import CarousalPahe from "layouts/authentication/screens/CarousalPahe";
import LogoutIcon from "@mui/icons-material/Logout";
import HistoryIcon from "@mui/icons-material/History";
import { Settings } from "@mui/icons-material";
import SettingsPage from "./layouts/settings/SettingsPage";
import PrelimsLanding from "Prelims/PrelimsLanding";
import PrelimsQuestionPage from "Prelims/PrelimsQuestionPage";
import PrelimsSingleQuestion from "Prelims/PrelimsSingleQuestion";
import UPSCAnswerKey2024 from "Prelims/UPSCAnswerKey2024";
import NeetQuestionPage from "Neet/NeetQuestionPage";
const routes = [
  {
    type: "hidden", // Define a hidden route type
    route: "/online-mocktest", // Define the route path
    component: <CarousalPahe />, // Define the component for the route
  },
  {
    type: "hidden", // Define a hidden route type
    route: "/signin", // Define the route path
    component: <LoginForm />, // Define the component for the route
  },
  {
    type: "hidden", // Define a hidden route type
    route: "/signup", // Define the route path
    component: <SignUpScreen />, // Define the component for the route
  },
  {
    type: "collapse",
    name: "Test.Ai",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/upsc-kpsc-online-mocktest",
    component: <QuizContainer />,
  },
  {
    type: "collapse",
    name: "Test History",
    key: "history",
    icon: <HistoryIcon />,
    route: "/history",
    component: <QuizHistory />,
  },
  {
    type: "collapse",
    name: "Prelims-AnswerKey",
    key: "prelims",
    icon: <Icon fontSize="small">source</Icon>,
    route: "/prelims",
    component: <PrelimsLanding />,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <Settings />,
    route: "/settings",
    component: <SettingsPage />,
  },

  {
    type: "collapse",
    name: "LogOut",
    key: "logout",
    icon: <LogoutIcon />,
    isDialog: true,
    // component: <QuizHistory />,
  },
  {
    type: "cohiddenllapse",
    route: "/prelims/upsc-prelims-answer-key",
    component: <PrelimsQuestionPage />,
  },
  {
    type: "cohiddenllapse",
    route: "/neet/neet-pyq",
    component: <NeetQuestionPage />,
  },
  {
    type: "cohiddenllapse",
    route: "/prelims/kpsc-prelims-answer-key",
    component: <PrelimsQuestionPage />,
  },
  {
    type: "cohiddenllapse",
    route: "/prelims/upsc-prelims-answer-key/upsc-prelims-question",
    component: <PrelimsSingleQuestion />,
  },
  {
    type: "cohiddenllapse",
    route: "/prelims/kpsc-prelims-answer-key/kpsc-prelims-question",
    component: <PrelimsSingleQuestion />,
  },
  {
    type: "cohiddenllapse",
    route: "/prelims/upsc-prelims-answer-key-2024",
    component: <UPSCAnswerKey2024 />,
  },
  {
    type: "hidden", // Define a hidden route type
    route: "/upsc-kpsc-online-mocktest/CreateQuiz", // Define the route path
    component: <CreateQuizNew />, // Define the component for the route
  },
  {
    type: "hidden", // Define a hidden route type
    route: "/upsc-kpsc-online-mocktest/TakeQuiz", // Define the route path
    component: <TakeQuiz />, // Define the component for the route
  },
  {
    type: "hidden", // Define a hidden route type
    route: "/upsc-kpsc-online-mocktest/QuizResult", // Define the route path
    component: <ShowQuizResults />, // Define the component for the route
  },
  {
    type: "previousQuizResult", // Define a hidden route type
    route: "/upsc-kpsc-online-mocktest/PreviousAnalysis", // Define the route path
    component: <ShowPreviousQuizResults />, // Define the component for the route
  },
  // {
  //   type: "collapse",
  //   name: "Quiz History",
  //   key: "history",
  //   icon: <HistoryIcon />,
  //   route: "/history",
  //   component: <QuizHistory />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
];

export default routes;
