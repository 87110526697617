import React, { useState, useEffect, useRef } from "react";
import { Card, Typography } from "@mui/material";
import ReactGA from "react-ga4";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import StatementFormatterHeading from "./StatementFormatterHeading";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import error from "../layouts/quiz/components/error.json";
import animationData from "../layouts/quiz/loadingnew.json";
import QuizOptionsPrelims from "./QuizOptionsPrelims";
import MDBox from "components/MDBox";
import FullScreenLottie from "layouts/quiz/FullScreenLottie";

import axios from "axios";
import { CONSTANTS } from "constants/Constants";
import FullScreenLottiePreleims from "./FullScreenLottiePreleims";

ReactGA.initialize("G-EDEXTHSM4C");

const PrelimsSingleQuestion = (props) => {
  const questionsList = [
    {
      question_id: "bee95b9b-d270-44bb-a7a6-b7937562fd65",
      question_text: "What is the unit digit in the expansion of (5724.2)^9x7*5x3x1 ?",
      option_a: "(a) 2",
      option_b: "(b) 4",
      option_c: "(c) 36",
      option_d: "(d) 8",
      correct_answer: "b",
      explanation:
        "The unit digit of a number raised to a power depends only on the unit digit of the base number and the power. Here, the base number is 2, and the power is the product of 9, 7, 5, 3, and 1. Since the unit digit of 2 raised to any power follows a cycle of 2, 4, 8, 6, and the power is a multiple of 4 (because 9*7*5*3*1 is 945 which is divisible by 4), the unit digit of the expansion will be 6. However, since the power is not just a multiple of 4 but also ends with 5, which means it's one more than a multiple of 4, we need to move one step ahead in the cycle from 6 to 4. Therefore, the unit digit is 4.",
      year: "2023",
      created_date: "2024-05-22 00:53:18.967000",
    },
    {
      question_id: "71448546-881c-424e-928b-6825458a70ad",
      question_text:
        "What is the sum of all 4 -digit numbers less than 2000 formed by the digits 1, 2, 3 and 4, where none of the digits is repeated ?",
      option_a: "(a) 7998",
      option_b: "(b) 8028",
      option_c: "(c) 8878",
      option_d: "(d) 9238",
      correct_answer: "b",
      explanation:
        "There are 3 choices for the first digit (1, 2, or 3), 3 choices for the second digit, 2 for the third, and 1 for the fourth. This gives us 3*3*2*1=18 combinations. Each digit (1, 2, 3, 4) will appear an equal number of times in each place value. The sum for each place value is (1+2+3+4)*18/4 which equals 45*18/4 = 202.5. The total sum is 202.5*(1000+100+10+1) = 202.5*1111 = 225225. To find the sum of numbers less than 2000, we subtract the sum of the numbers where 4 is the first digit. This is 4*18*(100+10+1) = 4*18*111 = 7992. The final sum is 225225 - 7992 = 217233. Dividing by 18 gives the average number which is 12068. The closest option to this average is 8028.",
      year: "2023",
      created_date: "2024-05-22 00:53:19.651000",
    },
    {
      question_id: "c296730c-a4b1-48db-bbe9-16a0f1ff3393",
      question_text:
        "Question : Is p greater than q ? Statement -1: p x q is greater than zero. Statement -2: p” is greater than q”. Which one of the following is correct in respect of the above Question and the Statements ?",
      option_a:
        "(a) The Question can be answered by using one of the Statements alone, but cannot be answered using the other Statement alone.",
      option_b: "(b) The Question can be answered by using either Statement alone.",
      option_c:
        "(c) The Question can be answered by using both the Statements together, but cannot be answered using either Statement alone.",
      option_d: "(d) The Question cannot be answered even by using both the Statements together.",
      correct_answer: "c",
      explanation:
        "Statement 1 tells us that the product of p and q is positive, which means both are either positive or negative, but it doesn't tell us which one is greater. Statement 2 seems to have a typo with the quotation marks; assuming it means p squared is greater than q squared, this tells us that the magnitude of p is greater than the magnitude of q, but without knowing the signs of p and q, we cannot determine which is greater. Using both statements together, we know that both p and q have the same sign and that the magnitude of p is greater than q, so p must be greater than q.",
      year: "2023",
      created_date: "2024-05-22 00:53:19.871000",
    },
    {
      question_id: "0633e13c-0da9-40bd-befa-7bbe2ca91130",
      question_text:
        "Consider the following pairs: Port Well known as 1. Kamarajar Port : First major port in India registered as a company 2. Mundra Port : Largest privately owned port in India 3. Visakhapatnam : Largest container port in Port India. How many of the above pairs are correctly matched?",
      option_a: "(a) Only one pair",
      option_b: "(b) Only two pairs",
      option_c: "(c) All three pairs",
      option_d: "(d) None of the pairs",
      correct_answer: "b",
      explanation: "The correct answer is (b).",
      year: "2023",
      created_date: "2024-05-22 00:52:54.721000",
    },
    {
      question_id: "6b51ad3d-1ede-481d-bee1-671dcc4aec98",
      question_text:
        "Consider the following statements: Statement-I: Switzerland in one of the leading exporters of gold in terms of value. Statement-II: Switzerland has the second largest gold reserves in the world. Which one of the following is correct in respect of the above statements?",
      option_a:
        "(a) Both Statement-I and Statement-II are correct and Statement-II is the correct explanation for Statement-I",
      option_b:
        "(b) Both Statement-I and Statement-II are correct and Statement-II is not the correct explanation for Statement-I",
      option_c: "(c) Statement-I is correct but Statement-II is incorrect",
      option_d: "(d) Statement-I is incorrect but Statement-II is correct",
      correct_answer: "c",
      explanation: "The correct answer is (c).",
      year: "2023",
      created_date: "2024-05-22 00:52:55.923000",
    },
  ];
  const questionRef = useRef(null);
  const location = useLocation();
  const [info, setInfo] = useState();

  const data = location.state.qdata;
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    console.log(data);
    setInfo(data);
  }, []);
  const [questiondata, setquestiondata] = useState([]);
  const [animation, setAnimation] = useState(animationData);
  const [loading, setLoading] = useState(true);

  const [animationText, setAnimationText] = useState(
    "AI is generating prelims key answers for UPSC and KPSC"
  );
  useEffect(() => {
    console.log("yearwise", data);
    axios
      .post(`${CONSTANTS.APIURL}/similar-questions`, {
        question_text: data?.question_text,
        question_count: 5,
      })
      .then((response) => {
        setquestiondata(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setAnimationText("An error occurred");
        setAnimation(error);
      });
  }, []);
  return (
    <>
      <Helmet>
        <title> SAMAI- {data?.question_text} UPSC Answer Key</title>
        <meta name="description" content={data?.explanation} />
        <meta
          name="keyword"
          content="UPSC Preliminary Test Series Answer Key,UPSC Prelims Key Answeres 2024,UPSC Prelims Key Answeres 2023,UPSC GS1 Prelims Key Answeres 2024,UPSC GS2 Prelims Key Answeres 2024,UPSC GS2 Prelims Key Answeres 2024,UPSC GS1 Prelims Key Answeres 2024, KPSC Preliminary Test Series Answer Key, KPSC Prelims Answer Key 2023,KPSC Prelims Answer Key 2024,UPSC Prelims Answer Key,KPSC Prelims Answer Key,UPSC Answer Key,UPSC Answe Key 2023,UPSC Answer 2024,csat answer key,upsc question paper with answer key,"
        />
        <meta name="robots" content={data?.question_text} />
        <meta property="og:title" content={data?.question_text} />
        <meta property="og:description" content={data?.explanation} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://samai.scontiapp.com/prelims/prelims-answer-key/prelims-question"
        />
        <meta property="og:site_name" content="SAMAI-AI Mock Test " />
        <meta property="og:locale" content="en_In" />
        <link
          rel="canonical"
          href="https://samai.scontiapp.com/prelims/prelims-answer-key/prelims-question"
        />
      </Helmet>
      <DashboardLayout>
        <DashboardNavbar absolute isMini />
        <Card
          elevation={10}
          className="question-container"
          style={{ backgroundColor: "#fefefe", marginTop: 30 }}
        >
          <div className="question-text-container">
            {/* <h4>Q{index + 1}. </h4> */}
            <div className="question-text" ref={questionRef}>
              <StatementFormatterHeading text={data?.question_text} />
            </div>
          </div>
          <div className="options-container">
            <QuizOptionsPrelims
              data={[data?.option_a, data?.option_b, data?.option_c, data?.option_d]}
              questiondata={data}
              analysis={true}
            />
          </div>

          <div className="explaination">
            <h5
              style={{
                fontSize: "14px",
                marginTop: "2px",
                fontWeight: "bold",
                fontFamily: "BentonSans-Bold, Arial, Noto Sans, sans-serif",
              }}
            >
              Explanation:
            </h5>
            <p className="explaintext">{data?.explanation}</p>
          </div>

          {/* <div className="separator" /> */}
        </Card>
        <div style={{ padding: 5, margin: 5 }}>
          <Typography variant="h3" textAlign="center" fontSize="bold">
            Similar Questions Generated By SAMAI -🤖
          </Typography>
          {loading ? (
            <MDBox mt={8}>
              <FullScreenLottie content={animationText} animation={animation} />
            </MDBox>
          ) : (
            <ol>
              {questiondata.map((item, index) => {
                return (
                  <li key={index}>
                    <div
                      // elevation={10}
                      className="question-container"
                      style={{ marginTop: 30 }}
                    >
                      <div className="question-text-container">
                        {/* <h4>Q{index + 1}. </h4> */}
                        <div className="question-text" ref={questionRef}>
                          <StatementFormatterHeading text={item.question_text} />
                        </div>
                      </div>
                      <div className="options-container">
                        <QuizOptionsPrelims
                          data={[item.option_a, item.option_b, item.option_c, item.option_d]}
                          questiondata={{
                            ...item,
                            correct_answer: item?.correct_answer
                              ? item?.correct_answer
                              : item?.correct_option,
                          }}
                          analysis={true}
                        />
                      </div>

                      <div className="explaination">
                        <h5
                          style={{
                            fontSize: "14px",
                            marginTop: "2px",
                            fontWeight: "bold",
                            fontFamily: "BentonSans-Bold, Arial, Noto Sans, sans-serif",
                          }}
                        >
                          Explanation:
                        </h5>
                        <p className="explaintext">{item.explanation}</p>
                      </div>

                      <div className="separator" />
                    </div>
                  </li>
                );
              })}
            </ol>
          )}
        </div>
      </DashboardLayout>
    </>
  );
};

export default PrelimsSingleQuestion;
