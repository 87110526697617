/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import QuizQuestionsRate from "./QuizQuestionsRate";
import QuizOptions from "./QuizOptions";
import "./QuizQuestionComponentAnalysis.css";
import {
  AppRegistration,
  AppRegistrationTwoTone,
  BookmarkAddTwoTone,
  CancelTwoTone,
  ReportTwoTone,
  Save,
} from "@mui/icons-material";
import Modal from "react-modal";
import Icon from "@mui/material/Icon";
import Lottie from "react-lottie";
import { Button, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import analysis from "../../layouts/quiz/analysis.json";
import { CONSTANTS } from "constants/Constants";
import { client } from "constants/APIFunctions";
import { auth } from "../../firebase/firebase";
import { useCookies } from "react-cookie";
import { ToastContainer, toast } from "react-toastify";
import { Report } from "@material-ui/icons";
import CircularProgress from "@mui/material/CircularProgress";
import StatementFormatter from "./components/StatementFormatter";
import generating from "../../examples/animations/generating.json";
import performancereport from "../../examples/animations/performancereport.json";
import axios from "axios";
import ReactGA from "react-ga4";
import idea from "./idea.png";
ReactGA.initialize("G-EDEXTHSM4C");
const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
  content: {
    width: "70%",
    maxWidth: "600px",
    margin: "auto",
    border: "none",
    borderRadius: "8px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "auto",
  },
  "::-webkit-scrollbar": {
    width: "4px",
    height: "4px",
  },
  "::-webkit-scrollbar-thumb": {
    background: "gray",
    borderRadius: "2px",
  },
  closeButton: {
    cursor: "pointer",
  },
};

const customStylesPhone = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
  content: {
    width: "80%",
    maxWidth: "400px",
    margin: "auto",
    border: "none",
    borderRadius: "8px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "auto",
  },
  "::-webkit-scrollbar": {
    width: "2px",
    height: "2px",
  },
  "::-webkit-scrollbar-thumb": {
    background: "gray",
    borderRadius: "1px",
  },
};

if (window.innerWidth > 768) {
  customStyles.content.width = "80%";
  customStyles.content.maxWidth = "600px";
}

const defaultOption1 = {
  loop: true,
  autoplay: true,
  animationData: analysis,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const defaultOption2 = {
  loop: true,
  autoplay: true,
  animationData: generating,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const defaultOption3 = {
  loop: true,
  autoplay: true,
  animationData: performancereport,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const iconsList = [
  {
    name: "Similar AI Qs",
    //icon: <AppRegistrationTwoTone className="icon-div" />,
    type: 0,
  },
  // {
  //   name: "Save",
  //   icon: <BookmarkAddTwoTone className="icon-div" />,
  //   type: 1,
  // },
  {
    name: "Report",
    //icon: <ReportTwoTone className="icon-div" />,
    type: 2,
  },
];

const reportData = [
  {
    report: "Answer is wrong",
    type: 0,
    placeholder: "Please suggest your comments regarding the answer",
  },
  {
    report: "Explanation is wrong",
    type: 1,
    placeholder: "Please suggest your comments regarding the explanation",
  },
  {
    report: "Question is wrong",
    type: 2,
    placeholder: "Please suggest your comments regarding the question",
  },
];
const QuizQuestionComponentAnalysis = (props) => {
  const [fontSize, setFontSize] = useState(36);
  const questionRef = useRef(null);
  const [correctAnswer, setCorrectAnswer] = useState(6);
  const [selectedOption, setSelectedOption] = useState();
  const [showTextInput, setShowTextInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  // const [selectedOption, setSelectedOption] = useState();
  const [isActive, setIsActive] = useState(false);
  const [cookies, setCookie] = useCookies(["user"]);
  const [isloading, setisloading] = useState(false);
  const [userdata, setuserdata] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [generatingDetailed, setgeneratingdetailed] = useState(false);
  const [generatedExplanation, setgeneratedexplanation] = useState();

  const reportQuestions = () => {
    if (selectedOption) {
      const selectedText = `Selected: ${selectedOption.report} explanation: ${
        inputValue ? inputValue : ""
      }`;
      setisloading(true);
      var a = 0;
      client
        .post("/api/question/report", {
          test_id: props.passedData.testId,
          question_id: props.data.question_id,
          user_id: auth.currentUser
            ? auth.currentUser.uid
            : userdata?.user_id
            ? userdata?.user_id
            : "813b6e33-91ff-49f0-8e08-2f479c95e4e1",
          report_text: selectedText,
        })
        .then((res) => {
          console.log("success", res);
          setisloading(false);
          props.toastprops("Thanks for reporting...");
          setInputValue("");
        })
        .catch((err) => {
          console.log(err);
          setisloading(false);
          props.toastprops("An error occurred...");
        });
    } else {
      setisloading(false);
      props.toastprops("Please select the options...");
      // toast("Please select the options...");
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("userdata"));
    setuserdata(items);
    if (!questionRef.current) return;

    const { lineHeight, height } = getComputedStyle(questionRef.current);
    const numLines = Math.ceil(parseInt(height, 10) / parseInt(lineHeight, 10));
    // Set font size based on number of lines within the given range
    setFontSize(Math.min(Math.max(20, 26 - (numLines - 1)), 26));
  }, []);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  const getDetailedExplanation = (question, opta, optb, optc, optd) => {
    setgeneratingdetailed(true);
    setModalOpen(true);
    const payloadData =
      question +
      `(a) ${opta?.replace(/\(a\)|\(b\)|\(c\)|\(d\)/g, "")}` +
      `(b) ${optb?.replace(/\(a\)|\(b\)|\(c\)|\(d\)/g, "")}` +
      `(c) ${optc?.replace(/\(a\)|\(b\)|\(c\)|\(d\)/g, "")}` +
      `(d) ${optd?.replace(/\(a\)|\(b\)|\(c\)|\(d\)/g, "")}`;
    console.log(payloadData);
    axios
      .get(`${CONSTANTS.APIURL}/api/gemini/gemini_explanation`, {
        params: {
          user_response: payloadData,
        },
      })
      .then((response) => {
        setgeneratingdetailed(false);
        setgeneratedexplanation(response?.data?.explanation);
        setModalOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setgeneratingdetailed(false);
        setModalOpen(false);
      });
  };
  return (
    <div className="question-container" style={{ marginTop: 8 }}>
      <div className="question-text-container">
        <h4>Q{props.index + 1}. </h4>
        <div className="question-text" ref={questionRef}>
          <StatementFormatter text={props.data.question_text} />
        </div>
      </div>
      <div className="options-container">
        <QuizOptions
          data={[
            props.data.option_a,
            props.data.option_b,
            props.data.option_c,
            props.data.option_d,
          ]}
          questiondata={props.data}
          analysis={true}
        />
      </div>
      {props?.data?.user_response === "skipped" ? (
        <div style={{ color: "red", fontSize: "13px" }}>You skipped this question</div>
      ) : (
        <div />
      )}
      <div className="explaination">
        <h5
          style={{
            fontSize: "14px",
            marginTop: "2px",
            fontFamily: "BentonSans-Bold, Arial, Noto Sans, sans-serif",
          }}
        >
          Explanation:
        </h5>
        <div className="explaintext">{props.data.explanation}</div>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "380px",
            gap:5
          }}
        > */}
        {/* <img src={idea} alt="idea" width="32px" height="32px" /> */}

        <button
          style={{
            backgroundColor: "#EDC967",
            borderRadius: "80px",
            boxShadow: "0px 2px 8px rgba(0,0,0,0.16)",
            marginRight: window.innerWidth > 768 ? "45px" : "15px",
            padding: "5px",
            cursor: "pointer",
            minWidth: "80px",
            // width: "300px",
            height: 30,
            fontSize: 12,
            borderStyle: "none",
            marginTop: "10px",
            marginBottom: "10px",
            marginLeft: "20px",
            color: "#000959",
          }}
          onClick={() => {
            setModalOpen(!modalOpen);
            getDetailedExplanation(
              props.data?.question_text,
              props.data?.option_a,
              props.data?.option_b,
              props.data?.option_c,
              props.data?.option_d
            );
          }}
        >
          Click For Detailed Explanation
        </button>
        {/* </div> */}
      </div>
      <div className="div-options">
        {iconsList.map((item) => {
          return (
            <div
              style={{
                backgroundColor: "#EDC967",
                borderRadius: "80px",
                boxShadow: "0px 2px 8px rgba(0,0,0,0.16)",
                marginRight: window.innerWidth > 768 ? "30px" : "10px",
                display: "flex",
                flexDirection: "row",
                padding: "5px",
                alignItems: "center",
                cursor: "pointer",
                minWidth: "80px",
                justifyContent: "center",
              }}
              onClick={() => {
                if (item.type == 0) {
                  props.setReferenceQuestion(props.data.question_text);
                  props.open(true);
                } else if (item.type == 2) {
                  setIsActive(false); // Reset isActive to false before toggling
                  setTimeout(() => {
                    setIsActive(true); // Toggle isActive after a short delay
                  }, 50);
                }
              }}
            >
              <div className="text-div">{item.name}</div>
              {item.icon}
            </div>
          );
        })}
      </div>
      {isActive ? (
        <div className={isActive ? "report-container-active" : "report-container"}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <h6 style={{ marginLeft: "8px", padding: "8px" }}>Report the Question:</h6>
            <div
              onClick={() => {
                setIsActive(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <CancelTwoTone style={{ marginRight: "8px", marginTop: "8px" }} />
            </div>
          </div>
          <div className="option-buttons">
            {reportData.map((item) => {
              return (
                <div
                  className={
                    selectedOption?.type === item.type
                      ? "option-div-container-selected"
                      : "option-div-container"
                  }
                  onClick={() => {
                    if (selectedOption?.type === item.type) {
                      setSelectedOption();
                      setShowTextInput(false);
                    } else {
                      setSelectedOption(item);
                      setShowTextInput(true);
                    }
                  }}
                >
                  {item.report}
                </div>
              );
            })}
          </div>
          {showTextInput && (
            <div className="text-input">
              <input
                className="textInputExplanation"
                type="text"
                id="textInput"
                value={inputValue}
                onChange={handleInputChange}
                placeholder={selectedOption?.placeholder}
              />
            </div>
          )}
          <button
            className="button-styles-analysis"
            onClick={() => {
              reportQuestions();
            }}
          >
            {"Submit"}
            {isloading ? (
              <CircularProgress color="inherit" size={"16px"} style={{ marginLeft: "8px" }} />
            ) : (
              <div />
            )}
          </button>
        </div>
      ) : (
        <div />
      )}

      <div className="separator" />
      <Modal
        isOpen={modalOpen}
        onClose={setModalOpen}
        style={window.innerWidth > 768 ? customStyles : customStylesPhone}
        ariaHideApp={false}
        position="fixed"
      >
        <div>
          {generatingDetailed ? (
            <div
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                flex: 1,
                justifyContent: "center",
              }}
            >
              <Lottie
                options={defaultOption2}
                height={200}
                width={200}
                style={{ alignSelf: "start" }}
              />
              <Typography variant="h6" textAlign="center">
                Generating Your Detailed Performance Insights
              </Typography>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Typography variant="h4"> Q{props.index + 1}. Detailed Explanation</Typography>
                <IconButton onClick={() => setModalOpen(false)} size="small">
                  <Close />
                </IconButton>
              </div>
              <Lottie
                options={defaultOption3}
                height={200}
                width={200}
                style={{ alignSelf: "start" }}
              />
              <pre
                style={{
                  textAlign: "justify",
                  fontSize: 14,
                  width: "100%",
                  textWrap: "wrap",
                  fontFamily: "BentonSans-Bold, Arial, Noto Sans, sans-serif",
                }}
              >
                {generatedExplanation?.replace(/\*/g, "")}
              </pre>
              <div style={{ height: 30 }} />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default QuizQuestionComponentAnalysis;
