/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import "./QuizHistory.css"; // Import the CSS styles
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FullScreenLottie from "../quiz/FullScreenLottie";
import animationData from "../quiz/loadingnew.json"; // Replace with your animation file
import { auth } from "../../firebase/firebase";
import error from "../quiz/components/error.json";
import nodata from "../quiz/components/nodata.json";

import MDBox from "components/MDBox";
import { client } from "constants/APIFunctions";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import ReactGA from "react-ga4";
ReactGA.initialize("G-EDEXTHSM4C");
import { Helmet } from "react-helmet";

const QuizHistory = () => {
  const [quizData, setQuizData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [animation, setAnimation] = useState(animationData);
  const [animationText, setAnimationText] = useState("Getting your history...");
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["user"]);
  const [userdata, setuserdata] = useState();

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("userdata"));
    setuserdata(items);
    var userdata = items;
    client
      .get("/api/test/get_tests_of_user", {
        params: {
          user_id: auth.currentUser
            ? auth.currentUser.uid
            : userdata?.user_id
            ? userdata?.user_id
            : "813b6e33-91ff-49f0-8e08-2f479c95e4e1",
        },
      })
      .then((response) => {
        setLoading(false);
        setQuizData(response.data.test_list);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setAnimation(error);
        setAnimationText("An error occurred...");
      });
  }, []);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <DashboardLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SAMAI-Mock Test Results Generated by AI</title>
        <meta
          name="description"
          content="Ai Generated FREE UPSC, KPSC, and UPPSC Mock Tests Results with Best AI."
        />
        <meta
          name="keyword"
          content="Free UPSC mock test online Results, UPSC mock test series results, UPSC Civil Services Exam Mock Tests results, UPSC Preliminary Test results, UPSC Mains Test Series results, KPSC Preliminary Mock Tests,AI Generated Mock Results, UPSC GS Mock Tests Results,UPSC Current Affairs Mock Tests Results, UPSC CSAT AI-Mock Tests Results, UPSC Optional Subject AI-Mock Tests Results, UPSC Online Practice Tests Results, Chapter-wise mock tests results, Subject-wise mock tests results, Online test series for competitive exams results,"
        />
        <meta
          property="og:description"
          content="Ai Generated FREE UPSC, KPSC, and UPPSC Mock Tests Results with Best AI."
        />
        <meta property="og:url" content="https://samai.scontiapp.com/history" />
        <meta property="og:site_name" content="SAMAI-AI Mock Test " />
        <link rel="canonical" href="https://samai.scontiapp.com/history" />

        <meta
          property="og:description"
          content="SAMAI-Free UPSC & KPSC with Best AI Mock Tests - with Scontinent"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="SAMAI-AI Mock Test " />
        <meta property="og:locale" content="en_In" />
      </Helmet>

      <DashboardNavbar absolute isMini />
      {loading ? (
        <MDBox mt={8}>
          <FullScreenLottie content={animationText} animation={animation} />
        </MDBox>
      ) : (
        <MDBox mt={8}>
          {quizData.length > 0 ? (
            <div className="quiz-container">
              {quizData.map((quiz, index) => (
                <div key={index} className="quiz-card">
                  <div className="test-name">{quiz.test_name}</div>
                  <div className="test-row">
                    <div className="test-name">Subject:</div>
                    <div className="test-value">{quiz.subject_name}</div>
                  </div>
                  <div className="test-row">
                    <div className="test-name">Topics:</div>
                    <div className="test-value">{quiz.topic_name}</div>
                  </div>
                  <div className="test-row">
                    <div className="test-name">Subtopics:</div>
                    <div className="test-value">{quiz.sub_topic_name}</div>
                  </div>
                  {/* <div className="test-row">
                    <div className="test-name">Difficulty Level:</div>
                    <div className="test-value">{quiz.difficulty_level}</div>
                  </div> */}
                  <div className="test-row">
                    <div className="test-name">Score:</div>
                    <div className="test-value">{quiz?.test_score?.toFixed(2)}</div>
                  </div>
                  <button
                    style={{
                      cursor: "pointer",
                      padding: "5px 15px",
                      border: "0",
                      boxSizing: "border-box",
                      borderRadius: "40px",
                      backgroundColor: "gold",
                      color: "#000000",
                      fontSize: "15px",
                      fontFamily: "BentonSans, Arial, Noto Sans, sans-serif",
                      fontWeight: 500,
                      lineHeight: "24px",
                      textTransform: "capitalize",
                      outline: "none",
                      alignSelf: "right",
                      marginTop: "8px",
                      display: "flex",
                    }}
                    onClick={() => {
                      navigate("/upsc-kpsc-online-mocktest/PreviousAnalysis", {
                        state: { data: quiz },
                      });
                      ReactGA.event({
                        category: "Report ",
                        action: "User is going to check with the report",
                      });
                    }}
                  >
                    View Report
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <FullScreenLottie content={"No data available"} animation={nodata} showButton={true} />
          )}
        </MDBox>
      )}
    </DashboardLayout>
  );
};

export default QuizHistory;
